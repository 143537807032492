import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Select from '../../../objects/select';
import Input from '../../../objects/input';

/* Redux */
import { setValues, nextPage, prevPage } from '../../../redux/actions/ubo-form';

const Partners = ({ dispatch, defaultValues }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues });
  const { handleSubmit, formState, watch } = formMethods;
  const partner = watch('partner');
  const options = [
    { label: '1 vennoten', value: 1 },
    { label: '2 vennoten', value: 2 },
    { label: '3 vennoten', value: 3 },
    { label: '4 vennoten', value: 4 },
  ];
  const partnerOptions = [
    { label: 'Ja' },
    { label: 'Nee' },
  ];

  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(nextPage());
  };

  const renderFields = () => (
    Array(parseInt(partner, 10)).fill().map((name, i) => (
      <div className="mt-40" key={`partner${i}`}>
        <p className="bold">{`Vennoot ${i + 1}`}</p>
        <div className="mt-20">
          <Grid columns={1}>
            <Grid columns={2}>
              <Input name={`partners[${i}].firstname`} label="Voornaam" required />
              <Input name={`partners[${i}].lastname`} label="Achternaam" required />
              <Input name={`partners[${i}].birthday`} label="Geb. datum" required />
              <Select
                name={`partners[${i}].partner`}
                placeholder="Gehuwed/Geregistreerd partner"
                options={partnerOptions}
                required
              />
            </Grid>
            <Grid columns={1}>
              <Input IEInputStraatnaam = {true} name={`partners[${i}].streetname`} label="Straatnaam" required  />
              <Input IEInputNummer = {true} name={`partners[${i}].housenumber`} label="Nummer" required  />
            </Grid>
            <Grid columns={2}>
              <Input name={`partners[${i}].postalcode`} label="Postcode" required />
              <Input name={`partners[${i}].city`} label="Plaats" required />
              <Input name={`partners[${i}].email`} label="E-mailadres" type="email" required />
              <Input name={`partners[${i}].mobile`} label="Telefoonnummer" type="tel" defaultValue="06" required />
            </Grid>
            {
              watch()[`partners[${i}].partner`] === partnerOptions[0].label
              && (
                <Grid columns={1}>
                  <p className="bold">Gegevens van uw partner</p>
                  <Grid columns={2}>
                    <Input name={`partners[${i}].firstnamePartner`} label="Voornaam partner" required />
                    <Input name={`partners[${i}].lastnamePartner`} label="Achternaam partner" required />
                    <Input name={`partners[${i}].emailPartner`} label="E-mailadres partner" required />
                    {/* <Input name={`partners[${i}].birthdayPartner`} label="Geb. datum partner" required /> */}
                  </Grid>
                  <p className="sub light">Vul bovenstaand het e-mailadres van de partner in. Dit moet een ander e-mailadres zijn dan het e-mailadres dat reeds wordt gebruikt voor de belanghebbende. Wanneer je aanvraag is goedgekeurd dienen de partner(s) van belanghebbende(n) digitaal mee te tekenen op de borgstelling en sturen wij dit verzoek naar het e-mailadres dat je hier opgeeft.</p>
                </Grid>
              )
            }
          </Grid>
        </div>
      </div>
    ))
  );

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="bold">Hoeveel vennoten heeft jouw bedrijf?</p>
        <div className="mt-20">
          <Select
            name="partner"
            placeholder="Selecteer het aantal"
            options={options}
            required
          />
        </div>
        {partner && renderFields()}
        <div className="mt-40 inDesktop">
          <Grid>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Partners.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
};

Partners.defaultProps = {
  defaultValues: {},
};

export default connect((state) => ({
  defaultValues: state.uboForm,
}))(Partners);
