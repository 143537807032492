import {
  SET_COMPLETE_VALUES,
} from '../actions/complete';

const INITIAL_STATE = JSON.parse(localStorage.getItem('complete')) || {};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_COMPLETE_VALUES:
      return {
        ...state,
        ...action.values,
      };
    default:
      return state;
  }
}
