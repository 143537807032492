import styled from 'styled-components';

/* Constants */
import { space, spaceLarge } from '../../constants/spaces';
import { grayLight2 } from '../../constants/colors';

export default styled.div`
  .title {
    font-size: ${(props) => props.fontSize}px;
    text-align: center;
    color: ${grayLight2};
    display: flex;
    align-items: center;
    font-weight: 400;

    &:before,
    &:after {
      content: '';
      height: 1px;
      flex-grow: 1;
      display: block;
      background: #dddddd;
    }

    &:before {
      margin-right: ${space}px;
    }

    &:after {
      margin-left: ${space}px;
    }
  }

  .logos {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${spaceLarge}px;

    & > div {
      flex-basis: 20%;
    }

    img {
      max-height: 35px;

      &.vertical {
        max-height: 70px;
      }
    }
  }
`;
