import {
  TOGGLE_LOADING,
} from '../actions/loading';

const INITIAL_STATE = {};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        [action.prop]: action.bool,
      };
    default:
      return state;
  }
}
