import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

/* Constants */
import { mdMinWidth } from '../../constants/breakpoints';

const SearchResults = ({
  show, loading, results,
  onSelect,
}) => {
  const limit = window.innerWidth > mdMinWidth ? 20 : 5;
  const [selectIndex, setSelectIndex] = useState(-1);
  const partner = localStorage.getItem('partner');
  const renderResults = () => results.slice(0, limit).map((item, index) => (
    <li
      className={index === selectIndex ? 'active' : ''}
      key={item.kvkNumber}
      onMouseDown={() => onSelect(item)}
      role="menuitem"
    >
       <div className="name">{item.handelsnaam}</div>
      {item.plaats && <div className="postalcode">{item.plaats}</div>}
    </li>
  ));

  const handleKeydown = (e) => {
    if (results.length === 0) {
      setSelectIndex(-1);
      return;
    }

    // Up arrow key
    if (e.which === 40) {
      const newIndex = selectIndex + 1;
      if (newIndex <= Math.min(limit - 1, results.length - 1)) setSelectIndex(newIndex);
    }

    // Down arrow key
    if (e.which === 38) {
      const newIndex = selectIndex - 1;
      if (newIndex >= 0) setSelectIndex(newIndex);
    }

    // Enter key
    if (e.which === 13) {
      e.preventDefault();
      if (selectIndex >= 0) onSelect(results[selectIndex]);
      setSelectIndex(-1);
    }
  };

  const renderTitle = () => {
    if (loading) return 'Zoeken';
    if (partner) return 'Vul de volledige bedrijfsnaam in'
    if (results.length > 0) return 'Selecteer je bedrijf';
    if (results.length === 0) return 'Green resultaten';
  };

  const renderContent = () => {
    if (loading) return <div className="loading" />;
    if (results.length > 0) return <ul className="results" role="menu">{renderResults()}</ul>;

    return (
      <div className="no-results">
        <br />
        <p>Vul de correcte bedrijfsnaam of het 8-cijferig KVK-nummer in.</p>
        {/* <p className="mt-20">{partner ? 'Of vul het 8-cijferige KVK-nummer in.' : 'Of vul je 8-cijferige KVK-nummer in.'}</p> */}
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  });

  return (
    <StyledComponent className={!show && 'hide'}>
      <div className="top">
        <p className="lead">
          <strong>{renderTitle()}</strong>
        </p>
      </div>
      {renderContent()}
      <br/>
      {results && results.length > 0 && !loading }{
        <div className="no-results">
          <p>Staat je bedrijf er niet tussen?</p>
          <p>Zoek dan op je 8-cijferig KVK-nummer.</p>
        </div>
      }
      <br/>
    </StyledComponent>
  );
};

SearchResults.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
};

SearchResults.defaultProps = {
  show: true,
  loading: false,
};

export default SearchResults;
