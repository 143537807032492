import React from 'react';
import { useHistory } from 'react-router-dom';

/* Style */
import StyledComponent from './style';

/* Constants */
import { financialInfo } from '../../constants/routes';

/* Objects */
import Divider from '../../objects/divider';
import Button from '../../objects/button';

import {Telephone, Telephone_Link} from '../../constants/commons';

export default () => {
  const history = useHistory();

  return (
    <StyledComponent>
      <div className="icon">
        <i className="icmn-hourglass" />
      </div>
      <h2>Hoe sneller jij bent, hoe sneller wij zijn.</h2>
      <Divider align="center" />
      <p className="lead light">
        Nu geen tijd om je aanvraag af te ronden? Geen probleem. Binnen enkele minuten ontvang je een e-mail met instructies hoe je de aanvraag later kan afronden.
        <br />
        <br />
        Zodra wij al je gegevens beveiligd en in goede orde hebben ontvangen, kunnen we alle slimme koppen bij elkaar steken en kijken hoeveel je veilig kan lenen.
      </p>
      <div className="mt-40">
        <Button onClick={() => history.push(financialInfo)}>Terug naar het uploaden</Button>
      </div>
      <div className="contact mt-40">
        <img src={`${process.env.PUBLIC_URL}/images/csm-2.png`} width="330px" alt="contact" />
        <strong>Liever persoonlijk contact?</strong>
        <br />
        Bel met Isabelle of Pieter
        <br />
        <span>via <a href={Telephone_Link}>{Telephone}</a></span>
      </div>
    </StyledComponent>
  );
};
