import styled from 'styled-components';

/* Constants */
import { xsMinWidth } from '../../constants/breakpoints';

export default styled.div`
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;

  @media only screen and (min-width: ${xsMinWidth}px) {
    padding: 80px 20px 20px 20px;
  }

  input {
    width: 100%;
    height: 40px;
    padding-right: 40px;
    background: none;
    font-size: 30px;
  }

  .container {
    width: 100%;
    max-width: 600px;
    position: relative;
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(-135deg);
    cursor: pointer;

    i {
      font-size: 25px;
    }
  }

  #buttonHolder{
    width: 95px;
    height: 36px;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 50px;
    cursor: pointer;
  }

  #sendButton{
    background-color: #29c4ea;
    width: 100%;
    height: 100%;
    color: #ffffff;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 500;
  }
`;
