import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import Header from '../components/header';
import CallUs from '../components/call-us';
import MainMenu from '../components/main-menu';

import {
  financialInfo,
} from '../constants/routes';
import { useRouteMatch, useLocation  } from 'react-router-dom';

const RequestLoan = ({ children }) => {
  let pathArray = useLocation().pathname.split('/');
  let paramId = pathArray[pathArray.length - 1];
  let isFinancialInfoPage = !!useRouteMatch(financialInfo);
  return (
    <>
      <Header>
        <CallUs />
        {!paramId.includes('SBF') && isFinancialInfoPage && <MainMenu />}
      </Header>
      {children}
    </>
  )
};

RequestLoan.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequestLoan;
