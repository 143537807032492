import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Constants */
import { completeError } from '../constants/routes';

/* Objects */

/* Components */
import Header from '../components/header';
import CompleteMenu from '../components/complete-menu';
import FullScreenLoader from '../components/full-screen-loader';
import SuccessManager from '../components/success-manager';

/* Redux */
import { setValues, loadPageData } from '../redux/actions/complete';

import { Container,Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const CompleteBootstrap = ({
  dispatch, sidebar, error,
  loading, completeId, children,
}) => {
  const paramId = useParams().id;
  const id = paramId ? paramId : completeId ? completeId : undefined; // 22803
  const colums = {
    mdMinWidth: '2.5fr 1fr',
  };

  const Div = styled.div`
  height: 100%

  .inMobile {
    display: none;
  }

  @media only screen and (max-width: 543px) {
    .inMobile {
      display: block;
    }

    .inDesktop {
      display: none;
    }
  }

  // @media only screen and (max-width: 543px) {
  //   .inMobileSpecial {
  //     display: block;
  //   }

  //   .inDesktop {
  //     display: none;
  //   }
  // }
`;

  useEffect(() => {
    dispatch(setValues({ id }));
    dispatch(loadPageData());
  }, [dispatch, id]);

  if (!id || error) {
    return <Redirect to={completeError} />;
  }

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Header marginRight={3.6}>
        <CompleteMenu />
      </Header>
      {
        <Container fluid style={{height: 'calc(100% - 78px)'}}>
          <Div>
          <Row style={{height:'100%'}}>
            <Col style={{padding: '60px 20px'}}>
              <div style={{ maxWidth:'720px', margin: '0 auto'}}>
                {children}
              </div>
            </Col>
            <Col lg="auto" xl="auto" sm={12} style={{padding:0}}>
              <SuccessManager />
            </Col>
          </Row>
          </Div>
        </Container>
      }
    </>
  );
};

CompleteBootstrap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  completeId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CompleteBootstrap.defaultProps = {
  error: false,
  sidebar: false,
  loading: true,
  completeId: null,
};

export default connect((state) => ({
  error: state.error.opportunity,
  loading: state.loading.fullscreen,
  completeId: state.complete.id,
}))(CompleteBootstrap);
