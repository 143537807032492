import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeral from 'numeral';

/* Objects */
import Divider from '../objects/divider';

/* Components */
import SubmitStatus from '../components/submit-status';

const Overview = ({ firstName, desiredLoan, awardedLoan }) => (
  <>
    <h2 className="myDossierHeading">
      {firstName ? `${firstName}, wij verwachten dat jij ` :  "wij verwachten dat jij"}
      <b>
        {`€${numeral(awardedLoan || desiredLoan).format()}`}
      </b>
     {' kunt lenen.'}
    </h2>
    <Divider />
    <p className="lead dark maxLoanAmountIndicationTxt">Dit is een indicatie van het maximale leenbedrag. Voor een definitief voorstel hebben we ter verificatie nog een paar aanvullende gegevens nodig.</p>
    <div className="mt-40">
      <SubmitStatus />
    </div>
  </>
);

Overview.propTypes = {
  firstName: PropTypes.string,
  desiredLoan: PropTypes.number,
  awardedLoan: PropTypes.number,
};

Overview.defaultProps = {
  firstName: null,
  desiredLoan: 0,
  awardedLoan: 0,
};

export default connect((state) => ({
  firstName: state.opportunity.firstName,
  desiredLoan: state.opportunity.desiredLoan,
  awardedLoan: state.opportunity.awardedLoan,
}))(Overview);
