import React from 'react';
import moment from 'moment';
import {Telephone_no_space, Telephone_Link} from '../../../constants/commons';

export default () => (
  <>
    <p><strong>Volg onderstaande stappen:</strong></p>
    <ol>
      <li>
        Klik
        <a href="https://mijnzakelijk.ing.nl/zakelijk/overzichten/download/index" target="_blank" rel="noopener noreferrer"> hier </a>
        om veilig in te loggen bij
        <strong> ING </strong>
        en je banktransacties te downloaden.
      </li>
      <li>
        Selecteer bij ‘Rekening’ je
        <strong> Zakelijke rekening</strong>
        .
      </li>
      <li>
        Vul de datum in van
        <strong className="date">{` ${moment().subtract(1, 'years').format('DD-MM-YYYY')} `}</strong>
        t/m
        <strong className="date">{` ${moment().format('DD-MM-YYYY')}`}</strong>
        .
      </li>
      <li>
        Selecteer bij ‘Bestandsformaat’ -
        <strong> MT940</strong>
        .
      </li>
      <li>
        Klik op
        <strong> Download</strong>
        .
        {' '}
        <div className="desktop">
          Het bestand wordt opgeslagen in de map
          <strong> Downloads</strong>
          .
        </div>
      </li>
      <p className="desktop">
        <strong>Let op! </strong>
        Je kunt dit bestand zelf niet openen. Het is een gecodeerd bestand speciaal voor boekhoudprogramma’s en dergelijke.
      </p>
      <li>
        <strong>Upload </strong>
        het bestand in het blauwe venster onderin deze pagina.
      </li>
    </ol>
    <p>
      Heb je meer dan één zakelijke rekening? Herhaal dan deze stappen.
    </p>
    <p className="mobile">
      <br />
      <strong>Lukt het niet op je mobiel of tablet?</strong>
      <br />
      Maak je aanvraag af via de computer. Dat is een stuk makkelijker! Vervolg daar je aanvraag door op ‘Direct uploaden’ te klikken in de welkomstmail die je hebt ontvangen.
    </p>
    <p className="desktop">
      <br />
      <strong>Kom je er niet uit?</strong>
      <br />
      Bekijk de instructievideo.
    </p>
    <div className="video">
      <iframe title="ing" src="https://www.youtube.com/embed/aj3s0WPpW38" frameBorder="0" allowFullScreen />
    </div>
    <p>
      <br />
      <strong>Hulp nodig?</strong>
      <br />
      Bel onze Customer Success Managers via
      <a href={Telephone_Link}> {Telephone_no_space}</a>
      .
    </p>
  </>
);
