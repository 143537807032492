import React, { useState } from 'react';
import moment from 'moment';
import {Telephone_no_space, Telephone_Link} from '../../../constants/commons';

export default () => {
  const [extraInfo, setExtraInfo] = useState(false);

  return (
    <>
      <p><strong>Volg onderstaande stappen:</strong></p>
      <ol>
        <li>
          Klik
          <a href="https://bankieren.rabobank.nl/klanten/bedrijven/internetbankieren/betalensparen/rekeningoverzicht/downloaden_transacties" target="_blank" rel="noopener noreferrer"> hier </a>
          om veilig in te loggen bij
          <strong> Rabobank </strong>
          en je banktransacties te downloaden.
        </li>
        <li>
          Selecteer bij ‘Downloadformaat’ -
          <strong> MT940</strong>
          .
        </li>
        <li>
          Selecteer bij ‘Rekening(en)’ -
          <strong> Alle rekeningen</strong>
          .
        </li>
        <li>
          Selecteer bij ‘Periode’ -
          <strong> Specifieke datum reeks</strong>
          .
        </li>
        <li>
          Vul de datum in van
          <strong className="date">{` ${moment().subtract(1, 'years').format('DD-MM-YYYY')} `}</strong>
          t/m
          <strong className="date">{` ${moment().format('DD-MM-YYYY')}* `}</strong>
          en klik op
          <strong> Bestand downloaden</strong>
          .
          {' '}
          <div className="desktop">
            Het bestand wordt opgeslagen in de map
            <strong> Downloads</strong>
            .
          </div>
        </li>
      </ol>
      <p>
        *Krijg je de melding dat het niet mogelijk is om gegevens van een periode langer dan 3 maanden in één keer te downloaden?
        <span onClick={() => setExtraInfo(true)}> Klik </span>
        hier voor verdere instructies.
      </p>
      {
          extraInfo
          && (
          <>
            <p>
              <strong>Volg onderstaande stappen:</strong>
            </p>
            <ol>
              <li>
                Kwartaal 1: Vul de datum in van
                <strong className="date">{` ${moment().add(1, 'days').subtract(1, 'years').format('DD-MM-YYYY')} `}</strong>
                t/m
                <strong className="date">{` ${moment().subtract(1, 'years').add(3, 'months').format('DD-MM-YYYY')} `}</strong>
                en klik op
                <strong> Bestand downloaden</strong>
                .
              </li>
              <li>
                Kwartaal 2: Vul de datum in van
                <strong className="date">
                  {` ${moment().add(1, 'days').subtract(1, 'years').add(3, 'months')
                    .format('DD-MM-YYYY')} `}
                </strong>
                t/m
                <strong className="date">{` ${moment().subtract(1, 'years').add(6, 'months').format('DD-MM-YYYY')} `}</strong>
                en klik op
                <strong> Bestand downloaden</strong>
                .
              </li>
              <li>
                Kwartaal 3: Vul de datum in van
                <strong className="date">
                  {` ${moment().add(1, 'days').subtract(1, 'years').add(6, 'months')
                    .format('DD-MM-YYYY')} `}
                </strong>
                t/m
                <strong className="date">{` ${moment().subtract(1, 'years').add(9, 'months').format('DD-MM-YYYY')} `}</strong>
                en klik op
                <strong> Bestand downloaden</strong>
                .
              </li>
              <li>
                Kwartaal 4: Vul de datum in van
                <strong className="date">
                  {` ${moment().add(1, 'days').subtract(1, 'years').add(9, 'months')
                    .format('DD-MM-YYYY')} `}
                </strong>
                t/m
                <strong className="date">{` ${moment().subtract(1, 'years').add(12, 'months').format('DD-MM-YYYY')} `}</strong>
                en klik op
                <strong> Bestand downloaden</strong>
                .
              </li>
            </ol>
          </>
          )
        }
      <ol start="6">
        <p className="desktop">
          <strong>Let op! </strong>
          Je kunt dit bestand zelf niet openen. Het is een gecodeerd bestand speciaal voor boekhoudprogramma’s en dergelijke.
        </p>
        <li>
          <strong>Upload </strong>
          het bestand in het blauwe venster onderin deze pagina.
        </li>
      </ol>
      <p className="mobile">
        <br />
        <strong>Lukt het niet op je mobiel of tablet?</strong>
        <br />
        Maak je aanvraag af via de computer. Dat is een stuk makkelijker! Vervolg daar je aanvraag door op ‘Direct uploaden’ te klikken in de welkomstmail die je hebt ontvangen.
      </p>
      <p className="desktop">
        <br />
        <strong>Kom je er niet uit?</strong>
        <br />
        Bekijk de instructievideo.
      </p>
      <div className="video">
        <iframe title="rabo" src="https://www.youtube.com/embed/nNdhErsd4bQ" frameBorder="0" allowFullScreen />
      </div>
      <p>
        <br />
        <strong>Hulp nodig?</strong>
        <br />
        Bel onze Customer Success Managers via
        <a href={Telephone_Link}> {Telephone_no_space}</a>
        .
      </p>
    </>
  );
};
