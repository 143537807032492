import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../utils/confetti';

/* Constants */
import { primary, primary2, red } from '../../constants/colors';

/* Style */
import StyledComponent from './style';

const Confetti = ({ children }) => {
  const cardEl = useRef(null);
  const doConfetti = () => {
    window.confetti(cardEl.current, {
      angle: 270,
      spread: 200,
      startVelocity: 100,
      elementCount: 60,
      decay: .77,
      colors: [primary, primary2, red],
    });
  }

  useEffect(() => {
    cardEl && setTimeout(() => {
      doConfetti();
    }, 500);
  }, [cardEl]);

  return (
    <StyledComponent>
      <div className="start" ref={cardEl} />
      {children}
    </StyledComponent>
  );
}

Confetti.propTypes = {
  children: PropTypes.node.isRequired,
}

Confetti.defaultProps = {
  children: null,
}

export default Confetti;
