import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

/* Style */
import StyledComponent from './style';

const Checkbox = ({
  name, label, fontSize,
  defaultChecked, required,
}) => {
  const { register, errors } = useFormContext();
  const registerData = { required };
  const error = errors[name];
  const componentClasses = classNames({
    error,
  });

  return (
    <StyledComponent
      className={componentClasses}
      fontSize={fontSize}
      htmlFor={name}
    >
      <div>
        <input
          id={name}
          name={name}
          type="checkbox"
          defaultChecked={defaultChecked}
          ref={register(registerData)}
        />
        <div className="checkbox" />
      </div>
      <div className="label">{label}</div>
    </StyledComponent>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  defaultChecked: PropTypes.bool,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {
  fontSize: 14,
  defaultChecked: false,
  required: false,
};

export default Checkbox;
