import styled from 'styled-components';

/* Constants */
import { xsMinWidth, smMinWidth, desktopMediumMaxWidth, mobileMediumMaxWidth } from '../../constants/breakpoints';
import { blue } from '../../constants/colors';

export default styled.div`
  .amount {
    height: 125px;
    font-size: 80px;
    font-weight: 500;
    color: ${blue};
    display: flex;
    flex-direction: row;
    overflow: hidden;

    @media only screen and (min-width: ${xsMinWidth}px) {
      font-size: 100px;
    }

    @media only screen and (max-width: 382px) {
      font-size: 48px;
      height:75px;

      &:before {
        font-size: 48px;
        line-height:75px;
      }
    }

     @media only screen and (max-width: 425px) {
       font-size: 65px;
    
       &:before {
        font-size: 65px;
        line-height:75px;
      }
     }

     @media only screen and (max-width: 320px) {
      font-size: 55px;
   
      &:before {
        font-size: 55px;
        line-height:75px;
      }
    }

    input {
      width: 100%;
      font-size: inherit;
      color: inherit;
      font-weight: 600;
    }

    &:before {
      content: '€';
      line-height: 125px;
      font-weight: 600;
      
      @media only screen and (max-width: 382px) {
        line-height:75px;
      }
        
      
    }

    
  }

  .amount-widget{
    height: 125px;
    font-size: 80px;
    font-weight: 500;
    color: ${blue};
    display: flex;
    flex-direction: row;
    overflow: hidden;


    // @media only screen and (min-width: ${xsMinWidth}px) {
    //   font-size: 60px;
    //   height: 100px;

    //   &:before {
    //     line-height:100px;
    //   }
    // }

    @media only screen and (min-width: ${desktopMediumMaxWidth}px) {
      font-size: 60px;
      height: 100px;

      &:before {
        line-height:100px !important;
      }
    }

    @media only screen and (max-width: 1023px) {
      font-size: 48px;
      height: 86px;

      &:before {
        line-height:86px !important;
      }
    }

    @media only screen and (max-width: 425px) {
      font-size: 48px;
      height: 98px;

      &:before {
        line-height:98px !important;
      }
    }

    @media only screen and (max-width: 320px) {
      font-size: 35px;
      height:75px;

      &:before {
        line-height:75px !important;
      }
    }

    input {
      width: 100%;
      font-size: inherit;
      color: inherit;
      font-weight: 600;
    }

    &:before {
      content: '€';
      line-height: 125px;
      font-weight: 600;
      

      @media only screen and (max-width: 382px) {
        line-height:75px;
      }
      
    }
  }

`;
