import {
  SET_DIALOG,
  TOGGLE_DIALOG,
} from '../actions/dialog';

const INITIAL_STATE = {
  open: false,
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DIALOG:
      return {
        ...state,
        children: action.children,
      };
    case TOGGLE_DIALOG:
      return {
        ...state,
        open: action.open,
      };
    default:
      return state;
  }
}
