import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Objects */
import Divider from '../../objects/divider';

import {Telephone, Telephone_Link} from '../../constants/commons';

const partner = localStorage.getItem('partner');
export const Thanks = ({ firstName, company, partnerFirstName, from }) => (

  <StyledComponent>
    <div className="icon">
      <i className="icmn-high-five" />
    </div>
    {
      firstName
        ? <h2 className="thnkyouHeading">{`Bedankt voor ${partner ? 'de' : 'je'} aanvraag ${partner ? partnerFirstName : firstName}!`}</h2>
        : <h2 className="thnkyouHeading">{from === 'revision-document-upload' ? 'Bedankt voor het uploaden van je banktransacties!'
              : 'Bedankt voor je aanvraag!'}</h2>
    }
    <Divider align="center" width="233px" />
    {
      from === 'revision-document-upload' ? 
      <p className="lead thankuDesc">We hebben al je gegevens in goede orde ontvangen. We gaan nu de kredietcheck uitvoeren.</p>
      :
      <p className="lead thankuDesc">
      { company && partner ? `We hebben de gegevens in goede orde ontvangen en gaan nu alle slimme koppen bij elkaar steken en kijken hoeveel ${company} kan lenen.`
        :'We hebben al je gegevens in goede orde ontvangen. We gaan nu alle slimme koppen bij elkaar steken om je leencapaciteit te berekenen.'}
      </p>

    }
    
    <div className="mt-40 subHeading">
      <strong style={{ "font-weight": 700}}>We komen zo snel mogelijk bij je terug!</strong>
    </div>
    <div className="contact mt-40 marginBottomInButtonsDesktop">
        {
          partner ?
          <img src={`${process.env.PUBLIC_URL}/images/success-managers/frisovanderputten.png`} width="108px" alt="contact" />
          :
          <img src={`${process.env.PUBLIC_URL}/images/csm_noBackground.png`} width="155px" alt="contact" />
        }

      <strong className="contactDetails">Heb je nog vragen?</strong>
      <br />
      {partner ?
        (<div className="contactTxt">Friso zit voor je klaar!
        <br />
        Bel <a href={Telephone_Link}>{Telephone}</a> of mail naar partners@bridgefund.nl
        </div>)

        : (<div className="contactTxt">
          Bel met Isabelle of Pieter
          <br />
          <span>via <a className="phone" href={Telephone_Link}>{Telephone}</a></span>
          </div>
          )}
    </div>
  </StyledComponent>
);

Thanks.propTypes = {
  firstName: PropTypes.string,
};

Thanks.defaultProps = {
  firstName: null,
  from : ""
};

export default connect((state) => ({
  firstName: state.request.firstName,
  partnerFirstName: state.request.partnerFirstName,
  company: state.request.company,
}))(Thanks);
