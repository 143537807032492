import React from 'react';

/* Style */
import StyledComponent from './style';

export default () => (
  <StyledComponent>
    <img src={`${process.env.PUBLIC_URL}/images/loader.svg`} alt="loader" />
  </StyledComponent>
);
