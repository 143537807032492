import React, { useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useFormContext } from 'react-hook-form';

/* Style */
import StyledComponent from './style';

/* Objects */
import Slider from '../../objects/slider';

const SelectMonths = ({
  name, startMonths, minMonths,
  maxMonths, required,
}) => {
  const { register, setValue, watch } = useFormContext();
  const step = 1;
  const registerData = { required };
  const amountString = watch(name);
  const [amountInt, setAmountInt] = useState(amountString ? parseInt(amountString, 10) : startMonths);
  const currentPercentage = ((amountInt - minMonths) / (maxMonths - minMonths)) * 100;
  const thumbPixels = ((32 / 100) * currentPercentage);
  const style = {
    left: `calc(${currentPercentage}% ${currentPercentage >= 50 ? '-' : '+'} ${currentPercentage >= 50 ? thumbPixels / 2 : 16 - thumbPixels}px)`,
  };

  // After slider value change
  const handleChange = (event) => {
    const { value } = event.target;
    setValue(name, numeral(value).format(), true);
    setAmountInt(parseInt(value, 10));
  };

  return (
    <StyledComponent>
      <input
        ref={register(registerData)}
        type="hidden"
        id={name}
        name={name}
        defaultValue={numeral(amountInt).format()}
      />
      <div className="months" style={style}>
        {`${numeral(amountInt).format()} mnd`}
      </div>
      <Slider
        min={minMonths}
        max={maxMonths}
        step={step}
        value={amountInt}
        onChange={handleChange}
      />
    </StyledComponent>
  );
};

SelectMonths.propTypes = {
  name: PropTypes.string.isRequired,
  startMonths: PropTypes.number,
  minMonths: PropTypes.number,
  maxMonths: PropTypes.number,
  required: PropTypes.bool,
};

SelectMonths.defaultProps = {
  startMonths: 6,
  minMonths: 3,
  maxMonths: 24,
  required: false,
};

export default SelectMonths;
