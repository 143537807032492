import axios from 'axios';

/* Redux */
import { toggleLoading } from './loading';
import { fetchOpportunity } from './opportunity';

import ENV from '../../config/env';

/* Actions */
export const SET_DEPTFORM_VALUES = 'SET_DEPTFORM_VALUES';
export const RESET_DEPTFORM_VALUES = 'RESET_DEPTFORM_VALUES';
export const SET_DEPTFORM_PAGE = 'SET_DEPTFORM_PAGE';
export const SET_PREDEFINED_PARTIES_LOAN = 'SET_PREDEFINED_PARTIES_LOAN';
export const SET_PREDEFINED_PARTIES_LEASE = 'SET_PREDEFINED_PARTIES_LEASE';
export const SET_PREDEFINED_PARTIES_PAYMENT_BACKLOGS = 'SET_PREDEFINED_PARTIES_PAYMENT_BACKLOGS';
export const SET_TYPE_OF_LEASE_OBJECT = 'SET_TYPE_OF_LEASE_OBJECT';
export const REMOVE_DEBT_VALUES = 'REMOVE_DEBT_VALUES';

const setLocalStorage = (object) => {
  const storageObject = { ...object };
  delete storageObject.submit;
  localStorage.setItem('dept-form', JSON.stringify(storageObject));
  
};

export const setValues = (values) => {
  if (!values) return false;
  return (dispatch, store) => {
    const { deptForm } = store();

    // Set localStorage
    setLocalStorage({
      ...deptForm,
      ...values,
    });

    dispatch({
      type: SET_DEPTFORM_VALUES,
      values,
    });
  };
};

export const resetValues = (values = {}) => (dispatch) => {
  setLocalStorage(values);
  dispatch({
    type: RESET_DEPTFORM_VALUES,
    values,
  });
};

export const removeValues = (item) => (dispatch) => { 
  let dataObj = JSON.parse(localStorage.getItem('dept-form'))
  if(dataObj && dataObj.item){
    delete dataObj.item;
    setLocalStorage(dataObj);
  }

  dispatch({
    type: REMOVE_DEBT_VALUES,
    item: item,
  });
};

export const nextPage = () => (dispatch, store) => {
  const { deptForm } = store();
  const currentPage = deptForm.currentPage || 0;

  dispatch(setValues({
    currentPage: currentPage + 1,
  }));
};

export const prevPage = () => (dispatch, store) => {
  const { deptForm } = store();
  const currentPage = deptForm.currentPage || 1;

  dispatch(setValues({
    currentPage: currentPage - 1,
  }));
};

export const submit = () => (dispatch, store) => {
  const { complete, deptForm } = store();

  // deleteing predefiend party objects and lease objects
  delete deptForm.leasePredefinedParties;
  delete deptForm.loanPredefinedParties;
  delete deptForm.paymentBacklogsPredefinedParties;
  delete deptForm.typeOfLeaseObject;

  const formData = {
    ...deptForm,
    id: complete.id,
  };

  // Set loading state
  dispatch(toggleLoading('deptForm', true));

  axios.post('https://www.bridgefund.nl/create_debt_document.php', formData)
    .then((response) => response.data)
    .then(() => {
      dispatch(fetchOpportunity(true)).then(() => {
        dispatch(setValues({ submit: true }));
        dispatch(toggleLoading('deptForm', false));
      });
    });
};


export const requestPredefinedParties  = (reqData) => (dispatch) => {
 
  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*' 
    }
  };
  
  axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/predefined-parties`, reqData, axiosConfig)
  .then((response) => response.data)
    .then((responseData) => {
      let data = responseData.data;
      if(data){
        let optionsLoan = [];
        let optionsLease = [];
        let optionPaymentBacklogs = [];
        let optionsLeaseObjects = [];
        data.map((item) => {
           if(item.fieldName === 'party-names-loans'){
             if(item.fieldNameValue !== "Others" && item.fieldNameValue !== "Overige"){
              optionsLoan.push(item.fieldNameValue);
             }
           }else if(item.fieldName === 'party-names-lease-contracts' ){
            if(item.fieldNameValue !== "Others" && item.fieldNameValue !== "Overige"){
              optionsLease.push(item.fieldNameValue);
            }
           }else if(item.fieldName === 'party-names-payment-backlogs'){
            if(item.fieldNameValue !== "Others" && item.fieldNameValue !== "Overige"){
              optionPaymentBacklogs.push(item.fieldNameValue);
            }
           }else if(item.fieldName === 'type-of-lease-objects'){
            optionsLeaseObjects.push({
              "lable" : item.fieldNameValue,
              "value" : item.fieldNameValue
            })  
           }

        });

          dispatch({
            type: SET_PREDEFINED_PARTIES_LOAN,
            values : optionsLoan
          })

          dispatch({
            type: SET_PREDEFINED_PARTIES_LEASE,
            values : optionsLease
          })

          dispatch({
            type: SET_PREDEFINED_PARTIES_PAYMENT_BACKLOGS,
            values : optionPaymentBacklogs
          })

          dispatch({
            type: SET_TYPE_OF_LEASE_OBJECT,
            values : optionsLeaseObjects
          })
       
    }
      
  });
};

export const updateSMELoanRequest  = (reqData) => (dispatch) => {

  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*' 
    }
  };

  axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/update-sme-loan-request`, reqData, axiosConfig)
  .then((response) => response.data)
    .then((responseData) => {

    });

};

export const updateDebtAtThirdParty  = (reqData) => (dispatch) => {
  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*' 
    }
  };

  axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/update-debt-at-third-party`, reqData, axiosConfig)
  .then((response) => response.data)

};

export const updateDebtor_Creditor  = (reqData) => (dispatch) => {
  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*' 
    }
  };

  axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/update-debtor-creditor`, reqData, axiosConfig)
  .then((response) => response.data)

};

