import React, { useState } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

/* Constants */
import { completeOverview } from '../constants/routes';

/* Objects */
import Divider from '../objects/divider';
import Grid from '../objects/grid';
import File from '../objects/file';
import Button from '../objects/button';

/* Components */

/* Redux */
import { setValues, sendFiles } from '../redux/actions/complete';

const BankStatement = ({
  dispatch, history, loading,
  isSubmitted,
}) => {
  const formMethods = useForm({ mode: 'onChange' });
  const { handleSubmit, formState } = formMethods;
  const [files, setFiles] = useState([]);

  if (isSubmitted) {
    // GA tracking pageviews
    ReactGA.pageview(`${window.location.pathname}-complete${window.location.search}`);

    // Redirect after submit
    setTimeout(() => {
      dispatch(setValues({ submit: false }));
      history.push(completeOverview);
    }, 2000);
  }

  const handleFiles = (f) => {
    setFiles(f);
  };

  const onSubmit = () => {
    dispatch(sendFiles('SCREENSHOT', files));
  };

  return (
    <>
      <h2>
        Recente foto van je zakelijke bankrekening(en)
        <br />
        (afgelopen 6 mnd)
      </h2>
      <Divider />
      <p className="lead light smallFontDark">
        Upload een screenshot of duidelijke foto van je zakelijke bankrekening(en). Belangrijk is dat het IBAN-nummer en de tenaamstelling duidelijk in beeld zijn.
        <br />
        <br />
        Een screenshot maak je in Windows (Alt + PrtScn) of Mac (cmd + shft + 3).
      </p>
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-40">
            <File
              name="bankstatement"
              label="Selecteer je bankafschrift"
              labelActive="Selecteer nog een bankafschrift"
              onChange={handleFiles}
              accept=".jpg,.jpeg,.png,.pdf"
              allowedExtensions={/(\.jpg|\.jpeg|\.png|\.pdf)$/i}
              required
            />
          </div>
          <div className="mt-40 inDesktop">
            <Grid>
              {
                !formState.isValid
                && (
                  <Link to={completeOverview}>
                    <Button width="100%" outline>Terug naar mijn dossier</Button>
                  </Link>
                )
              }
              <Button
                type="submit"
                width="100%"
                loading={loading}
                success={isSubmitted}
                disabled={!formState.isValid}
                buttonSize= {formState.isValid ? "halfRight" : ''}
              >
                {'Verzenden'}
              </Button>
            </Grid>
          </div>
          <div className="mt-40 inMobile">
            <Grid>
              <Button
                type="submit"
                width="100%"
                loading={loading}
                success={isSubmitted}
                disabled={!formState.isValid}
                buttonSize= {formState.isValid ? "fullWidthBtn" : ''}
              >
                {'Verzenden'}
              </Button>
              {
                !formState.isValid
                && (
                  <Link to={completeOverview}>
                    <Button width="100%" outline>Terug naar mijn dossier</Button>
                  </Link>
                )
              }
            </Grid>
          </div>
        </form>
      </FormContext>
      {/* <Footer /> */}
    </>
  );
};

BankStatement.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

BankStatement.defaultProps = {
  loading: false,
  isSubmitted: false,
};

export default connect((state) => ({
  loading: state.loading.complete,
  isSubmitted: state.complete.submit,
}))(BankStatement);
