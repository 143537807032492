import styled, { css } from 'styled-components';

/* Constants */
import breakpoints, { xsMinWidth } from '../../constants/breakpoints';

export default styled.div`
  display: -ms-grid;
  display: grid;
  flex-direction: row;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  column-gap: ${(props) => props.columnGap}px;
  row-gap: ${(props) => props.rowGap}px;
  order: ${(props) => props.order};

  /* Set columns in string format */
  ${(props) => typeof props.gridColumns === 'string'
    && css`
      @media (min-width: ${xsMinWidth}px) {
        order: unset;
        -ms-grid-columns: ${props.gridColumnsIE};
        grid-template-columns: ${props.gridColumns};
      }
    `}

  /* Loop object with break points */
  ${(props) => typeof props.gridColumns === 'object'
    && Object.keys(props.gridColumns).map((key) => css`
      @media (min-width: ${breakpoints[key]}px) {
        order: unset;
        -ms-grid-columns: ${props.gridColumnsIE[key]};
        grid-template-columns: ${props.gridColumns[key]};
      }
    `)}

  /* For grid in grid */
  & & {
    grid-template-rows: repeat(auto-fit, minmax(min-content, 1px));
  }

  /* IE11 fix create rows and columns */
  & > * {
    display: block;
  }

  ${(props) => {
    let column = 0;
    let row = 1;

    return Array(10).fill().map((v, index) => {
      if ((index + 1) % props.totalColumns === 0) {
        row++;
        column = 0;
      }

      column++;
      return css`
        & > *:nth-child(${index}) {
          -ms-grid-column: ${column};
          -ms-grid-row: ${row};
        }
      `;
    });
  }}
`;
