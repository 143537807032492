import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Objects */
import ProgressBar from '../../objects/progress-bar';

/* Pages */
import LegalForm from './pages/11LegalForm';
import Stakeholders from './pages/21Stakeholders';
import Shareholders from './pages/22Shareholders';
import Partners from './pages/31Partners';
import Owner from './pages/41Owner';
import Sign from './pages/51Sign';

export const UboForm = ({ currentPage, legalForm }) => {
  const flows = {
    bv: [
      {
        title: 'Rechtsvorm',
        component: LegalForm,
      },
      {
        title: 'Belanghebbenden',
        component: Stakeholders,
      },
      // {
      //   title: 'Aandeelhouders',
      //   component: Shareholders,
      // },
      {
        title: 'Verklaring en ondertekening',
        component: Sign,
      },
    ],
    vof: [
      {
        title: 'Rechtsvorm',
        component: LegalForm,
      },
      {
        title: 'Vennoten',
        component: Partners,
      },
      {
        title: 'Verklaring en ondertekening',
        component: Sign,
      },
    ],
    eenmanszaak: [
      {
        title: 'Rechtsvorm',
        component: LegalForm,
      },
      {
        title: 'Eigenaar',
        component: Owner,
      },
      {
        title: 'Verklaring en ondertekening',
        component: Sign,
      },
    ],
  };
  const pages = flows[legalForm.toLowerCase()];
  const { title, component } = pages[currentPage];
  const Page = component;

  return (
    <StyledComponent>
      <p className="light">
        Stap {currentPage + 1} van {pages.length} - {title}
      </p>
      <div className="mt-10">
        <ProgressBar
          currentPage={currentPage}
          totalPages={pages.length}
        />
      </div>
      <div className="mt-40">
        <Page />
      </div>
    </StyledComponent>
  );
};

UboForm.propTypes = {
  currentPage: PropTypes.number,
  legalForm: PropTypes.string,
};

UboForm.defaultProps = {
  currentPage: 0,
  legalForm: 'bv',
};

export default connect((state) => ({
  currentPage: state.uboForm.currentPage,
  legalForm: state.uboForm.legalForm,
}))(UboForm);
