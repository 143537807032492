import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';

/* Constants */
import { mdMinWidth } from '../../constants/breakpoints';

/* Style */
import StyledComponent from './style';

/* Components */
import FullScreenSearch from '../full-screen-search';

/* Redux */
import { toggleLoading } from '../../redux/actions/loading';
import { toggleCompanies, getCompaniesByQuery, clearCompanies, checkApiAvilability } from '../../redux/actions/companies';
import { openDialog } from '../../redux/actions/dialog';

export const SearchCompany = ({ dispatch, invalidBaseProfile, suggestions }) => {
  const { register, setValue, watch } = useFormContext();
  const [query, setQuery] = useState(watch().company || '');
  const [selectedItem, setSelectedItem] = useState(watch() || null);
  let no_kvk = false;
  const registerData = {};
  const result = selectedItem.kvkNumber && !invalidBaseProfile
    ? (
      <>
        <strong>
          {/* {`${selectedItem.street} ${selectedItem.houseNumber}, ${selectedItem.zipcode} ${selectedItem.city}. `} */}
          {`${selectedItem.street}, ${selectedItem.city}. `}
        </strong>
        {`KVK nummer: ${selectedItem.kvkNumber}`}
      </>
    ) : null;

  // After the search query is set
  useEffect(() => {
    if (query.length < 3) {
      dispatch(clearCompanies());
      return;
    }

    const timer = setTimeout(() => {
      let itemFound = false;
      suggestions.find(item => {
        itemFound = item && ( item.handelsnaam === query || item.kvkNummer === query );
      });
 
      if(!itemFound){
       dispatch(getCompaniesByQuery(query));
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(checkApiAvilability("BridgeFund BV"));
  }, [])

  const selectItem = (item) => {
    // To show selected item

    setSelectedItem({
      //...item.addresses[0],
      kvkNumber: item.kvkNummer ? item.kvkNummer : '',
      street: item.straatnaam ? item.straatnaam : '',
      city: item.plaats ? item.plaats : '',
      zipcode: item.addresses ? item.addresses[0].postalCode : '',
    });
    // Set values in form hook
    setValue('kvkNumber', item.kvkNummer, true);
    setValue('company', item.handelsnaam, true);
    if (item.website) setValue('website', item.websites[0], true);
    if (item.straatnaam) setValue('street', item.straatnaam, true);
    if (item.addresses) setValue('houseNumber', item.addresses[0].houseNumber, true);
    if (item.addresses) setValue('zipcode', item.addresses[0].postalCode, true);
    if (item.plaats) setValue('city', item.plaats, true);
    // Reset input value
    setQuery(item.handelsnaam);
    // Hide TextBalloon
    dispatch(toggleCompanies(false));
    dispatch(getCompaniesByQuery(item.kvkNummer));
  };

  const handleChange = (e) => {
    const value = e.target.value || '';
    setQuery(value);

    if (window.innerWidth > mdMinWidth && value.length >= 3) {
      dispatch(toggleLoading('companies', true));
      dispatch(toggleCompanies(true));
    }
  };

  const handleFocus = () => {
    const dialog = <FullScreenSearch onSelect={selectItem} />;
    dispatch(openDialog(dialog));
  };

  const handleBlur = () => {
    dispatch(toggleCompanies(false));
  };

  let inputValue = query;
  if(inputValue && inputValue.match(/NO[\s]+?KVK/)){
    no_kvk = true;
    inputValue = inputValue.split("NO KVK - ")[1];
  }

  return (
    <StyledComponent>
      <div className="input-holder">
        <input
          className="search"
          placeholder="Doorzoek de KVK"
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      {
        !no_kvk ?
        <div className="result">{result}</div>
        :
        <div className="result"></div>
      }
      <input
        id="kvkNumber"
        name="kvkNumber"
        type="hidden"
        ref={register({ required: true })}
      />
      <input
        id="company"
        name="company"
        type="hidden"
        ref={register(registerData)}
      />
      <input
        id="website"
        name="website"
        type="hidden"
        ref={register(registerData)}
      />
      <input
        id="street"
        name="street"
        type="hidden"
        ref={register(registerData)}
      />
      <input
        id="houseNumber"
        name="houseNumber"
        type="hidden"
        ref={register(registerData)}
      />
      <input
        id="zipcode"
        name="zipcode"
        type="hidden"
        ref={register(registerData)}
      />
      <input
        id="city"
        name="city"
        type="hidden"
        ref={register(registerData)}
      />
    </StyledComponent>
  );
};

SearchCompany.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkApiAvilability: PropTypes.func.isRequired,
  invalidBaseProfile: PropTypes.bool
};

SearchCompany.defaultProps = {
  loading: false,
  invalidBaseProfile: false,
  suggestions: []
};

export default connect((state) => ({
  loading: state.loading.companies,
  suggestions: state.companies.items,
  invalidBaseProfile: state.error.invalidBaseProfile
}))(SearchCompany);
