import {
  SET_DEPTFORM_VALUES,
  RESET_DEPTFORM_VALUES,
  SET_DEPTFORM_PAGE,
  SET_PREDEFINED_PARTIES_LOAN,
  SET_PREDEFINED_PARTIES_LEASE,
  SET_PREDEFINED_PARTIES_PAYMENT_BACKLOGS,
  SET_TYPE_OF_LEASE_OBJECT,
  REMOVE_DEBT_VALUES,
} from '../actions/dept-form';

const INITIAL_STATE = JSON.parse(localStorage.getItem('dept-form')) || {};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DEPTFORM_VALUES:
      return {
        ...state,
        ...action.values,
      };
    case RESET_DEPTFORM_VALUES:
      return {
        ...action.values,
      };
    case SET_DEPTFORM_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case SET_PREDEFINED_PARTIES_LOAN : 
    return {
      ...state,
      loanPredefinedParties: action.values,
    };
    case SET_PREDEFINED_PARTIES_LEASE : 
    return {
      ...state,
      leasePredefinedParties: action.values,
    };
    case SET_PREDEFINED_PARTIES_PAYMENT_BACKLOGS :
    return {
      ...state,
      paymentBacklogsPredefinedParties: action.values,
    };
    case SET_TYPE_OF_LEASE_OBJECT :
      return {
        ...state,
        typeOfLeaseObject: action.values,
    };
    case REMOVE_DEBT_VALUES:
      let newState = state;
      let item = action.item;
      if(newState[item]){
        delete newState[item];
      }
      return {
        ...newState
      };
    default:
      return state;
  }
}
