import axios from 'axios';

/* Redux */
import { toggleLoading } from './loading';
import { fetchOpportunity } from './opportunity';

/* Actions */
export const SET_UBOFORM_VALUES = 'SET_UBOFORM_VALUES';
export const RESET_UBOFORM_VALUES = 'RESET_UBOFORM_VALUES';
export const SET_UBOFORM_PAGE = 'SET_UBOFORM_PAGE';

const setLocalStorage = (object) => {
  const storageObject = { ...object };
  delete storageObject.submit;
  localStorage.setItem('ubo-form', JSON.stringify(storageObject));
};

export const setValues = (values) => {
  if (!values) return false;

  return (dispatch, store) => {
    const { uboForm } = store();

    // Set localStorage
    setLocalStorage({
      ...uboForm,
      ...values,
    });

    dispatch({
      type: SET_UBOFORM_VALUES,
      values,
    });
  };
};

export const resetValues = (values = {}) => (dispatch) => {
  setLocalStorage(values);
  dispatch({
    type: RESET_UBOFORM_VALUES,
    values,
  });
};

export const nextPage = () => (dispatch, store) => {
  const { uboForm } = store();
  const currentPage = uboForm.currentPage || 0;

  dispatch(setValues({
    currentPage: currentPage + 1,
  }));
};

export const prevPage = () => (dispatch, store) => {
  const { uboForm } = store();
  const currentPage = uboForm.currentPage || 1;

  dispatch(setValues({
    currentPage: currentPage - 1,
  }));
};

export const submit = () => (dispatch, store) => {
  const { complete, uboForm } = store();

  // adding the hidden birthdayPartner as empty string
  let valueItems = ["stakeholders", "partners", "owners"];

  for (var key in uboForm) {
    if(valueItems.includes(key)){
    if (uboForm.hasOwnProperty(key)) {
        uboForm[key].forEach((item) => {
       item.birthdayPartner = "";
        })
      }
    }
  };

  const formData = {
    ...uboForm,
    id: complete.id,
  };

  // Set loading state
  dispatch(toggleLoading('uboForm', true));

  axios.post('https://www.bridgefund.nl/create_ubo_document.php', formData)
    .then((response) => response.data)
    .then(() => {
      dispatch(fetchOpportunity(true)).then(() => {
        dispatch(setValues({ submit: true }));
        dispatch(toggleLoading('uboForm', false));
      });
    });
};
