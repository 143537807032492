import styled from 'styled-components';

/* Constants */
import { gray, primary } from '../../constants/colors';

export default styled.div`
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    color: ${gray};

    & > div:last-child {
      margin-left: auto;
      font-weight: 600;
    }
  }

  .highlight {
    color: ${primary};
  }
`;
