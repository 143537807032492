import React, { useRef } from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

/* Components */
import Youtube from '../youtube';

/* Objects */
import Divider from '../../objects/divider';
import Button from '../../objects/button';

export const Waiting = ({ analysis }) => {
  const player = useRef(null);
  const nextVideo = () => {
    player.current.nextVideo();
  };
  const text = [
    {
      title: <h2>Wachten is #*@#</h2>,
      text: (
        <p className="lead light">
          Maar we zijn nog even druk aan het rekenen voor je!
          <br />
          <br />
          We verwachten binnen 45 minuten bij je terug te zijn. Voor in de tussentijd hebben we een aantal inspirerende talks voor je op een rijtje gezet. Met een paar van onze favoriete ondernemers aan het woord. Enjoy!
          <br />
          (Als je het niet te druk hebt natuurlijk.)
        </p>
      ),
    },
    {
      title: <h2>Wachten is #*@#</h2>,
      text: (
        <p className="lead light">
          Maar we zijn nog even bezig met de laatste gegevens!
          <br />
          <br />
          We verwachten binnen een uurtje of twee bij je terug te zijn. Voor in de tussentijd hebben we een aantal inspirerende talks voor je op een rijtje gezet. Met een paar van onze favoriete ondernemers aan het woord. Enjoy!
          <br />
          (Als je het niet te druk hebt natuurlijk.)
        </p>
      ),
    },
  ];

  return (
    <StyledComponent>
      <div className="icon">
        <img src="https://media.giphy.com/media/tXL4FHPSnVJ0A/source.gif" alt="giphy" />
      </div>
      {text[analysis].title}
      <Divider align="center" />
      {text[analysis].text}
      <div className="mt-40">
        <Youtube ref={player} playlistId="PLeG_4Tum25-uaEQ_7q3R9BaVPkopsDsyP" shufflePlaylist />
      </div>
      <div className="mt-40">
        <Button onClick={nextVideo}>Ik wil iets anders zien</Button>
      </div>
    </StyledComponent>
  );
};

Waiting.propTypes = {
  analysis: PropTypes.number,
};

Waiting.defaultProps = {
  analysis: 0,
};

export default Waiting;
