import styled from 'styled-components';

/* Constants */
import { spaceLarge } from '../../constants/spaces';
import { purple, dark } from '../../constants/colors';

export default styled.div`
  text-align: center;

  .icon {
    width: 205px;
    height: 205px;
    margin: 0 auto ${spaceLarge}px;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    // background-color: #ffffff;
    // border-radius: 50%;
    background-image:url(${process.env.PUBLIC_URL}/images/Rect.svg);
    background-repeat:no-repeat;

    i {
      color: ${purple};
      font-size: 93px;
    }
  }

  .contact {
    line-height: 1.76;

    img {
      display: block;
      margin: 40px auto;
    }
  }

  #sorryHeading{
    font-weight:600;
    font-size:38px;
    color: ${dark};
  }

  .sorryDesc{
    font-size:21px;
    font-weight:400;
    color: ${dark};
  }

  .mainDesc{
    margin-top: 50px;
  }

  .subheading{
    font-size:21px;
    font-weight:700;
    color: ${dark};
  }

  .minorHeading{
    font-size:18px;
    font-weight: 700;
    color: ${dark};
  }

  .phone{
    font-size:18px;
    font-weight: 600;
    color: ${dark};
  }

  .contacts{
    font-size:18px;
    color: ${dark};
  }

`;
