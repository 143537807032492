import styled from 'styled-components';

/* Constants */
import { space, spaceSmall, spaceLarge, marginSpace } from '../../constants/spaces';
import {
  gray, grayDark, red,
  green, dark, dark2, clearBlue, stepsSideBarBackground, primary,
  primary3
} from '../../constants/colors';

import {
  smMinWidth,
  xsMinWidth,
  mobileLargeMaxWidth,
  desktopMediumMaxWidth,
} from '../../constants/breakpoints';

/* Styles */
import { icomoon } from '../../styles/icomoon';

export default styled.div`
  ul {
    list-style-type: none;
    margin: 0 0 40px 0;
    padding: 0;
  }

  li {
    padding: ${spaceSmall * 1.5}px 0;
    margin-bottom: ${marginSpace}px;
    border-radius: 6px;
    border: solid 1px #eeeeee;
    position: relative;
    transition: background-color 300ms ease;

    &:last-child {
      margin: 0;
    }

    .anchor {
      height: 100%;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .info {
    flex-grow: 1;
    padding-right: ${spaceLarge}px;

    @media only screen and (min-width: ${xsMinWidth}px) {
      padding-right: ${spaceLarge * 2}px;
    }
  }

  .lead {
    font-weight: 600;
    margin-bottom: ${marginSpace}px !important;
  }

  .label {
    font-size: 18px;
    line-height: 2;
    font-weight:400;
    color: ${dark2};
  }

  .time,
  .description {
    display: none;
    font-size: 17px;
    color: ${gray};
    line-height: 1.76;
  }

  .lightColor {
    color : #E30565 !important;
    font-weight: 400 !important;
  }

  .todo,
  .feedback,
  .complete {
    p {
      color: #444444;
    }

    ul {
      margin: 0;
    }

        .todoList .info {
          display: inline-flex;
          }

         .todoList li .anchor {
            font-size: 14px;
            padding: 6px 33px 6px 22px;
            border-radius:25px;
            background-color: ${clearBlue}; //rgba(228,0,102,0.1)
            color: ${stepsSideBarBackground};
            position: absolute;
            top: 50%;
            height: 28px;
            right: 1px;
            margin-right: 10px;
            font-weight: normal;
            transform: translateY(-50%);
          }

          .todoList li .anchor: hover {
            background-color: ${primary};
            color: white;

          }

          .todoList li .anchor:hover::after {
            color:white;
          }

          .todoList li .anchor:after {
            ${icomoon};
            content: "\\e909";
            font-size: 11px;
            color: ${stepsSideBarBackground};
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translateY(-50%) rotate(-90deg);
            font-weight: normal;
            }
          }

          .invullenBtn{
            padding: 6px 43px 6px 22px !important;
            font-size: 14px;
            font-weight: bold !important;
          }

          .uploadenBtn {
            font-size: 14px;
            font-weight: bold !important;
          }
  //   li:after {
  //     ${icomoon};
  //     content: "\\e909";
  //     font-size: 14px;
  //     color: ${red};
  //     position: absolute;
  //     top: 50%;
  //     right: ${space}px;
  //     transform: translateY(-50%) rotate(-90deg);

  //     @media only screen and (min-width: ${xsMinWidth}px) {
  //       right: ${spaceLarge}px;
  //     }
  //   }

  //   li:hover {
  //     background-color: #eeeeee;
  //   }
  // }

  .todo .time,
  .feedback .description {
    display: block;
    font-weight: 1;
  }

  .complete li {
    height: 90px;
  }

  .waiting,
  .done {
    .anchor {
      // pointer-events: none;
      // cursor: default;
      .info{
        .description {
          display: none;
        }
      }
    }
 }

  .feedback,
  .waiting{
    margin-top: 10px;
    // font-weight: bold;

    .info {
      display: inline-flex;
    }

    li .anchor {
      font-size: 14px;
      padding: 6px 33px 6px 22px;
      border-radius:25px;
      background-color: ${clearBlue}; 
      color: ${stepsSideBarBackground};
      position: absolute;
      top: 33%;
      height: 28px;
      right: 1px;
      margin-right: 10px;
      font-weight: normal;
    }


    li .anchor: hover {
      background-color: ${primary};
      color: white;

    }

    li .anchor:hover::after {
      color:white;
    }

    li .anchor:after {
      ${icomoon};
      content: "\\e909";
      font-size: 11px;
      color: ${stepsSideBarBackground};
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%) rotate(-90deg);
      font-weight: normal;
      }
    }

    .invullenBtn{
      padding: 6px 43px 6px 22px !important;
    }

    li {
      .info {
        .anchor: hover {
          background-color: #E40066;
          color: white;
        }
      }
    }
}

.feedback {
   i {
     margin-top:4%;
   }
}

  .done {
    p {
      color: ${green};
    }

    ul {
      margin: 0;
    }

    li {
      padding: ${marginSpace}px 0;
      background-color: rgba(22, 211, 169, 0.06);
      height: 60px;

      i:before {
        font-size: 32px;
        color: ${green};
        content: "\\e902";
      }
    }
  }

  i {
    width: 80px;
    font-size: 35px;
    color: ${grayDark};
    text-align: center;
    flex-shrink: 0;

    @media only screen and (min-width: ${xsMinWidth}px) {
      width: 100px;
    }
  }

  .todoList{
    margin-top:${marginSpace}px;
    font-weight: bold;
  }

  #stepStatusIcon{
    float:left;
    margin-right:1%;
    width: 27px
  }

  .leadWrapper{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
  }

  .leapHeadRight{
    width: 183px
  }

  #numberOfActions{
    font-size:15px;
    font-weight:400;
    color: ${dark2};
    opacity : 70%;

  }

  #numberOfActionsMobile{
    display:none;
  }

  .completerText{
    font-size:20px;
    font-weight:bold;
    line-height:32px;
    color: ${dark} !important;
  }

  @media only screen and (max-width: ${mobileLargeMaxWidth}px) {
    #numberOfActions{
       display:none;
      },

      #numberOfActionsMobile{
        display:block;
      }

      .leapHeadRight {
        width: 88px;
      }

      p.lead {
        font-size: 16px;
      }

      #stepStatusIcon {
        width: 26px;
        margin-left:13%;
        margin-right:1%
      }

      #numberOfActionsMobile{
        font-size: 13px;
        margin-top: 4%;
     }

      .label {
        font-size: 16px;
        line-height: 1.5;

        position: absolute;
        top: 50%;

        transform: translateY(-50%);
      }

      .feedback {
        .info {
          padding-right: 32px;
        }
        .mobileFeedback {
          all: initial;
          width:100%;
          height:100%
        }
        .label {
          font-size: 17px;
          line-height: 1.5;
          // font-weight: bold;

          position: relative !important;
          margin-top: 10px;
          transform: translateY(-9%);
        }
        .anchor {
          top: 40% !important;
        }
      }

      .done {
        .label {
          display: inline-flex;
          transform: translateY(-38%);
        }
        li {
          i:before {
             font-size: 22px;
             width: 62px;

          }

           i{
            width: 62px;
            font-size: 22px;
            }
          }
      }

      .todo {
        .label {
          top: 45% !important;
        }
        li {
          i:before {
            font-size: 22px;
            width: 62px;
          }

          i{
            width: 62px;
            font-size: 28px;
          }

        }
      }

      .uploadenBtn {
        display: none;
      }

      .invullenBtn{
        display: none;
      }

      .actionButtonMobile{
        display : block !important;

       }

      .todoList li .label {
        max-width:100% !important;
      }

      .todoList li .anchor:after{
        top: 35% !important;
      }

      .description{
        font-size:13px;
        line-height:1.4;
      }

      .todoList li .anchor:after {
        transform: translateY(-36%) rotate(-90deg) !important;
      }

    }

  .actionButtonMobile{
    padding: 0px 9px 6px 24px !important;
    background-color:transparent !important;
    margin-right: 1px !important;
    display : none;
    color : ${primary3} !important;
  }

  .actionButtonMobile:after{
    color : ${primary3} !important;
  }

  .info{
    width: 92%
  }

  .labelAndDesWrapper{
    width:100%;
  }

  .itemIcon{
    position: relative;
    float: left;

  }

  i {
    position: relative;
    float: left;
    top: 50%;

    transform: translateY(-55%);
  }

  .todoList {
    i {
      position: relative;
      float: left;
      top: 50%;

      transform: translateY(-38%);
    }
    .label {
      position: relative;
      top: 50%;

      transform: translateY(-38%);
    }
  }

  .mb-40{
    margin-bottom: 40px !important;
  }

  @media only screen and (max-width: 339px) {
    .leapHeadRight{
      width:112px;
    }
  }

  @media only screen and (max-width: 290px) {
    .leapHeadRight{
      width:116px;
    }
  }


  @media only screen and (max-width: ${smMinWidth}px) {

      .description{
        max-width: 83%;
      }

      .label{
        font-size:17px;
      }
     
      
  }

  @media only screen and (max-width: ${smMinWidth}px) {

      .description{
        max-width: 83%;
      }

      .label{
        font-size:17px;
      }
     
      
  }

  @media only screen and (max-width: ${desktopMediumMaxWidth}px) {

    .description{
      max-width: 96%;
    }

    
    
  }

  @media only screen and (min-width: 522px) and (max-width: 710px) {

    .description{
      max-width: 85%;
    }

  }

  @media only screen and (min-width: 990px) and (max-width: 1040px) {

    .description{
      max-width: 85%;
    }

    .label{
      font-size:19px;
    }
  }












`;
