import styled from 'styled-components';

/* Constants */
import { headerHeight } from '../../constants/spaces';
import { mdMinWidth } from '../../constants/breakpoints';

export default styled.div`
  min-height: calc(100vh - ${headerHeight}px);
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${mdMinWidth}px) {
    flex-direction: row;
  }

  .children {
    flex-grow: 1;
  }

  .sidebar {
    order: 1;
    background-color:#FDF2E6;

    @media only screen and (min-width: ${mdMinWidth}px) {
      order: 0;
    }

    &.right {
      order: 1;
    }
  }
`;
