import styled from 'styled-components';
import { lighten } from 'polished';

/* Constants */
import { space, spaceLarge } from '../../constants/spaces';
import {
  gray, fieldActive, primary2, green,
  fieldAction, fieldError, red, dark2,
  fileSelected, fileError
} from '../../constants/colors';

/* Styles */
import { icomoon } from '../../styles/icomoon';

export default styled.div`
  width: 100%;

  label {
    width: 100%;
    display: block;
  }

  &.selected {
    .file-drop-target {
      flex-direction: row;
      background-color: ${fileSelected};

      i {
        margin: 0 ${spaceLarge}px 0 0;
      }
    }
  }

  &.error {
    .file-drop-target {
      background-color: ${fileSelected};
    }
  }

  .file-drop-target {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${spaceLarge}px 0;
    border: dashed 1px #00997F;
    background-color: #00997E15;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 300ms ease-out;

    &.file-drop-dragging-over-target {
      background-color: ${fieldAction} !important;
    }

    i {
      font-size: 50px;
      margin: 0 0 ${space}px 0;
      color: ${primary2};
    }

    .file-amount {
      font-size: 14px;
      color: ${dark2};
      pointer-events: none;
      font-weight:400;
      text-align:center;

      strong {
        margin-bottom: ${space}px;
      }
    }

    .submitted {
      margin-right:5px;
      margin-left:8px;
    }
  }

  .set {
    width: 100%;
    display: block;
    padding: ${spaceLarge}px 0 ${space}px;
    margin-bottom: 20px;
    border: solid 1px #00997F;
    text-align: center;
    border-radius: 5px;
    transition: background-color 300ms ease-out;
    background-color: ${fileSelected};
    font-size: 14px;
    float:left;
  }

  .setError{
    background-color: ${fileError} !important;
    border: solid 1px #E30565;
  }

  .intro {
    width: 90%;
    margin: 0 auto;
    text-align: left;

    p {
      // font-weight: 600;
      float:left;
      font-size: 16px;
    }

    // &:before {
    //   ${icomoon};
    //   content: "\\e904";
    //   font-size: 60px;
    //   margin-bottom: ${space}px;
    //   color: ${green};
    // }
  }

  .fileMiddle{
    list-style-type: none;
    padding: 0;
  }

  .files {
    margin-top: ${space}px;
    list-style-type: none;
    padding: 0;

    li {
      height: 60px;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0 ${spaceLarge}px 0 ${space}px;
      // border-top: solid 1px white;
      // border-bottom: solid 1px #eeeeee;
      float: left;
      width: 100%;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .iconWithDescription {
      display: flex;
      flex-direction: row;
      width:75%;
    }

    i {
      color: ${green};
      font-size: 30px;
      margin-right: ${space * 1.5}px;
    }

    .name {
      text-align: left;
      text-decoration: underline;
    }

    .nameMiddle{
      text-align: center;
      width:100%;
    }

    .nameMiddleCloseIcon{
      padding-left:5px;
      cursor: pointer;
    }

    .delete {
      margin-left: auto;
      color: #1F2848;
      cursor: pointer;
      width:20%
    }

    &.errors {
      margin: 0;

      i {
        position: relative;
        transform: rotate(45deg);
        color: ${red};
      }

      span {
        display: block;
        font-size: 12px;
        color: ${gray};
      }
    }
  }

  .lockIcon {
    float: left;
    margin-right: 15px;
  }

  .fileNameIcon{
    margin-right: 15px;
    margin-left: 14px;
  }

  .successFile{
    color : #00997F;
  }

  .errorFile{
    color : #E30565;
  }

  .deleteIcon{
    float: right;
    margin-left: 15px;
    cursor: pointer;
  }

  .textHighlighted{
    font-weight:600;
  }

  @media only screen and (max-width: 425px) {
    .files {
      li {
        display:inline-flex;
      }

      .delete {
        float: right;
        width:38%;
      }
    }

    .submitted {
      margin-right:0px;
      margin-left:1px;
    }

    .uploadIcon{
      margin-left: 13px;
    }

    .beforeSubmit{
      text-align:center;
    }

    .intro p{
      float: none;
      line-height: 1;
    }

    .fileNameIcon{
      margin-left:0
    }

    .iconWithDescription{
      width:65%;
    }

    .deleteIcon{
      margin-left:2px;
    }

    .intro {
      width: 94%;
    }

  }

  input {
    opacity: 0;
    position: absolute;
  }

  .uploadIcon{
    margin-bottom: 8px;
    width: 58px;
  }

  .uploadWrapper{
    position: relative;
    float: left;
    width: 100%;
  }

  @media only screen and (max-width: 375px) {
    #dummy{
      width: 87%;
    }

    .intro p{
      float: none;
      line-height: 1;
    }

    .fileNameIcon{
      margin-left:0
    }

    .files {
      li{
        padding:0 8px 0 8px;
      }

      .iconWithDescription{
        width:56%;
        overflow: auto;
      }

      .delete {
        width:40%;
      }
    }

    .intro {
      width: 94%;
    }

  }

  @media only screen and (max-width: 320px) {

    .intro p{
      float: none;
      line-height: 1;
    }

    .fileNameIcon{
      margin-left:0
      margin-right: 7px;
    }

    .files {
      li{
        padding:0 5px 0 5px;
      }

      .iconWithDescription{
        width:56%;
        overflow: auto;
      }

      .delete {
        width:40%;
      }
    }

    .intro {
      width: 94%;
    }
  }
  

`;
