import React from 'react';

/* Objects */
import Container from '../objects/container';

/* Components */
import Waiting from '../components/waiting';

export default () => (
  <Container width={770}>
    <Waiting analysis={1} />
  </Container>
);
