import { getCookie } from "./cookie";

export const getGtmParameters = () => {

  let parameters = {
    utm_source: '(direct)',
    utm_medium: '(none)',
    utm_campaign: '(not set)',
    utm_term: '(not set)',
    utm_content: '(not set)',
    utm_id: '(not set)',
    gclid: '(not set)',
    s_id: '(not set)',
    at_gd: '(not set)'
  };

  try {
    let googleTagServiceDetails = getCookie('__gtm_campaign_url');

    if (googleTagServiceDetails) {
      googleTagServiceDetails = decodeURIComponent(googleTagServiceDetails).split('?')[1];

      if (googleTagServiceDetails) {

        const splitedGoogleTagServiceDetails = googleTagServiceDetails.split('&');

        Object.keys(parameters).forEach((key) => {
          if (parameters.hasOwnProperty(key)) {

            let value = parameters[key];

            splitedGoogleTagServiceDetails.forEach(item => {
              if (item.includes(key)) {
                value = item.split('=')[1];
              }
            })

            parameters[key] = value;

          }
        })
      }
    }
  } catch (err) {
    console.log('Failed to get cookie details: ' + err);
  }

  if ( parameters['gclid'] !== '(not set)' ) {
    parameters['utm_source'] = 'google';
    parameters['utm_medium'] = 'cpc';
  }

  return parameters;

}
