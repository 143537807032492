import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Card = ({ children, icon, image }) => (
  <StyledComponent>
    {
      icon
      && (<div className="icon iconWrapper">{icon}</div>)
    }
    {
      image
      && (<div className="iconWrapper"><img src={image} className="image" alt="icon" /></div>)
    }
    <div className="children">{children}</div>
  </StyledComponent>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  image: PropTypes.string,
};

Card.defaultProps = {
  icon: null,
  image: null,
};

export default Card;
