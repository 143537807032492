import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import numeral from 'numeral';

/* Constants */
import { personalInfo } from '../constants/routes';
import {
  activeOptions, activeValues, revenueOptions,
  revenueValues, goalOptions, whenOptions,
} from '../constants/options';

/* Objects */
import Container from '../objects/container';
import Grid from '../objects/grid';
import Button from '../objects/button';
import Select from '../objects/select';

/* Components */
import PageLayout from '../components/page-layout';
import SelectAmount from '../components/select-amount';
import RequestProgress from '../components/request-progress';

/* Redux */
import { setValues } from '../redux/actions/request';

const BasicInfo = ({ dispatch, request }) => {
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const amount = params.get('amount') ? numeral(params.get('amount')).format() : undefined;
  const widget = params.get('widget');

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: amount || request.amount || undefined,
      active: params.get('active') || request.active,
      revenue: params.get('revenue') || request.revenue,
      goal: params.get('goal') || request.goal,
      when: params.get('when') || request.when,
    },
  });

  // let url= document.location.href;
  // window.history.pushState({}, "", url.split("?")[0]);

  const { handleSubmit, formState } = formMethods;
  const activeSelect = activeOptions.map((option, i) => ({ label: option, value: activeValues[i] }));
  const activeFeedback = [
    'Spannend, ga zo door!',
    'Lekker op weg dus!',
    'Tijd om door te groeien!',
    'Mooi, jij weet hoe het werkt.',
  ];
  const revenueSelect = revenueOptions.map((option, i) => ({ label: option, value: revenueValues[i] }));
  const revenueFeedback = [
    'Het begin is er.',
    'Pas het topje van de ijsberg...wedden?',
    'The sky is NOT the limit!',
    'Omzet waar je u tegen zegt.',
    'Dat zijn nog eens cijfers.',
    'Dat is serieus zakendoen.',
    'Dat is nog eens ondernemen.',
  ];
  const goalSelect = goalOptions.map((option) => ({ value: option }));
  const goalFeedback = [
    'Overbruggen zit in onze naam.',
    'Inslaan die handel.',
    'Investeren = groeien!',
    'Goed gereedschap is het halve werk.',
    'Mooi! Laat zien wie je bent.',
    'Vol gas naar de top!',
    'Tijd voor verandering!',
    'Goede voorbereiding is het halve werk.',
    'Steengoed idee!',
    'Nieuwe ronde, nieuwe kansen!',
    'Je maakt ons nieuwsgierig.',
  ];
  const whenSelect = whenOptions.map((option) => ({ value: option }));
  const whenFeedback = [
    'Mooi. Binnen 5 uur is ons record.',
    'Het kan ook binnen een dag.',
    'We zijn sneller dan je denkt.',
    'Je aanvraag is geheel vrijblijvend!',
  ];
  const onSubmit = (data) => {
    data.amount = data.amount.replace('.','')
    dispatch(setValues(data));
    history.push(personalInfo);
  };
  const partner = localStorage.getItem('partner');

  let isDisabled = (widget && widget === "Qualification") ? false : !formState.isValid;

  // Remove partner id from storage
  // if (!params.get('partner')) localStorage.removeItem('partner');

  return (
    <PageLayout>
      <RequestProgress />
      <Container width={730}>
        <p className="lead darker">{partner ? ('Hoeveel heeft je klant nodig?') : ('Hoeveel heb je nodig?')}</p>
        <FormContext {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} id="GT-FORM-BASIC">
            <SelectAmount
              name="amount"
              required
              autoFocus
            />
              <div className="mt-40">
              <Grid columns={2}>
                <Select
                  name="active"
                  placeholder={partner ? 'Hoelang bestaat het bedrijf?' : 'Hoelang bestaat je bedrijf?'}
                  options={activeSelect}
                  feedback={activeFeedback}
                  required
                />
                <Select
                  name="revenue"
                  placeholder="Hoe hoog is de jaarlijkse omzet?"
                  options={revenueSelect}
                  feedback={revenueFeedback}
                  required
                />
                <Select
                  name="goal"
                  placeholder={partner ? 'Waar is het voor nodig?' : 'Waar heb je het voor nodig?'}
                  options={goalSelect}
                  feedback={goalFeedback}
                  required
                />
                <Select
                  name="when"
                  placeholder={partner ? 'Hoe snel is het nodig?': 'Wanneer heb je het nodig?'}
                  options={whenSelect}
                  feedback={whenFeedback}
                  required
                />
              </Grid>
            </div>
            <div className="mt-40">
              <Grid>
                <div />
                <Button
                  type="submit"
                  width="100%"
                  //disabled={!formState.isValid}
                  disabled={isDisabled}
                >
                  Volgende stap
                </Button>
              </Grid>
            </div>
          </form>
        </FormContext>
      </Container>
    </PageLayout>
  );
};

BasicInfo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  request: PropTypes.shape({
    amount: PropTypes.string,
    active: PropTypes.string,
    revenue: PropTypes.string,
    goal: PropTypes.string,
    when: PropTypes.string,
  }).isRequired,
};

export default connect(
  (state) => ({
    request: state.request,
  }),
)(BasicInfo);
