import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';
import {
  grayDark,
  red,
  field,
  fieldActive,
  fieldError,
  fieldColor,
  borderGray,
  dark,
  borderGreen,
  borderRed,
  errorRed
} from '../../constants/colors';

export default styled.label`
  width: 100%;
  height: ${(props) => props.height};
  display: block;
  padding: 0 ${space}px;
  background-color: ${fieldColor};
  position: relative;
  cursor: pointer;
  transition: background-color 200ms;
  border-bottom: 1px solid ${borderGray};
  font-weight:400
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: ${dark};

  &.label,
  &.error {
    input {
      bottom: 0;
      transform: translateY(0);
    }
  }

  &.focus,
  &.set,
  &.error {
    .label {
      height: 20px;
      line-height: 20px;
      font-weight: 600;
      top: 0;
      transform: scale(0.8);
    }

    input {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.set {
    background-color: ${fieldActive};
    border-bottom: 1px solid ${borderGreen};
  }

  &.error {
    background-color: ${fieldError};
    border-bottom: 1px solid ${borderRed};
    background-color: ${errorRed};

    .label {
      color: ${red};
    }
  }

  &.IEInputStraatnaam {
    float: left;
    width: 66%;
  }

  &.IEInputNummer {
    float: left;
    width: 29.5%;
    margin-left:4.5%;
  }

  .label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center left;
    font-size: 17px;
    transition: top 300ms ease, transform 300ms ease;
    pointer-events: none;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 30px;
    padding: 0 ${space}px;
    position: absolute;
    bottom: 50%;
    left: 0;
    transform: translateY(50%);
    color: ${grayDark};
    background-color: transparent;
    font-size: 17px;
    opacity: 0;
    cursor: pointer;
    transition: opacity 100ms ease, bottom 300ms ease, transform 300ms ease;
    transition-delay: 100ms;
    pointer-events: none;
  }

  .numPrefix{
    line-height: 89px;
  }

  .currency{
    padding-left: 30px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-text-fill-color: ${grayDark};
  }
`;
