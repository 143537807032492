import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Select from '../../../objects/select';
import Input from '../../../objects/input';

/* Redux */
import { setValues, nextPage, prevPage } from '../../../redux/actions/ubo-form';

const Stakeholders = ({ dispatch, defaultValues }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues });
  const { handleSubmit, formState, watch } = formMethods;
  const stakeholder = watch('stakeholder');
  const options = [
    { label: '1 belanghebbende', value: 1 },
    { label: '2 belanghebbenden', value: 2 },
    { label: '3 belanghebbenden', value: 3 },
    { label: '4 belanghebbenden', value: 4 },
  ];
  const partnerOptions = [
    { label: 'Ja' },
    { label: 'Nee' },
  ];

  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(nextPage());
  };

  const renderFields = () => (
    Array(parseInt(stakeholder, 10)).fill().map((name, i) => (
      <div className="mt-40" key={`stakeholder${i}`}>
        <p className="bold">{`Belanghebbende ${i + 1}`}</p>
        <div className="mt-20">
          <Grid columns={1}>
            <Grid columns={2}>
              <Input name={`stakeholders[${i}].firstname`} label="Voornaam" required />
              <Input name={`stakeholders[${i}].lastname`} label="Achternaam" required />
              <Input name={`stakeholders[${i}].birthday`} label="Geb. datum" required />
              <Select
                name={`stakeholders[${i}].partner`}
                placeholder="Gehuwed/Geregistreerd partner"
                options={partnerOptions}
                required
              />
            </Grid>
            <Grid columns={1}>
              <Input IEInputStraatnaam = {true} name={`stakeholders[${i}].streetname`} label="Straatnaam" required  />
              <Input IEInputNummer = {true} name={`stakeholders[${i}].housenumber`} label="Nummer" required  />
            </Grid>
            <Grid columns={2}>
              <Input name={`stakeholders[${i}].postalcode`} label="Postcode" required />
              <Input name={`stakeholders[${i}].city`} label="Plaats" required />
              <Input name={`stakeholders[${i}].email`} label="E-mailadres" type="email" required />
              <Input name={`stakeholders[${i}].mobile`} label="Telefoonnummer" type="tel" defaultValue="06" required />
              <Input name={`stakeholders[${i}].percentage`} label="Percentage aandelen" required />
            </Grid>
            {
              watch()[`stakeholders[${i}].partner`] === partnerOptions[0].label
              && (
                <Grid columns={1}>
                  <p className="bold">Gegevens van uw partner</p>
                  <Grid columns={2}>
                    <Input name={`stakeholders[${i}].firstnamePartner`} label="Voornaam partner" required />
                    <Input name={`stakeholders[${i}].lastnamePartner`} label="Achternaam partner" required />
                    <Input name={`stakeholders[${i}].emailPartner`} label="E-mailadres partner" required />
                    {/* <Input name={`stakeholders[${i}].birthdayPartner`} label="Geb. datum partner" required /> */}
                  </Grid>
                  <p className="sub light">Vul bovenstaand het e-mailadres van de partner in. Dit moet een ander e-mailadres zijn dan het e-mailadres dat reeds wordt gebruikt voor de belanghebbende. Wanneer je aanvraag is goedgekeurd dienen de partner(s) van belanghebbende(n) digitaal mee te tekenen op de borgstelling en sturen wij dit verzoek naar het e-mailadres dat je hier opgeeft.</p>
                </Grid>
              )
            }
          </Grid>
        </div>
      </div>
    ))
  );

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="bold">
          Een uiteindelijk belanghebbende is een natuurlijk persoon die één of meer
          <br />
          van onderstaande kenmerken heeft:
        </p>
        <ul className="mt-20">
          <li>Heeft direct of indirect 25% of meer kapitaalbelang in de organisatie.</li>
          <li>Kan 25% of meer van de stemrechten uitoefenen in de algemene vergadering van organisatie.</li>
          <li>Kan feitelijk zeggenschap uitoefenen in de organisatie.</li>
          <li>Is begunstigde van 25% of meer van het vermogen van de organisatie.</li>
        </ul>
        <p className="bold mt-40">Hoeveel belanghebbende(n) heeft jouw bedrijf?</p>
        <div className="mt-20">
          <Select
            name="stakeholder"
            placeholder="Selecteer het aantal"
            options={options}
            required
          />
        </div>
        {stakeholder && renderFields()}
        <div className="mt-40 inDesktop">
          <Grid>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Stakeholders.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
};

Stakeholders.defaultProps = {
  defaultValues: {},
};

export default connect((state) => ({
  defaultValues: state.uboForm,
}))(Stakeholders);
