export const bridgefund = 'https://www.bridgefund.nl';
export const app = 'https://app.bridgefund.nl';
export const notFound = '/404';
export const waiting = '/wachten';

export const requestLoan = '/lening-aanvragen';
export const basicInfo = `${requestLoan}/financieringsbehoefte`;
export const personalInfo = `${requestLoan}/bedrijfsgegevens`;
export const financialInfo = `${requestLoan}/banktransacties`;
export const requestSorry = `${requestLoan}/sorry`;
export const requestLater = `${requestLoan}/later-afronden`;
export const requestThanks = `${requestLoan}/bedankt`;

/* temp routes */
export const test_inverse_wizard = `${requestLoan}/test-inverse-wizard`;
export const temp_psd2 = `${requestLoan}/banktransacties-psd2`;

export const updateLoan = '/lening-revisie';
export const newFinancialInfoUpdate = `${updateLoan}/banktransacties`;
export const workflowStatus = `${updateLoan}/werkstroomstatus`;

export const completeRequest = '/afronden';
export const completeError = `${completeRequest}/oeps`;
export const completeWaiting = `${completeRequest}/wachten`;
export const completeOverview = `${completeRequest}/overzicht`;
export const completeMtFile = `${completeRequest}/mt940-bestand`;
export const completeBtwResult = `${completeRequest}/btw-aangifte`;
export const completeBankStatement = `${completeRequest}/bankafschrift`;
export const completeStakeholders = `${completeRequest}/belanghebbenden`;
export const completeDeptForm = `${completeRequest}/schuldenformulier`;
export const completePassport = `${completeRequest}/paspoort`;
export const completeFinancialState = `${completeRequest}/jaarrekening`;
export const completeCreditArg = `${completeRequest}/kredietovereenkomst`;
export const completeProposal = `${completeRequest}/voorstel`;
export const completeMtAndPsd2File = `${completeRequest}/banktransacties`;


