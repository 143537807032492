import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { useFormContext } from 'react-hook-form';

/* Style */
import StyledComponent from './style';

/* Objects */
import Slider from '../../objects/slider';

const SelectAmount = ({
  name, startAmount, minAmount,
  maxAmount, required, autoFocus, widget
}) => {
  const { register, setValue, watch } = useFormContext();
  const registerData = { required };
  const amountString = watch([name])[name];
  const step = 1000;
  const [amountInt, setAmountInt] = useState(amountString ? parseInt(amountString.replace('.', ''), 10) : startAmount);

  // After input value change
  useEffect(() => {
    if (!amountString) return;

    const timer = setTimeout(() => {
      let amount = parseInt(amountString.replace('.', ''), 10);
      amount = Number.isNaN(amount) ? startAmount : amount;
      amount = amount < minAmount ? minAmount : amount;
      amount = amount > maxAmount ? maxAmount : amount;
      amount = Math.round(amount / step) * step;
      setValue(name, numeral(amount).format(), true);
      setAmountInt(amount);
    }, 1000);
    return () => clearTimeout(timer);
  }, [name, startAmount, minAmount, maxAmount, amountString, setValue]);

  // After slider value change
  const handleChange = (event) => {
    const { value } = event.target;
    setValue(name, numeral(value).format(), true);
    setAmountInt(parseInt(value, 10));
  };

  return (
    <StyledComponent>
      <div className={widget ? "amount-widget":"amount"}>
        <input
          ref={register(registerData)}
          id={name}
          name={name}
          defaultValue={numeral(amountInt).format()}
          inputMode="numeric"
          autoFocus={autoFocus}
        />
      </div>
      <Slider
        min={minAmount}
        max={maxAmount}
        step={step}
        value={amountInt}
        onChange={handleChange}
      />
    </StyledComponent>
  );
};

SelectAmount.propTypes = {
  name: PropTypes.string.isRequired,
  startAmount: PropTypes.number,
  minAmount: PropTypes.number,
  maxAmount: PropTypes.number,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  widget: PropTypes.bool
};

SelectAmount.defaultProps = {
  startAmount: 50000,
  minAmount: 5000,
  maxAmount: 250000,
  required: false,
  autoFocus: false,
  widget: false
};

export default SelectAmount;
