import styled from 'styled-components';

import { tabMaxWidth } from '../../constants/breakpoints';
import {dark, blueGradientStart} from '../../constants/colors';

export default styled.div`
  @media only screen and (max-width: ${tabMaxWidth}px) {

    // .hukuYf{
    //   margin-bottom:40px;
    // }

    .hdxnSS{
      padding-bottom:20px
    }

    .inDesktopActionWrapper{
      padding-bottom: 0px !important;
    }

  }

  .marginBottomInButtonsDesktop{
    margin-bottom:60px;
  }

   width: ${(props) => props.width}px;
  max-width: 100%;
  margin: 0 auto;
  padding: ${(props) => props.padding}px ${(props) => props.paddingRight}px;

  .inMobile {
    display: none;
  }

  .inDesktopActionWrapper{
    padding-bottom: 60px;
  }

  @media only screen and (max-width: 543px) {
    .inMobile {
      display: block;
    }

    .inDesktop {
      display: none;
    }
  }

  @media only screen and (max-width: 543px) {
    .inMobileSpecial {
      display: block;
    }

    .inDesktop {
      display: none;
    }
  }

  body{
    width:100%
  }

  // new bank transactionsPage //

  .transactionsOptions{
    width: 100%;
    position: relative;
    padding: 12px 12px 12px 12px;
    border-radius: 9px;
    z-index: 1;
    font-size: 14px;
    cursor:pointer;
    border: 2px solid #EBEBEB;
    display:flex;
    color: ${dark};
    // opacity: 80%;
  }

  .transactionsOptions p {
    font-size:12px
  }

  .selectedOption{
    background-color:#00A58819;
    border: 2px solid #00997F;
    color: ${dark};
  }

  .mainHeading{
    color: ${dark};
    font-size:23px;
    margin-top: 30px !important;
  }
  
  .iconDiv{
    margin-right: 12px;
    
  }

  .contentDivSelected{
    opacity: 100% !important;
  }

  .contentDiv{
    padding-top: 10px;
    opacity: 80%;
  }

  .selectedIcon{
    width: 19px;
    max-width: max-content;
    padding-top: 12px;
  }

  .infoIcon{
    width: 20px;
    height: 20px;
    max-width: fit-content;
    
  }

  .optionHeading{
    font-size:16px;
    opacity: 80%;
  }

  .optionHeadingSelected{
    opacity: 100%;
  }

  .stepHeadingsMt940{
    font-size:16px;
  }

  .linkText{
    font-size:14px;
  }

  .linkText a{
    color:${blueGradientStart};
  }

  #PSD2InformativeWindow{
    // width: 338px;
    // height: 302px;
    width: 347px;
    height: 321px;
    background-color: #1F2848;
    color: #fff;
    text-align: left;
    border-radius: 21px;
    padding: 15px 16px 11px 16px;
    position: absolute;
    z-index: 1;
    bottom: 96%;
    left: 67%;
    cursor: initial;
    opacity:100% !important;
  }

  #PSD2Mt940CompleteInfo{
    width: 347px;
    height: 321px;
    background-color: #1F2848;
    color: #fff;
    text-align: left;
    border-radius: 21px;
    padding: 15px 16px 11px 16px;
    position: absolute;
    z-index: 1;
    bottom: 67%;
    left: 67%;
    cursor: initial;
    opacity:100% !important;
  }

  .informativeTop{
    width:100%;
    height:35px;
    display: inline-flex;
  }

  .informativeHeading {
    color: #FDF2E6;
    font-size: 16px !important;
    font-weight: bold;
    float:left;
    width: 93%;
  }

  .closeInformativeWindow{
    float:right;
    width:20px;
    cursor:pointer;
  }

  .informativeContent {
    color: #FDF2E6;
    font-size: 13px !important;
  }

  .helpImg{
    float:right;
    width:20px;
    cursor: pointer;
    margin-right: 10px;
  }

  .informativeBottomTxt{
    font-size: 13px !important;
    margin-top: 7px;
    text-decoration: underline;
  }

  #PSD2Mt940CompleteHelpWindow{
    width: 338px;
    height: 128px;
    background-color: #1F2848;
    color: #fff;
    text-align: left;
    border-radius: 21px;
    padding: 15px 16px 11px 16px;
    position: absolute;
    z-index: 1;
    bottom: 120px;
    left: 40%;
    cursor: initial;
  }

  #PSD2HelpWindow{
    width: 338px;
    height: 128px;
    background-color: #1F2848;
    color: #fff;
    text-align: left;
    border-radius: 21px;
    padding: 15px 16px 11px 16px;
    position: absolute;
    z-index: 1;
    bottom: 120px;
    left: 40%;
    cursor: initial;
  }

  #psd2FailMsg{
    width:100%;
    height:92px;
    background-color:#E3056515;
    margin: 13px 0 40px 0;
  }

  #failMsg{
    width:100%;
    height:92px;
    background-color:#E3056515;
    margin: 13px 0 40px 0;
    border-radius: 6px;
  }

  #psd2FailTop{
    width:100%;
    float:left;
    display:inline-flex;
    margin: 7px 0 8px 0;
  }

  #failTop{
    width:100%;
    float:left;
    display:inline-flex;
    margin: 7px 0 8px 0;
  }

  #psd2FailTop img{
    padding : 10px 10px 0 15px;
  }

  #failTop img{
    padding : 10px 10px 0 15px;
  }

  #psd2FailTop p{
    padding : 10px 10px 0 2px;
    font-size: 16px;
    color: #E30565;
  }

  #failTop p{
    padding : 10px 10px 0 2px;
    font-size: 16px;
    color: #E30565;
  }

  #psd2FailContent{
    font-size: 12px;
    color: #1E274A;
    padding-left:15px;
  }

  #failContent{
    font-size: 12px;
    color: #1E274A;
    padding-left:15px;
  }

  #legendLabel{
    position: absolute;
    width:144px;
    height:31px;
    margin-top: -29px;
    margin-left: 37%;
    padding: 5px 14px;
    border-radius: 15px;
    font-size:12px;
    text-align: center;
  }

  .legendLabelActive{
    color: #ffffff;
    background-color: #00A588;
  }

  .legendLabelInactive{
    color: #7B8094;
    background-color: #ffffff;
    border: 1px solid #EBEBEB;
  }

// workflow Status page

  .workflowStatusWrapper{
    width:100%; 
    text-align:center;
    margin-top: 50px
    
  }

  .headingFont{
    font-size:18px;
    margin-bottom: 10px !important;
  }

  .successTxt{
    font-size:36px;
    margin-bottom: 10px !important;
  }

  .headerIcon{
    margin-bottom: 10px;
  }

  .wfltxtWrapper{
    
  }

  .wflTxtContent{
    font-size:12px;
    font-family: 'inter', sans-serif;
    color:#1E274A;
  }

  .wflTxtContact{
    margin-top: 10x;
  }

  #psd2revisionSuccessDiv{
    text-align:center;
  }

// media queries

@media only screen and (min-width: 320px) {
  #PSD2InformativeWindow{
    width: 100%;
    height: 396px;
    bottom: 98%;
    left: 0;
  }

  #PSD2Mt940CompleteInfo{
    width: 100%;
    height: 459px;
    bottom: 39%;
    left: 0;
  }

  #PSD2HelpWindow{
    width: 98%;
    bottom: -35%;
    left: 2px
  }

  #PSD2Mt940CompleteHelpWindow{
    width: 98%;
    bottom: -35%;
    left: 2px
  }

  #legendLabel{
    margin-left: 20%;
  }

  #failMsg{
    height:128px;
  }
}

@media only screen and (min-width: 375px) {
  #PSD2InformativeWindow{
    width: 347px;
    height: 321px;
    bottom: 96%;
    left: 0;
  }

  #PSD2Mt940CompleteInfo{
    width: 347px;
    height: 321px;
    bottom: 64%;
    left: -33px;
  }

  #PSD2HelpWindow{
    width: 98%;
    bottom: -10%;
    left: 4px;
  }

  #PSD2Mt940CompleteHelpWindow{
    width: 98%;
    bottom: -10%;
    left: 4px;
  }

  #legendLabel{
    margin-left: 23%;
  }

  #failMsg{
    height:128px;
  }

}

@media only screen and (min-width: 425px) {
  #PSD2InformativeWindow{
    bottom: 98%;
    left: 6%;
  }

  #PSD2Mt940CompleteInfo{
    bottom: 55%;
    left: -1%;
  }

  #PSD2HelpWindow{
    width: 98%;
    bottom: -6%;
    left: 5px;
  }

  #PSD2Mt940CompleteHelpWindow{
    width: 98%;
    bottom: -6%;
    left: 5px;
  }

  #legendLabel{
    margin-left: 26%;
  }

  #failMsg{
    height:128px;
  }
}

@media only screen and (min-width: 768px) {
  #PSD2InformativeWindow{
    bottom: 98%;
    left: 55%;
  }

  #PSD2Mt940CompleteInfo{
    bottom: 19%;
    left: 49%;
  }

  #PSD2HelpWindow{
    width: 338px;
    height: 122px;
    bottom: 12%;
    left: 54%;
  }

  #PSD2Mt940CompleteHelpWindow{
    width: 338px;
    height: 122px;
    bottom: 47%;
    left: 54%;
  }

  #legendLabel{
    margin-left: 37%;
  }

   #failMsg{
    height:92px;
  }
}

@media only screen and (min-width: 1024px) {
  #PSD2InformativeWindow{
    bottom: 98%;
    left: 67%;
  }

  #PSD2Mt940CompleteInfo{
    bottom: 19%;
    left: 59%;
  }

  #PSD2HelpWindow{
    width: 338px;
    height: 128px;
    bottom: 14%;
    left: 47%;
  }

   #failMsg{
    height:92px;
  }
}

@media only screen and (min-width: 2560px) {
  #PSD2InformativeWindow{
    bottom: 98%;
    left: 67%; 
  }

  #PSD2Mt940CompleteInfo{
    bottom: 67%;
    left: 64%;
  }

  #PSD2HelpWindow{
    width: 338px;
    height: auto;
    bottom: 41%;
    left: 49%;
  }

   #failMsg{
    height:92px;
  }
}

`;
