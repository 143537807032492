import axios from 'axios';
import ReactGA from 'react-ga';

/* Constants */
import { activeValues } from '../../constants/options';

/* Redux */
import { toggleLoading } from './loading';
import { reportError } from './error';

/* Utils */
import { getCookie } from '../../utils/cookie';
import { getGtmParameters } from "../../utils/googleTagManager";

/* env */
import ENV from '../../config/env';

/* Actions */
export const SET_REQUEST_VALUES = 'SET_REQUEST_VALUES';
export const SET_CONCENT_DETAILS = 'SET_CONCENT_DETAILS';
export const SET_LOADING = 'SET_LOADING';
export const SET_PSD_REVISION_STATUS = 'SET_PSD_REVISION_STATUS';
export const SET_PSD_ACCOUNT_SHORT = 'SET_PSD_ACCOUNT_SHORT';
export const SET_CONCENT_INFORMATION = 'SET_CONCENT_INFORMATION';
export const SET_CONCENT_RENEWAL_STATE = 'SET_CONCENT_RENEWAL_STATE';
export const PSD_COMPLETED = 'PSD_COMPLETED';
export const SET_CONCENT_STATUS = 'SET_CONCENT_STATUS';
export const SET_RISK_ANLYSIS_SEQUENCE_NUMBER = 'SET_RISK_ANLYSIS_SEQUENCE_NUMBER';
export const RESET_STATUS_VALUES = 'RESET_STATUS_VALUES';

export const setValues = (values) => {
  if (!values) return false;

  return (dispatch, store) => {
    const { request } = store();
    const object = {
      ...request,
      ...values,
      potential: 'low',
    };

    // Potential 'lowrev'
    if (
      (object.active === activeValues[2]
      || object.active === activeValues[3])
      && parseInt(object.revenue, 10) === 0
    ) object.potential = 'LOWREV';

    // Potential 'medium'
    if (
      object.active === activeValues[1] &&
      parseInt(object.revenue, 10) >= 150000
    )
      object.potential = "MEDIUM";

    // Potentail 'high
    if (
      (object.active === activeValues[2]
      || object.active === activeValues[3])
      && parseInt(object.revenue, 10) >= 150000
    ) object.potential = 'HIGH';


    // Store in local
    const storageObject = { ...object };
    delete storageObject.submit;
    if(storageObject.riskAnlysisSequence)  delete storageObject.riskAnlysisSequence;
    if(storageObject.concentStatus)  delete storageObject.concentStatus;
    if(storageObject.psdCompleted)  delete storageObject.psdCompleted;
    if(storageObject.allConcentsRenewed)  delete storageObject.allConcentsRenewed;
    if(storageObject.psd2Accounts)  delete storageObject.psd2Accounts; 
    if(storageObject.revisionPsd2Status)  delete storageObject.revisionPsd2Status; 
    if(storageObject.activeWorkflowFound)  delete storageObject.activeWorkflowFound; 
    if(storageObject.psd2CustomerId)  delete storageObject.psd2CustomerId; 
    if(storageObject.dataLoading)  delete storageObject.dataLoading;
    
    sessionStorage.setItem('request', JSON.stringify(storageObject));

    dispatch({
      type: SET_REQUEST_VALUES,
      values: object,
    });
  };
};

export const generateLoanRequestdata = (request) => {

  let googleClientId;

  ReactGA.ga((tracker) => {
    googleClientId = tracker.get('clientId');
  });

  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': '*/*' 
    }
  };

  let parameters = getGtmParameters();
  let selectorCount = Math.floor(Math.random() * 100) + 1;

  return {
    revenue: request.revenue,
    defined_revenue: request.revenue,
    company: request.company,
    company_active: request.active,
    coc_number: request.kvkNumber,
    website: request.website,
    email: request.email,
    phone: request.phone,
    street: request.street,
    street_no: request.houseNumber,
    zipcode: request.zipcode,
    city: request.city,
    firstname: request.firstName,
    lastname: request.lastName,
    spending_goal: request.goal,
    requested_amount: request.amount,
    google_client_id: googleClientId,
    referrer: getCookie('__gtm_referrer') ? decodeURIComponent(getCookie('__gtm_referrer')) : 'https://www.bridgefund.nl/',
    utm_source: parameters['utm_source'],
    utm_medium: parameters['utm_medium'],
    utm_campaign: parameters['utm_campaign'],
    utm_term: parameters['utm_term'],
    utm_content: parameters['utm_content'],
    utm_id: parameters['utm_id'],
    gclid: parameters['gclid'],
    s_id: parameters['s_id'],
    at_gd: parameters['at_gd'],
    loan_needed_within: request.when,
    opportunity_potential: request.potential,
    partner: localStorage.getItem('partner'),
    partner_firstName: request.partnerFirstName,
    partner_lastName: request.partnerLastName,
    partner_email: request.partnerEmail,
    partner_phone: request.partnerPhone,
    flow: 'CONFETTI',
    test: process.env.NODE_ENV && process.env.NODE_ENV === 'development',
    psd2ormt940request: selectorCount < 101 ? "PSD2" : "MT940"

  }

}

export const submit = () => (dispatch, store) => {
   const { request, companies } = store();

  // Set loading state
  dispatch(toggleLoading('request', true));

  if(companies.coc_api_available && companies){

    const companyItem = companies.items.find(item => item.kvkNummer === request.kvkNumber);
    const basisData = companyItem.links.find(link => link.rel === 'basisprofiel');

    if(basisData && basisData.href){

      axios.get(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/coc/basis-profile`, {
        params : {
          url: basisData.href,
        },
        }).then((response) => response.data.data)
          .then((data) => {
            if(data){

              const embeddedData = data._embedded.hoofdvestiging;
              const address = embeddedData.adressen;
              const addressFound = address.find(item => item.type === 'bezoekadres' && item.land === "Nederland");

              if(addressFound){

                request.street_no = addressFound.huisnummer ? addressFound.huisnummer : '';
                request.zipcode = addressFound.postcode ? addressFound.postcode : '';
                request.website = embeddedData.websites ? embeddedData.websites[0] : '';
                request.registrationdate = data.formeleRegistratiedatum ? data.formeleRegistratiedatum : '';

                const reqData = generateLoanRequestdata(request);

                if(reqData){
                  axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/create`, reqData )
                    .then((response) => response.data)
                    .then((responseData) => {
                      let data = responseData.data;
                      dispatch(setValues({
                        id: data.potentials.active.id,
                        businessKey: data.potentials.active.potential_no,
                        potentialId: data.potentials.potentialId,
                        customerId: data.customerId,
                        submit: true,
                        psd2ormt940request: data.potentials.psd2ormt940request
                      }));
                      dispatch(toggleLoading('request', false));
                  });
                }
                
              }else{
                dispatch(toggleLoading('request', false));
                dispatch(reportError('invalidBaseProfile', true))
              }
            }
          }); 
    }
  }else {

    const reqData = generateLoanRequestdata(request);

    if(reqData){
      axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/create`, reqData )
        .then((response) => response.data)
        .then((responseData) => {
          let data = responseData.data;
          dispatch(setValues({
            id: data.potentials.active.id,
            businessKey: data.potentials.active.potential_no,
            potentialId: data.potentials.potentialId,
            customerId: data.customerId,
            submit: true,
            psd2ormt940request: data.potentials.psd2ormt940request
          }));
          dispatch(toggleLoading('request', false));
        });
    }
  }
};

export const sendFiles = (id, files) => (dispatch, getState) => {
  const formData = new FormData();
  
  let businessKey = getState().request.businessKey ? getState().request.businessKey : "";
  let potentialId = getState().request.potentialId ? getState().request.potentialId : "";
  let processDefinitionKey = businessKey !== "" ? "loan-initiation" : "";
  formData.append('id', id);
  formData.append('type', 'BANK');
  formData.append('businessKey', businessKey);
  formData.append('potentialId', potentialId);
  formData.append('processDefinitionKey', processDefinitionKey);
  formData.append('riskAnalysisSequenceNumber', 0);
  
  // Set loading state
  dispatch(toggleLoading('request', true));

  files.forEach((file) => {
    formData.append('files', file, file.name);
  });

  axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/document-upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then((response) => response.data)
  .then((responseData) => {
    if(responseData.data.fileIds && responseData.data.fileIds.length > 0){
      let reqData = { 
        businessKey : businessKey, 
        documents:responseData.data.fileIds
      }; 
      axios.post('https://www.bridgefund.nl/create_initiation_documents.php', reqData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }
    
    dispatch(setValues({
      submit: true,
      businessKey: "",
      potentialId: "",
      psd2ormt940request: ""
    }));
    dispatch(toggleLoading('request', false));
  });
 };

export const createRevisionDocument = (id, files) => (dispatch) => {
  const formData = new FormData();

  formData.append('businessKey', id);

  // Set loading state
  dispatch(toggleLoading('request', true));

  files.forEach((file) => {
    formData.append('files[]', file, file.name);
  });

  axios.post('https://bridgefund.nl/create_revision_documents.php', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }) .then(() => {
      dispatch(sendFilesToServer({files : files, businessKey : id, processDefinitionKey : 'revision-flex-loan'}));
    });
};

export const sendFilesToServer = (docData) => (dispatch) => {
  try{
   const formData = new FormData();
   formData.append('businessKey', docData.businessKey ? docData.businessKey : "");
   formData.append('type', docData.fileType ? docData.fileType : "");
   formData.append('processDefinitionKey', docData.processDefinitionKey ? docData.processDefinitionKey : "");

   docData.files.forEach((file) => {
     formData.append('files', file, file.name);
   });

   axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/upload-document-to-server`, formData, {
   headers: {
     'Content-Type': 'multipart/form-data',
   },
 }) 
 .then((response) => response.data)
 .then((responseData) => {
   if(responseData && responseData.data.addedFiles && responseData.data.addedFiles.length > 0){
     dispatch(setValues({
       submit: true,
     }));
     dispatch(toggleLoading('request', false))
   }
 });

  }catch(err){
    console.log('error while sendFilesToServer : ', err)
  }
}

export const requestPotentialParameters = (id) => (dispatch) => {
  try{
   axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/potential-parameters`, {id:id}, {
     headers: {
       'Content-Type': 'application/json',
     },
   }) 
   .then((response) => response.data)
   .then((responseData) => {
     if(responseData && responseData.data){
       dispatch(setValues({
         businessKey: responseData.data[0].potential_no,
         potentialId: responseData.data[0].id,
         customerId: responseData.data[0].customerId
       }));
     }
   });

  }catch(err){
   console.log('error while requestPotentialParameters : ', err)
  }
};

export const getInverseRedirectURL = (data) => (dispatch) => {
   try{

      dispatch({
        type: SET_CONCENT_RENEWAL_STATE,
        allConcentsRenewed:'no'
      });

     dispatch({
       type: SET_LOADING,
       value:true
     })

      let reqData = {
        loanRequestId : data.loanRequestId,
        customerId : data.customerId,
        returnUrlPath : data.returnUrlPath,
        action : data.action ? data.action : 'create'
      };

      axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/invers-psd2/get-consent-wizard-redirect-url`, reqData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => response.data)
      .then((responseData) => {
        // redirecting to inverse wizard
        if(responseData.data && responseData.success){
          if(ENV.APP_ENV === 'development'){
            window.location.replace('/lening-aanvragen/test-inverse-wizard');
          }else{
            window.location.href = responseData.data.inversConsentWizardRedirectUrl;
          }
        }

      });

    }catch(err){
      console.log('error while getting redirect url for PSD2 : ', err)
    }
};

export const getData = (data) => (dispatch) => {
   try{
      if(data.concentCount && Number(data.concentCount) === 0){
        dispatch({
          type : SET_CONCENT_DETAILS,
          noConcent : true
        })
      }else{
        if(Number(data.concentCount) > 0){
            dispatch({
              type: SET_LOADING,
              value:true
            })
          let date = new Date();
          let updated = date.setMonth(date.getMonth() - 24);
          updated = new Date(updated);

          let reqData = {
            psd2RetrieveType : data.type ? data.type : "first",
            inversCollectionId : data.consentCollectionId,
            inversRequestId : data.requestId,
            fromDate : updated,
            riskAnalysisSequenceNumber : data.riskAnlysisSequence ? data.riskAnlysisSequence : 0
          };

          axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/invers-psd2/get-data`, reqData, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then((response) => response.data)
          .then((responseData) => {
            
              if(responseData.data && responseData.success){
                dispatch({
                  type: SET_LOADING,
                  value:false
                })
                dispatch({
                  type: PSD_COMPLETED,
                  value:true
                })
                dispatch(setValues({
                  submit: true,
                }));

                localStorage.removeItem('psd2Customer');
              }
          })
        }
        
      }
   }catch(err){
    dispatch({
      type: SET_LOADING,
      value:false
    })
    console.log('error while getting customer and loan data for PSD2 : ', err)
   }
};

export const getBanks = () => (dispatch) => {
  try{
    axios.get(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/crm/get-banks`)
    .then((response) => response.data)
    .then((responseData) => {
      if(responseData.data){
        dispatch(setValues({
          bankList: responseData.data
        }));
        }else{
          console.log(responseData.error)
        }
      })
    }catch(err){
      console.log('error while getBanks : ', err)
  }
}

 // only in development purpose
export const requestSystemRedirectDemoUrl = (data) => (dispatch) => {
  try{ 

     let reqData = {
      loanRequestId : data.loanRequestId,
      customerId : data.customerId,
      returnUrlPath : data.returnUrlPath,
      consentCollectionStatus : data.consentCollectionStatus,
      isFromRevision : data.isFromRevision ? data.isFromRevision : false

    };

    dispatch({
      type: SET_LOADING,
      value:true
    })
    
     axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/invers-psd2/get-demo-redirect-url`, reqData, {
       headers: {
         'Content-Type': 'application/json'
       }
     })
     .then((response) => response.data)
     .then((responseData) => {
        window.location.assign(responseData.data.demoRedirectUrl);
        //window.location.assign(`${responseData.data.demoRedirectUrl.replace('https://app.xxxx.nl', document.location.origin)}`)
        
     })

  }catch(err){
    dispatch({
      type: SET_LOADING,
      value:false
    })
   console.log('error while getting redirect url : ', err)
  }
};

export const checkWorkflowStatus = (data) => (dispatch) => {
  try{ 

     // psd2 revision step 1

     if(data.riskAnlysisSequence){
      dispatch({
        type : SET_RISK_ANLYSIS_SEQUENCE_NUMBER,
        sequenceNumber : data.riskAnlysisSequence
      });
     }
     
     axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/invers-psd2/check-contract-mt940-or-psd2`, data, {
       headers: {
         'Content-Type': 'application/json'
       }
     })
     .then((response) => response.data)
     .then((responseData) => {

        if(responseData && responseData.data){
            dispatch({
              type: SET_PSD_REVISION_STATUS,
              valueObj:responseData.data
            });

            localStorage.setItem('psd2Customer', responseData.data.customerId);
        }
       
     });

  }catch(err){
   console.log('error while checking workflow status : ', err)
  }
};

export const getInversConsentCollection = (data) => (dispatch) => {
  try{ 
    
    // psd2 revision step 4

     axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/invers-psd2/get-psd2-account-short`, data, {
       headers: {
         'Content-Type': 'application/json'
       }
     })
     .then((response) => response.data)
     .then((responseData) => {
          if(responseData && responseData.data){
            dispatch({
              type: SET_PSD_ACCOUNT_SHORT,
              psd2Accounts:responseData.data.PSD2Accounts
            })
          }
          
     });

  }catch(err){
   console.log('error while getting psd2 accounts : ', err)
  }
};

export const getInversConsentInformation = (data) => (dispatch) => {
  try{ 

    dispatch({
      type: SET_LOADING,
      value:true
    })

     axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/invers-psd2/get-consent-information`, data, {
       headers: {
         'Content-Type': 'application/json'
       }
     })
     .then((response) => response.data)
     .then((responseData) => {
          if(responseData && responseData.data){
            dispatch({
              type: SET_CONCENT_INFORMATION,
              concentInfomation:responseData.data.accounts
            });

            dispatch(processConcentRenewal({ customerId : localStorage.getItem('psd2Customer')}));
          }
          
     });

  }catch(err){
    dispatch({
      type: SET_LOADING,
      value:false
    })
   console.log('error while getting concent information : ', err)
  }
};

export const processConcentRenewal = (data) => (dispatch) => {
  try{ 

    // psd2 revision step 7

    dispatch({
      type: SET_LOADING,
      value:true
    })

     axios.post(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/invers-psd2/process-consent-renewal`, data, {
       headers: {
         'Content-Type': 'application/json'
       }
     })
     .then((response) => response.data)
     .then((responseData) => {
          if(responseData && responseData.data){
            dispatch({
              type: SET_PSD_ACCOUNT_SHORT,
              psd2Accounts:responseData.data.PSD2Accounts
            });

            dispatch({
              type: SET_CONCENT_RENEWAL_STATE,
              allConcentsRenewed:responseData.data.completed
            });

            dispatch({
              type: SET_LOADING,
              value:false
            })
          }    
      });

  }catch(err){
    dispatch({
      type: SET_LOADING,
      value:false
    })
   console.log('error while processing concent renewal : ', err)
  }
};

export const setConcentStatus = (status) => (dispatch) => {
  dispatch({
    type : SET_CONCENT_STATUS,
    status : status
  })  
};