import React, { useRef, useMemo } from 'react';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import useDimensions from 'react-use-dimensions';

/* Style */
import StyledComponent from './style';

/* Constants */
import { basicInfo, personalInfo, financialInfo, temp_psd2 } from '../../constants/routes';

const RequestProgress = () => {
  const listRef = useRef(null);
  const [componentRef, { width }] = useDimensions();

  const currentPath = useLocation().pathname;
  let step3Path = temp_psd2;
  if(currentPath === '/lening-aanvragen/banktransacties'){
    step3Path = financialInfo
  }

  const items = [
    {
      label: 'Financieringsbehoefte',
      path: basicInfo,
      active: !!useRouteMatch(basicInfo),
    },
    {
      label: 'Bedrijfsgegevens',
      path: personalInfo,
      active: !!useRouteMatch(personalInfo),
    },
    {
      label: 'Banktransacties',
      path: step3Path,
      active: !!useRouteMatch(step3Path),
    }
  ];
  const activeIndex = items.findIndex((i) => i.active);
  const progress = useMemo(() => {
    const listWidth = listRef.current && listRef.current.offsetWidth;
    return activeIndex !== items.length - 1 ? ((width - listWidth) / 2) + ((listWidth / items.length) * (activeIndex + 1)) : width;
  }, [activeIndex, width, items]);

  return (
    <StyledComponent ref={componentRef} progress={progress}>
      <ul ref={listRef}>
        {
          items.map((item, i) => {
            const done = i < activeIndex;
            const { active } = item;
            const itemClasses = classNames({ done, active });

            const renderStep = () => (
              <>
                <div className="icon">
                  {done && <i className="icmn-checkbox" />}
                  {active && '...'}
                  {!done && !active && i + 1}
                </div>
                {item.label}
              </>
            );

            if (done) {
              return (
                <li
                  key={item.label}
                  className={itemClasses}
                >
                  <Link to={item.path}>
                    {renderStep()}
                  </Link>
                </li>
              );
            }

            return (
              <li
                key={item.label}
                className={itemClasses}
              >
                {renderStep()}
              </li>
            );
          })
        }
      </ul>
    </StyledComponent>
  );
};

export default RequestProgress;
