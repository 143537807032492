import axios from 'axios';

/* Utils */
import { uniqueBy } from '../../utils/array';

/* Redux */
import { toggleLoading } from './loading';
import { reportError } from './error';

/* env */
import ENV from '../../config/env';

/* Axios */
const { CancelToken } = axios;
let cancel = () => {};

/* Actions */
export const TOGGLE_COMPANIES = 'TOGGLE_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
export const SET_KVK_API_AVAILABILITY = 'SET_KVK_API_AVAILABILITY';

export const toggleCompanies = (bool) => (dispatch, store) => {
  const { companies } = store();
  const newBool = typeof bool !== 'undefined' ? bool : !companies.show;

  dispatch({
    type: TOGGLE_COMPANIES,
    show: newBool,
  });
};

export const getCompaniesByQuery = (query) => {
  if (!query) return false;

  return (dispatch) => {
    // Set loading state
    dispatch(toggleLoading('companies', true));

    let time = setTimeout(() => {
      dispatch(toggleLoading('companies', false));
    },15000);

    axios.get(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/coc/coc-search`, {  
       params : {
        query: query,
      },
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => response.data.data)
      .then((data) => {
        if (!data) {
          dispatch(toggleLoading('companies', false));
        }else{ 
          // filtering data
          let kvkResults = data.resultaten.filter((item) => {
            return (item.type && item.type !== "nevenvestiging");
          });
          dispatch({
            type: FETCH_COMPANIES_SUCCESS,
            totalItems: data.totalItems,
            items: uniqueBy(kvkResults, 'kvkNummer'),
          });
          dispatch(toggleLoading('companies', false));
          clearTimeout(time);
          dispatch(reportError('invalidBaseProfile', false))
        }
      });
  };
};

export const clearCompanies = () => (dispatch) => {
  // Cancel search query request
  cancel();
  dispatch({
    type: CLEAR_COMPANIES,
  });
};

export const checkApiAvilability = (companyName) => {
  return (dispatch) => {
    axios.get(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/coc/coc-search`, {
      params : {
        query: companyName,
      },
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => response.data.data)
      .then((data) => {
        if(data && data.resultaten){
          data.resultaten.forEach(element => {
            if(element.kvkNummer === '70304580' && element.plaats){
              const urlData = element.links.find(link => link.rel === "basisprofiel");
              if(urlData){
                dispatch(getCompanyBasisProfile(urlData, '70304580'))
              }
            }
          });
        }
      });
  };
}

export const getCompanyBasisProfile = (link, kvk) => {
  return (dispatch) => {
    axios.get(`${ENV.LOAN_APPLICATION_PROCESS_BACKEND}/coc/basis-profile`, {
      params : {
        url: link.href,
      },
    }).then((response) => response.data.data)
      .then((data) => {
        if(data){
          if(kvk && data.kvkNummer === kvk){
            dispatch({
              type : SET_KVK_API_AVAILABILITY,
              avilable : true
            });
          }else{
            return data;
          }
        }
      });
  };
}