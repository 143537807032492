import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import numeral from 'numeral';

/* Constants */
import { completeOverview } from '../constants/routes';

/* Objects */
import Divider from '../objects/divider';

/* Components */
import SelectAmount from '../components/select-amount';
import SelectMonths from '../components/select-months';
import TotalAmount from '../components/total-amount';

/* Redux */
import { setValues } from '../redux/actions/complete';

const Proposal = ({
  dispatch, history, isSubmitted,
  proposal,
}) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      amount: numeral(parseInt(proposal.quotation_loan_amount, 10)).format(),
      months: '6',
    },
  });
  const { watch } = formMethods;
  const watchAll = watch();
  const amount = parseInt(watchAll.amount.replace('.', ''), 10);
  const maxAmount = parseInt(proposal.maximum_loan_amount, 10);
  const months = parseInt(watchAll.months, 10);
  const maxMonths = parseInt(proposal.maximum_loan_time, 10);
  const surcharge = parseFloat(proposal.interest_increment);

  if (isSubmitted) {
    // GA tracking pageviews
    ReactGA.pageview(`${window.location.pathname}-complete${window.location.search}`);

    // Redirect after submit
    setTimeout(() => {
      dispatch(setValues({ submit: false }));
      history.push(completeOverview);
    }, 2000);
  }

  return (
    <>
      <h2>Stel je lening samen</h2>
      <Divider />
      <p className="lead light">Gefeliciteerd! Je aanvraag is goedgekeurd. Stel je eigen lening samen door het gewenste leenbedrag en de looptijd te kiezen. Je ziet direct wat je gaat betalen.</p>
      <FormContext {...formMethods}>
        <form>
          <div className="mt-40">
            <p className="lead light">Hoeveel zou je willen lenen?</p>
            <SelectAmount
              name="amount"
              maxAmount={maxAmount}
              required
            />
            <p className="light mt-20">Looptijd</p>
            <SelectMonths
              name="months"
              maxMonths={maxMonths}
              required
            />
            <div className="mt-40">
              <TotalAmount amount={amount || 0} months={months || 0} surcharge={surcharge} />
            </div>
          </div>
        </form>
      </FormContext>
      {/* <Footer /> */}
    </>
  );
};

Proposal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  proposal: PropTypes.shape({
    quotation_loan_amount: PropTypes.string,
    maximum_loan_amount: PropTypes.string,
    maximum_loan_time: PropTypes.string,
    interest_increment: PropTypes.string,
  }).isRequired,
};

Proposal.defaultProps = {
  loading: false,
  isSubmitted: false,
};

export default connect((state) => ({
  loading: state.loading.complete,
  isSubmitted: state.complete.submit,
  proposal: state.opportunity.proposal,
}))(Proposal);
