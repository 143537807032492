import styled from 'styled-components';

import { smMaxWidth, tabMaxWidth } from '../../constants/breakpoints';

export default styled.div`
  @media only screen and (max-width: ${smMaxWidth}px) {

    width: 100%;
    padding: 60px 20px;

  }

  @media only screen and (max-width: ${tabMaxWidth}px) {
    margin: 0 auto;
  }

  width: ${(props) => props.width};
  max-width: 100%;
  margin: 0 22%;
  padding: 60px 0px;

  @media only screen and (min-width:992px) and (max-width: 1270px) {

    width: 98%;
     margin-left: 5.5%;
   
  }

  @media only screen and (min-width:1271px) and (max-width: 1300px) {

    width: 99%;
    margin-left: 6%;
  }

  @media only screen and (min-width:1301px) and (max-width: 1488px) {

    width: 80%;
    margin-left: 17%;
  }

   @media only screen and (min-width:1489px) and (max-width: 1680px) {

    margin-left: 21%;
   }

   @media only screen and (min-width:1489px) and (max-width: 2300px) {

    margin-left: 22%;
   }

   @media only screen and (min-width:1489px) and (max-width: 1680px) {

    margin-left: 21%;
   }

  @media only screen and (min-width:${tabMaxWidth}px) and (max-width: 992px) {

    width: 95%;
    margin-left: auto;
    margin-right: auto;

  }

`;
