import React from 'react';

/* Objects */
import Container from '../objects/container';

/* Components */
import Sorry from '../components/sorry';

export default () => (
  <Container width={770}>
    <Sorry />
  </Container>
);
