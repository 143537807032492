import React from 'react';

/* Style */
import StyledComponent from './style';

/* Objects */
import Grid from '../../objects/grid';

export default () => {
  const items = [
    {
      icon: 'lenen.svg',
      title: 'Lenen van € 5.000 – € 250.000',
      text: 'Voor kleine problemen, grootse dromen en alles daartussenin.',
    },
    {
      icon: 'beslis.svg',
      title: 'Beslissing binnen 24 uur',
      text: 'Zodra je aanvraag rond is staat het geld dezelfde dag nog op je rekening.',
    },
    {
      icon: 'loop.svg',
      title: 'Looptijd tot 24 maanden',
      text: 'We denken met je mee en samen kiezen we de meest geschikte looptijd van de lening.',
    },
    {
      icon: 'afgelost.svg',
      title: 'Afgelost voor je het weet',
      text: 'Elke werkdag los je een klein gedeelte af. Op die manier merk je er zo min mogelijk van.',
    },
    {
      icon: 'korting.svg',
      title: 'Korting bij sneller aflossen',
      text: 'Gaan de zaken goed? Bij ons krijg je geen boete, maar juist korting als je sneller aflost!',
    },
    {
      icon: 'zonder.svg',
      title: 'Zonder businessplan of jaarcijfers',
      text: 'Onder 1M omzet kijken we alleen naar je zakelijke banktransacties van de afgelopen 12 maanden.',
    },
    {
      icon: 'helder.svg',
      title: 'Helder over de kosten',
      text: 'Vaste rente van 1% p/m + een opslag van 0.3% - 2.2%, gebaseerd op de gezondheid van je bedrijf.',
    },
    {
      icon: 'persoon.svg',
      title: 'Persoonlijk & betrokken',
      text: 'Je kunt elke werkdag met ons mailen, bellen, chatten, appen, sms-en of een postduif sturen.',
    },
    {
      icon: 'geen.svg',
      title: 'Geen kleine lettertjes',
      text: 'Bij ons geen afsluit- of opname kosten. Wel een overeenkomst van maar 3 pagina’s.',
    },
    {
      icon: 'snel.svg',
      title: 'Snel, simpel én verantwoord lenen',
      text: 'Financiering voor ondernemers zo snel, simpel en verantwoord mogelijk maken. Dat is onze missie.',
    },
    {
      icon: 'aanvaragen.svg',
      title: 'Aanvragen in 5 minuten',
      text: 'De aanvraag is zo gepiept. In 3 simpele stappen weet jij wat wat je kunt lenen.',
    },
    {
      icon: 'vri.svg',
      title: 'Vrijblijvend aanvragen',
      text: 'Je zit nergens aan vast. We helpen je graag met een geheel vrijblijvend aanbod.',
    },
  ];

  return (
    <StyledComponent>
      <Grid columns={3} columnGap={40} rowGap={60}>
        {
          items.map((item) => (
            <div className="item" key={item.icon}>
              {/* <i className={item.icon} /> */}
              <img src={`${process.env.PUBLIC_URL}/images/usp/${item.icon}`} alt="uspIcons" className="uspIcons" />
              <p className="lead">
                <strong className="uspItemTitle">{item.title}</strong>
              </p>
              <p className="light" dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          ))
        }
      </Grid>
    </StyledComponent>
  );
};
