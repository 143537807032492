import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Objects */
import Divider from '../../objects/divider';
import {Telephone, Telephone_Link} from '../../constants/commons';

export const Sorry = ({ amount }) => (
  <StyledComponent>
    <div className="icon">
      <i className="icmn-dove" />
    </div>
    <h2 id="sorryHeading">Sorry, sorry, {amount ? `${amount} x` : 'duizendmaal'} sorry.</h2>
    <Divider align="center" width="233px" />
    <p className="lead sorryDesc mainDesc">Balen zeg. We kunnen je (nog) niet helpen. Je hebt aangegeven dat je omzet lager is dan €50.000 per jaar of dat je bedrijf korter dan 12 maanden actief is.</p>
    <div className="contact mt-40">
      <p className="lead">
        <strong className="subheading">Dit is geen vaarwel.</strong>
      </p>
      <p className="lead sorryDesc">
        Ondernemers zijn doorzetters. Dus we hopen je binnenkort wel te kunnen helpen en wensen je veel succes met ondernemen.
      </p>
      <div className="mt-40 contacts">
        <strong className="minorHeading">Heb je nog vragen?</strong>
        <br />
        Bel <a className="phone" href={Telephone_Link}>{Telephone}</a>
      </div>
    </div>
  </StyledComponent>
);

Sorry.propTypes = {
  amount: PropTypes.string,
};

Sorry.defaultProps = {
  amount: null,
};

export default connect((state) => ({
  amount: state.request.amount,
}))(Sorry);
