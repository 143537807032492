import styled from 'styled-components';

/* Constants */
import { stepsSideBarBackground, primary3, primary2 , bluePurlplish} from '../../constants/colors';
import { space } from '../../constants/spaces';
import { mdMinWidth, smMinWidth, desktopMediumMaxWidth, tabMaxWidth } from '../../constants/breakpoints';

/* Styles */
import { icomoon } from '../../styles/icomoon';

export default styled.div`
  width: 100%;
  height: 100%;
  background-color: ${stepsSideBarBackground};
  padding: 60px 15% 0;

  @media only screen and (min-width: ${mdMinWidth}px) {
    padding: 60px 12% 0;
    width: 305px;
  }

  @media only screen and (min-width: 489px) and (max-width: ${smMinWidth}px)  {
     padding: 60px 31.5% 20px;
  }

  @media only screen and (min-width: 769px) and (max-width: 990px)  {
    padding: 60px 35.5% 20px;
  }

  @media only screen and (min-width: 487px) and (max-width: 599px)  {
     padding: 60px 27.5% 0;
  }

  @media only screen and (min-width: 431px) and (max-width: 486px)  {
    padding: 60px 25.5% 0;
  }

 @media only screen and (min-width: 426px) and (max-width: 430px)  {
  padding: 60px 24% 0;
  }

  @media only screen and (min-width: 370px) and (max-width: 425px)  {
     padding: 60px 20% 0;
  }

  @media only screen and (min-width: 250px) and (max-width: 312px)  {
    padding: 60px 10% 0;
  }

  @media only screen and (max-width: ${desktopMediumMaxWidth}px) {
    .contact {
       p {
        padding-bottom: ${space}px;
      }
    }
  }

  @media only screen and (max-width: ${tabMaxWidth}px) {
    padding-bottom:20px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    &.ssl li:before {
      content: "\\e906";
    }
  }

  .ssl li{
    font-weight: 400;
  }

  li {
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;

    &:before {
      ${icomoon};
      content: "\\e901";
      font-size: 23px;
      color: ${bluePurlplish};
      margin-right: ${space}px;
    }

    &:last-child {
      display: none;
      color: ${primary3};
      cursor: pointer;
      font-weight:600;

      @media only screen and (min-width: ${mdMinWidth}px) {
        display: flex;
      }

      &:before {
        content: "\\e91a" !important;
        color: ${primary3};
      }
    }
  }

  .contact {
    img {
      width: 200px;
     }

    p {
      font-size: 16px;
      margin-top: ${space}px;
      padding-bottom: ${space}px;
      font-weight: 400;
      line-height: 31px;
    }

    margin-bottom: 25px;
  }

  .successMangerFrisco {
    // transform: translate(67%,0) !important;
    width: 97px !important;
  }

  .csmImages{
    width: 140px !important;
  }

  @media only screen and (max-width: 488px) {
    .contact {
      p{
        font-size: 13.4px;
      }
    }
  }

  .uspLinks{
    font-weight:400;
    font-size: 14px;
    line-height:30px;
  }

`;
