import styled from 'styled-components';

/* Constants */
import { xsMinWidth } from '../../constants/breakpoints';
import { stepsSideBarBackground } from '../../constants/colors';

export default styled.div`
  height: 100%;
  width: 320px;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  color: ${stepsSideBarBackground};

  .tp{
    color: ${stepsSideBarBackground};
  }

  @media only screen and (min-width: ${xsMinWidth}px) {
    display: flex;
  }
`;
