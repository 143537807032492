import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowDimensions } from '../../utils/browserWidth';

/* Objects */
import Input from '../../objects/input';
import Select from '../../objects/select';
import Divider from '../../objects/divider';

/* Style */
import StyledComponent from './style';



const DuplicateForm = ({
  fields, prefix, defaultRows,
  addMoreLabel,
}) => {
  const [totalRows, setTotalRow] = useState(defaultRows);
  const { height, width } = useWindowDimensions(); // eslint-disable-line
  const renderFields = () => (
    Array(totalRows).fill().map((name, i) => (
      <div className="row" key={`row-${i}`}>
        {width < 576 && i>0 && <Divider width={'100%'} />}
        {fields.map((field) => (
          <label className={fields.length === 3 ? "form-group col-xs-12 col-sm-4" :  "form-group col-xs-12 col-sm-3"} key={`${field.name}-${i}`} htmlFor={`${field.name}-${i}`}>
            {width > 575 && i === 0 && <div className="label">{field.label}</div>}
            {
              field.type === 'select'
                ? (
                  <Select
                    id={`${field.name}-${i}`}
                    name={`${prefix}[${i}].${field.name}`}
                    placeholder=" "
                    options={field.options}
                    required={field.required}
                    showStatus={false}
                    placeholder={width < 576 && field.label}
                    // height={width < 576 ? '50px' : '60px'}
                  />
                ) : (
                  <Input
                    id={`${field.name}-${i}`}
                    name={`${prefix}[${i}].${field.name}`}
                    required={field.required}
                    label={width < 576 && field.label}
                    // height={width < 768 ? '50px' : '60px'}
                  />
                )
            }
          </label>
        ))}
        {
          i === (totalRows - 1) && totalRows > 1
            ? <div className="icon-div"><i className="icmn-plus-round" onClick={removeRow} /></div>
            : <div className="empty-space" />
        }
      </div>
    ))
  );

  const addRow = () => {
    setTotalRow(totalRows + 1);
  };

  const removeRow = () => {
    const newTotal = totalRows - 1;
    if (newTotal >= 1) setTotalRow(newTotal);
  };

  return (
    <StyledComponent>
      {renderFields()}
      <button
        className="add-row"
        type="button"
        onClick={addRow}
      >
        <div className="plus">+</div>
        {addMoreLabel}
      </button>
    </StyledComponent>
  );
};

DuplicateForm.propTypes = {
  fields: PropTypes.array.isRequired,
  prefix: PropTypes.string,
  defaultRows: PropTypes.number,
  addMoreLabel: PropTypes.string,
};

DuplicateForm.defaultProps = {
  prefix: '',
  defaultRows: 1,
  addMoreLabel: 'Nog een regel toevoegen',
};

export default DuplicateForm;
