/* Constants */
import { gray, grayLight, primary2, grayDarker, dark, dark2, primary } from '../constants/colors';
import {mobileLargeMaxWidth} from '../constants/breakpoints'

export default `
  p {
    font-size: 17px;
    line-height: 1.76;

    &.lead {
      font-size: 23px;
      line-height: 1.55;
      font-weight: 400;
    }

    &.leadSmall {
      font-size: 17px;
      line-height: 1.55;
      font-weight: 400;
    }

    &.smallFontDark {
      font-size: 19px !important;
      color: #2D2F39 !important;
    }

    &.sub {
      font-size: 14px;
      line-height: 1.57;

      &.normal {
        color: ${gray};
      }

      &.light {
        color: ${grayLight};
      }
    }

    &.light {
      color: ${gray};
    }

    &.light2 {
      color: ${dark2};
      opacity: 70%;
      font-size:16px;
      font-weight:400;
    }

    &.dark {
      color: ${grayDarker};
    }

    &.darker {
      color: ${dark};
    }

    &.center {
      text-align: center;
    }

    // &.DoorzoekHeading{
    //   font-size: 22px;
    // }

    &.maxLoanAmountIndicationTxt{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${dark};
    }

    &.smallFontDark {
      font-size: 19px !important;
      color: #2D2F39 !important;
    }
  }

  .bolder{
    font-weight:600;
    font-size:19px;
    line-height: 25px;
    color: ${dark2};
  }

  .link a{
    font-weight:400;
    color: ${grayLight} ;
    font-size: 16px;
  }

  .link:hover{
    color: ${primary} !important;
  }

  b,
  strong,
  .bold {
    font-weight: 600;
  }

  .strongHeading{
    font-weight: 700;
    font-size:16px;
    line-height:26px;
    color: ${dark2};
  }

  a {
    text-decoration: none;
    color: ${primary2};
    cursor: pointer;

    &[href^="tel"] {
      color: inherit;
    }
  }

  ul {
    line-height: 1.76;
    padding: 0;
  }

  .myDossierHeading{
    font-size: 32px;
    font-weight: 400;
    line-height:32px;
    color: ${dark};
  }

  @media only screen and (max-width: ${mobileLargeMaxWidth}px) {
    .myDossierHeading{
      font-size: 24px;
    }

    .maxLoanAmountIndicationTxt{
      font-size: 15px !important;
    }

`;
