import styled from 'styled-components';

/* Constants */
import { spaceLarge } from '../../constants/spaces';
import { purple, dark } from '../../constants/colors';

export default styled.div`
  text-align: center;

  .icon {
    width: 205px;
    height: 205px;
    margin: 0 auto ${spaceLarge}px;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    // background-color: #ffffff;
    // border-radius: 50%;
    background-image:url(${process.env.PUBLIC_URL}/images/Rect.svg);
    background-repeat:no-repeat;


    i {
      color: ${purple};
      font-size: 93px;
    }
  }

  .contact {
    line-height: 1.76;
    img {
      display: block;
      margin: 40px auto;
    }
  }

  .thnkyouHeading{
    font-size:38px;
    font-weight: 600;
    color: ${dark};

  }

  .thankuDesc{
    font-weight: 400;
    font-size:21px;
    color: ${dark};
    line-height: 30px;
    margin-top: 50px;
  }

  .subHeading{
    font-weight: 700;
    font-size:21px;
    color: ${dark};
  }

  .contactDetails{
    font-weight:700;
    font-size:18px;
    color: ${dark};
    line-height:31px;
  }

  .contactTxt{
    color: ${dark};
    font-weight:500;
    font-size:18px;
  }

  .phone{
    color: ${dark};
    font-weight:600;
  }

`;
