import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Constants */
import { completeOverview } from '../constants/routes';

/* Objects */
import Divider from '../objects/divider';

/* Components */
import UboFormWrapper from '../components/ubo-form';

/* Redux */
import { resetValues } from '../redux/actions/ubo-form';

const Stakeholders = ({ dispatch, history, isSubmitted }) => {
  if (isSubmitted) {
    // GA tracking pageviews
    ReactGA.pageview(`${window.location.pathname}-complete${window.location.search}`);

    // Redirect after submit
    setTimeout(() => {
      dispatch(resetValues({ submit: false }));
      history.push(completeOverview);
    }, 2000);
  }

  return (
    <>
      <h2>Verklaring Uiteindelijk Belanghebbende(n)</h2>
      <Divider />
      <p className="lead light smallFontDark">Het is belangrijk dat we weten met wie we zaken doen, om onze klantbediening te optimaliseren en vanuit wet- en regelgeving.</p>
      <div className="mt-40">
        <UboFormWrapper />
      </div>
      {/* <Footer /> */}
    </>
  );
};

Stakeholders.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isSubmitted: PropTypes.bool,
};

Stakeholders.defaultProps = {
  isSubmitted: false,
};

export default connect((state) => ({
  isSubmitted: state.uboForm.submit,
}))(Stakeholders);
