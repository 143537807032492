import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Divider = ({ width, align }) => (
  <StyledComponent
    className={align}
    width={width}
  />
);

Divider.propTypes = {
  width: PropTypes.string,
  align: PropTypes.string,
};

Divider.defaultProps = {
  width: '105px',
  align: '',
};

export default Divider;
