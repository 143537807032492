import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

/* Constants */
import { blueGradientStart, blueGradientEnd } from '../../constants/colors';

const Slider = ({
  min, max, step,
  value, onChange,
}) => {
  const currentPercentage = ((value - min) / (max - min)) * 100;
  const style = {
    background: `linear-gradient(to right, ${blueGradientStart} 0%, ${blueGradientEnd} ${currentPercentage}%, #FDF2E6 ${currentPercentage}%, #FDF2E6 100%)`,
  };

  return (
    <StyledComponent>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
      />
      <div className="background" style={style} />
    </StyledComponent>
  );
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

Slider.defaultProps = {
  min: 1,
  max: 10,
  step: 1,
  value: 1,
};

export default Slider;
