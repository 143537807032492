import styled from 'styled-components';

export default styled.div`
  position: relative;

  .start {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;
