import axios from 'axios';
import { toggleLoading } from './loading';
import { toggleError } from './error';

/* Actions */
export const FETCH_OPPORTUNITY_SUCCESS = 'FETCH_OPPORTUNITY_SUCCESS';
export const FETCH_OPPORTUNITY_FAILED = 'FETCH_OPPORTUNITY_FAILED';

export const fetchOpportunity = (resetCache) => (dispatch, store) => {
  const { complete, opportunity } = store();

  let completeLocalstorage = JSON.parse(localStorage.getItem('complete'));

  let urlArray = window.location.href.split('/');

  // Check cache
  if (!resetCache && opportunity.documents) return;

  // Set loading state
  dispatch(toggleLoading('opportunity', true));

  return axios.post('https://www.bridgefund.nl/document_information.php', {
    id: complete.id ? complete.id : (completeLocalstorage && completeLocalstorage.id) ? completeLocalstorage.id : urlArray[urlArray.length-1],
  }).then((response) => response.data)
    .then((data) => {
      if (data.succes === true) {
        dispatch({
          type: FETCH_OPPORTUNITY_SUCCESS,
          data: {
            firstName: data.customer.firstname,
            desiredLoan: parseInt(data.loan_request.desired_loan_amount, 10),
            awardedLoan: parseInt(data.loan_request.awarded_loan_amount, 10),
            documents: data.documents,
            proposal: data.proposal,
            manager: data.customer_succes_manager.toLowerCase().replace(/\s/g, ''),
          },
        });
      } else {
        dispatch(toggleError('opportunity', true));
      }
      dispatch(toggleLoading('opportunity', false));
    });
};
