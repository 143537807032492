import styled from 'styled-components';

export default styled.div`
  padding-top: 40px;
  position: relative;

  .months {
    padding: 4px;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    font-weight: 600;
    border-radius: 4px;
    border: solid 1px #dddddd;
    overflow: hidden;
    white-space: nowrap;
  }
`;
