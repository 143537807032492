import styled from 'styled-components';

/* Constants */
import { space, spaceSmall } from '../../constants/spaces';
import { gradientStart, gradientEnd } from '../../constants/colors';

export default styled.div`
  i {
    width: 70px;
    height: 70px;
    font-size: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:before {
      background: -webkit-gradient(linear, right center, left center, from(${gradientStart}), to(${gradientEnd}));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .lead {
    margin-top: ${space}px;
  }

  .light {
    margin-top: ${spaceSmall}px;
    font-size:17px;
    font-weight:400;
    color: #1E2749;
    opacity: 70%;

  }

  .uspItemTitle{
    font-weight: 700;
    font-size:20px;
    text-align:center;
  }

`;
