import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

/* Style */
import StyledComponent from './style';

/* Objects */
import Divider from '../../objects/divider';

const TotalAmount = ({ amount, months, surcharge }) => {
  const interest = (1 * (months > 12 ? 12 : months)) + (surcharge * (months < 6 ? 6 : months));
  const interestAmount = Math.round((amount / 100) * interest);

  return (
    <StyledComponent>
      <div className="row">
        <div>Hoofdsom</div>
        <div>{`€ ${numeral(amount).format()}`}</div>
      </div>
      <div className="row">
        <div>Rente</div>
        <div>{`€ ${numeral(interestAmount).format()}`}</div>
      </div>
      <Divider width="100%" />
      <div className="row">
        <strong>Total terug te betalen</strong>
        <div>{`€ ${numeral(amount + interestAmount).format()}`}</div>
      </div>
      <Divider width="100%" />
      <div className="row">
        <strong>Rente en aflossing per werkdag</strong>
        <div className="highlight">{`€ ${numeral((amount + interestAmount) / (months * 21.6)).format()}`}</div>
      </div>
      <div className="row">
        <div>Rente en aflossing per week (indicatief)</div>
        <div>{`€ ${numeral((((amount + interestAmount) / months) * 12) / 52).format()}`}</div>
      </div>
      <div className="row">
        <div>Rente en aflossing per maand (indicatief)</div>
        <div>{`€ ${numeral((amount + interestAmount) / months).format()}`}</div>
      </div>
    </StyledComponent>
  );
};

TotalAmount.propTypes = {
  amount: PropTypes.number.isRequired,
  months: PropTypes.number.isRequired,
  surcharge: PropTypes.number,
};

TotalAmount.defaultProps = {
  surcharge: 1,
};

export default TotalAmount;
