import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const KnownFrom = ({ fontSize, hide }) => (
  <StyledComponent fontSize={fontSize}>
    <div className="title">Bekend van o.a.</div>
    <div className="logos">
      <div><img src={`${process.env.PUBLIC_URL}/images/logos/rtlz.png`} alt="rtlz" /></div>
      <div><img src={`${process.env.PUBLIC_URL}/images/logos/fd.png`} alt="fd" className="vertical" /></div>
      {
        hide !== 'ondernemer'
        && <div><img src={`${process.env.PUBLIC_URL}/images/logos/de-ondernemer.png`} alt="de-ondernemer" /></div>
      }
      <div><img src={`${process.env.PUBLIC_URL}/images/logos/nrc.png`} alt="nrc" /></div>
    </div>
  </StyledComponent>
);

KnownFrom.propTypes = {
  fontSize: PropTypes.number,
  hide: PropTypes.string,
}

KnownFrom.defaultProps = {
  fontSize: 10,
  hide: '',
}

export default KnownFrom;
