export const activeOptions = [
  'Minder dan 6 maanden',
  '6 tot 12 maanden',
  '1 tot 3 jaar',
  'Meer dan 3 jaar',
];

export const activeValues = [
  'tot 6 maanden',
  '6 tot 12 maanden',
  '1 tot 3 jaar',
  'Meer dan 3 jaar',
];

export const revenueOptions = [
  'Minder dan € 50.000',
  '€ 50.000 - € 150.000',
  '€ 150.000 - € 250.000',
  '€ 250.000 - € 500.000',
  '€ 500.000 - € 750.000',
  '€ 750.000 - € 1.000.000',
  '> € 1.000.000',
];

export const revenueValues = [
  '0',
  '150000',
  '250000',
  '500000',
  '750000',
  '1000000',
  '2000000',
];

export const goalOptions = [
  'Debiteuren',
  'Voorraad',
  'Inventaris',
  'Machines',
  'Marketing',
  'Voertuigen',
  'Verbouwing',
  'Voorfinanciering',
  'Vastgoed',
  'Herfinanciering',
  'Overig',
];

export const whenOptions = [
  'Vandaag',
  'Binnen 1 week',
  'Geen haast',
  'Ik bekijk alleen de mogelijkheden',
];
