import styled from 'styled-components';

/* Constants */
import { blue, red, fieldError } from '../../constants/colors';

/* Styles */
import { icomoon } from '../../styles/icomoon';

export default styled.label`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  input {
    display: none;

    &:checked + .checkbox:before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &.error {
    .checkbox {
      background-color: ${fieldError};
      border-color: ${red};
    }
  }

  .checkbox {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 20px;
    position: relative;
    border-radius: 3px;
    border: solid 1px #dddddd;

    &:before {
      ${icomoon}
      content: "\\e901";
      font-size: 13px;
      font-weight: 600;
      color: #00997F;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.5) ;
      transition: all 0.2s ease-out;
    }
  }

  .label {
    font-size: ${(props) => `${props.fontSize}px`};
    line-height: 1.57;
  }
`;
