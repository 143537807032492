import React from 'react';

/* Style */
import StyledComponent from './style';

/* Objects */
import Divider from '../../objects/divider';
// import {Telephone, Telephone_Link} from '../../constants/commons';

export const NoOpportunity = () => (
  <StyledComponent>
    <div className="icon">
      <img src={`${process.env.PUBLIC_URL}/images/opes.jpg`} alt="opes page" />
    </div>
    <h2>Oeps! Er gaat iets mis met het laden van de pagina.</h2>
    <Divider align="center" />
    <p className="lead light">
      Probeer de pagina opnieuw te laden. Krijg je deze melding opnieuw?
      <br />
      Neem dan contact met ons op via: 085-4016600 of per e-mail via contact@bridgefund.nl
    </p>
  </StyledComponent>
);

export default NoOpportunity;
