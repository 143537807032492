import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

/* Style */
import StyledComponent from './style';

const Input = ({
  name, label, defaultValue,
  type, required, IEInputStraatnaam,
  IEInputNummer, height, from, currencyAmount
}) => {
  const {
    register, watch, errors, setValue,
  } = useFormContext();
  const currentWatch = watch([name])[name];
  const currentError = errors[name];
  const [focus, setFocus] = useState(false);
  const [value, setValueState] = useState(currentWatch);
  const [error, setError] = useState(currentError);
  const [flagTelephone, setFlagTelephone] = useState(false);
  const [currencySign, showCurrency] = useState(false);
  const keymaps = [8,48,49,50,51,52,53,54,55,56,57,188, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
  let componentClasses = classNames({
    label,
    focus,
    error,
    set: value,
  });

  // IEInputStraatnaam and IEInputNummer is used change styles when run in Internet Explorer
  if(IEInputStraatnaam){
    componentClasses = componentClasses + ' IEInputStraatnaam'
  }

  if(IEInputNummer){
    componentClasses = componentClasses + ' IEInputNummer'
  }

  useEffect(() => {
    setValueState(null);
    setError(null);
    const timer = setTimeout(() => {
      if(currencyAmount && currentWatch !== value){
       // let formattedValue = formatingAmounts(currentWatch);
        // const formattedAmount = toDutchAmount(currentWatch);
        setValueState(currentWatch);
        setValue([name], currentWatch);
        setError(currentError);
      }else{
        setValueState(currentWatch);
        setError(currentError);
      }
    }, 600);
    return () => clearTimeout(timer);
  }, [currentWatch, currentError]);

  useEffect(() => {
    if (type === 'tel' && (currentWatch && currentWatch.length >= 3) && (currentWatch.charAt(2) === '0' )) {
      let newString = currentWatch.slice(0, 2) + currentWatch.slice(3)
      setFlagTelephone(true);
      setValue([name],newString);
      setValueState(newString);
    }
    if (type === 'tel' && (currentWatch && currentWatch.length >= 3) && (currentWatch.charAt(2) === '6' ) && flagTelephone) {
      let newString = currentWatch.slice(0, 2) + currentWatch.slice(3)
      setFlagTelephone(false);
      setValue([name],newString);
      setValueState(newString);
    }
    if (
      currentWatch &&
      (name.includes("firstName")
      || name.includes("lastName")
      || name.includes("firstname")
      || name.includes("lastname")
      || name.includes("FirstName")
      || name.includes("LastName")
      )
      ) {
      let uppercaseConvert = currentWatch.charAt(0).toUpperCase() + currentWatch.slice(1);
      setValue([name],uppercaseConvert);
      setValueState(uppercaseConvert);
    }
  }, [focus, currentError, setValue, currentWatch])

  let registerData = {
    required: required ? (label ? `Vul ${label.toLowerCase()} in` : 'Vul dit veld in') : required,
  };

  if (type === 'email') {
    registerData = {
      ...registerData,
      pattern: {
        value: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Geen geldig e-mailadres',
      },
    };
  }

  if (type === 'tel') {
    registerData = {
      ...registerData,
      minLength: {
        value: 10,
        message: 'Telefoonnummer te kort',
      },
      maxLength: {
        value: 10,
        message: 'Telefoonnummer te lang',
      },
      pattern: {
        value: /^[0-9]*$/,
        message: 'Geen geldig telefoonnummer',
      },
    };
  }

  const formatingAmounts = (amount) => {
    try{
      
      if(!amount || amount === '') return '';

      if(!amount.match(/\d/g)) return amount;
  
      if(amount.match(/\,/g)){
        const splitted = amount.split(",");
        if(splitted[1].length > 2){
          amount = splitted[0]+","+splitted[1].substring(0, 2);
        } 
      }
  
      return amount;

    }catch(err){
      console.log('error formating amount : ', err)
    }
  }

 const onKeyUp = (evt) => {
    if(currentWatch && currentWatch !== '' && currencyAmount){
      let newCurrentWatch = '';
      if(!keymaps.includes(evt.which)){
        newCurrentWatch = currentWatch.substring(0, currentWatch.length - 1);

        setValueState(newCurrentWatch);
        setValue([name], newCurrentWatch);

      }else{
        newCurrentWatch = currentWatch;
        if(newCurrentWatch.match(/\,/g)){
          const splitted = currentWatch.split(",");
          if(splitted[1].length > 2){
            newCurrentWatch = splitted[0]+","+splitted[1].substring(0, 2);
          } 

          setValueState(newCurrentWatch);
          setValue([name], newCurrentWatch)
        }
      }
    } 
  }

  return (
    <StyledComponent height={height} className={componentClasses} htmlFor={name}>
      <div className="label">{error ? error.message : label}</div>
      {
          currencyAmount === true ?
          <> 
            { currencySign &&
              <span className="numPrefix">€</span>
            }
            
            <input
              type={type}
              id={name}
              name={name}
              autoComplete="new-password"
              ref={register(registerData)}              
              onBlur={() => setFocus(false)}
              onFocus={(evt) => {
                setValue([name], value || defaultValue );
                setFocus(true);
                showCurrency(true)
              }}
              className={currencyAmount && currencySign && 'currency'}
              //onKeyUp={(evt) => onKeyUp(evt)}
            />
         </>
        :
          <input
            type={type}
            id={name}
            name={name}
            autoComplete="new-password"
            ref={register(registerData)}
            onBlur={() => setFocus(false)}
            onFocus={() => {
              setValue([name], value || defaultValue);
              setFocus(true);
            }}
          />
      }
    </StyledComponent>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  height: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'tel']),
  required: PropTypes.bool,
  IEInputStraatnaam: PropTypes.bool,
  IEInputNummer: PropTypes.bool,
  from: PropTypes.string,
  currencyAmount: PropTypes.bool
};

Input.defaultProps = {
  label: null,
  defaultValue: '',
  type: 'text',
  required: false,
  IEInputStraatnaam: false,
  IEInputNummer: false,
  height: '50px',
  currency : false,
  from : "",
  currencyAmount : false
};

export default Input;
