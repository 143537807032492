import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';
import {
  gray,
  grayDark,
  green,
  field,
  fieldActive,
  fieldError,
  fieldColor,
  blueDark,
  borderGray,
  borderGreen,
  dark,
  borderRed,
  errorRed
} from '../../constants/colors';

/* Styles */
import { icomoon } from '../../styles/icomoon';

export default styled.label`
  width: 100%;
  position: relative;
  cursor: pointer;
  

  &.set {
    .holder:before {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }

    .holder:after {
      color: ${blueDark};
    }

    select {
      padding-left: 56px;
      background-color: ${fieldActive};
      border-bottom: 1px solid ${borderGreen};
    }
  }

  &.error {
    select {
      background-color: ${fieldError};
      border-bottom: 1px solid ${borderRed}
      background-color: ${errorRed};
    }
  }

  .holder {
    position: relative;

    &:before {
      ${icomoon};
      content: "\\e901";
      font-size: 24px;
      color: ${green};
      position: absolute;
      top: 50%;
      left: ${space}px;
      opacity: 0;
      transform: translateY(-50%) scale(0.7);
      pointer-events: none;
      transition: opacity 300ms ease, transform 300ms ease-out;
      transition-delay: 20ms;
    }

    &:after {
      ${icomoon}
      content: "\\e909";
      font-size: 10px;
      color: ${blueDark};
      position: absolute;
      top: 50%;
      right: ${space}px;
      transform: translateY(-50%);
      transition: color 200ms;
      pointer-events: none;
    }
  }

  select {
    -webkit-appearance: none;
    width: 100%;
    height: ${(props) => props.height};
    color: ${dark};
    font-size: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0 30px 0 16px;
    background-color: ${fieldColor};
    transition: padding 300ms ease-out, background-color 200ms;
    cursor: pointer;
    border-bottom: 1px solid ${borderGray};
    font-weight:400

    &::-ms-expand {
      display: none;
    }
  }
`;
