const components = [
  'fake',
  'card',
  'textBalloon',
  'header',
  'dialog',
  'fullScreenLoader',
];

export default (name) => components.indexOf(name);
