import React from 'react';
import { Link } from 'react-router-dom';

/* Constants */
import { completeOverview } from '../../constants/routes';

/* Style */
import StyledComponent from './style';

const CompleteMenu = () => {
  const items = [
    {
      label: 'Mijn dossier',
      link: completeOverview,
    },
  ];

  return (
    <StyledComponent>
      {
        items.map((item, i) => (
          <li key={item.label}>
            <Link className="topLink" to={item.link}>{item.label}</Link>
          </li>
        ))
      }
    </StyledComponent>
  );
};

export default CompleteMenu;
