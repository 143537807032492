import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestSystemRedirectDemoUrl } from '../redux/actions/request';
const pagePath = "/lening-aanvragen/test-inverse-wizard"

const testInverseWizard = ({ dispatch, loanRequestId, customerId }) =>  {
    
 const requestUrl = () =>{
  dispatch(requestSystemRedirectDemoUrl({
    loanRequestId : loanRequestId,
    customerId: customerId,
    returnUrlPath : pagePath.substring(1)
  }))
 }   
  
  return (
    <div style={{"width":"100%", "text-align":"center"}}>
      <button style={{"width":"180px", "padding": "15px", "border": "1px solid #4c46e4", "margin-top" : "300px"}} onClick={requestUrl}>
          Click to redirect
      </button>
    </div>
  )
};

testInverseWizard.propTypes = {
    dispatch: PropTypes.func.isRequired,
    customerId: PropTypes.string.isRequired,
    loanRequestId: PropTypes.string.isRequired
};

export default connect((state) => ({
    loanRequestId: state.request.businessKey,
    customerId: state.request.customerId
  }))(testInverseWizard);
