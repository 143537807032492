import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

/* Style */
import StyledComponent from './style';

const Radio = ({ name, options, required }) => {
  const { register } = useFormContext();
  const renderOptions = () => (
    options.map((option) => (
      <label
        key={option.label}
        className="option"
        htmlFor={option.label}
      >
        <input
          type="radio"
          id={option.label}
          name={name}
          value={option.label}
          ref={register({ required })}
        />
        <div className="radio" />
        <div className="label">{option.label}</div>
      </label>
    ))
  );

  return (
    <StyledComponent>
      {renderOptions()}
    </StyledComponent>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
};

Radio.defaultProps = {
  required: false,
};

export default Radio;
