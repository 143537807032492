export const unique = (myArr) => {
  if (!myArr) return;
  return myArr.filter((v, i, a) => a.indexOf(v) === i);
};

export const uniqueBy = (myArr, prop) => {
  if (!myArr || !prop) return;
  return myArr.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos);
};

export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};
