import React from 'react';

/* Objects */
import Container from '../objects/container';

/* Components */
import NoOpportunity from '../components/no-opportunity';

export default () => (
  <Container width={770}>
    <NoOpportunity />
  </Container>
);
