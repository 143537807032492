import React, {
  useRef, useEffect, forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

/* Utils */
import { shuffle as doShuffle } from '../../utils/array';

const Youtube = forwardRef(({ videoId, playlistId, shufflePlaylist }, ref) => {
  const player = useRef(null);
  const shuffle = useRef(shufflePlaylist);
  const onPlayerReady = () => {
    if (playlistId) {
      player.current.cuePlaylist({
        list: playlistId,
      });
    }
  };

  const onPlayerStateChanged = () => {
    let playlist = player.current.getPlaylist();

    // Shuffle playlist after cueing, because of shuffle bug in api
    if (shuffle.current && playlist) {
      playlist = doShuffle(playlist);
      player.current.cuePlaylist(playlist);
      shuffle.current = false;
    }
  };

  const loadVideo = () => {
    player.current = new window.YT.Player('player', {
      videoId,
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChanged,
      },
    });
  };

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = `${window.location.protocol}//www.youtube.com/iframe_api`;

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = loadVideo;

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      loadVideo();
    }
  });

  useImperativeHandle(ref, () => ({
    nextVideo() {
      player.current.nextVideo();
    },
  }));

  return (
    <StyledComponent>
      <div id="player" />
    </StyledComponent>
  );
});

Youtube.propTypes = {
  videoId: PropTypes.string,
  playlistId: PropTypes.string,
  shufflePlaylist: PropTypes.bool,
};

Youtube.defaultProps = {
  videoId: null,
  playlistId: null,
  shufflePlaylist: false,
};

export default Youtube;
