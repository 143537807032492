import styled from 'styled-components';

/* Constants */
import { gray, primary2 } from '../../constants/colors';

export default styled.div`

  &.center {
    display: table;
    margin: 0 auto;
  }

  &.right {
    display: table;
    margin-left: auto;
  }

  &.gray a {
    color: ${gray};

    &:hover {
      color: ${primary2};
    }
  }

  &.fullWidth{
    width:100%;
  }
`;
