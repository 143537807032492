import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/* Style */
import StyledComponent from './style';

const LinkTo = ({ to, children, className }) => (
  <StyledComponent className={className}>
    <Link to={to}>{children}</Link>
  </StyledComponent>
);

LinkTo.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LinkTo.defaultProps = {
  className: '',
};

export default LinkTo;
