import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

/* Constants */
import {
  basicInfo, personalInfo, financialInfo,
  requestSorry, requestThanks,
} from '../../constants/routes';

/* Style */
import StyledComponent from './style';

const MainMenu = () => {
  const sorry = !!useRouteMatch(requestSorry);
  const items = [
    {
      label: 'Financieringsbehoefte',
      step: 1,
      active: !!useRouteMatch(basicInfo),
    },
    {
      label: 'Bedrijfsgegevens',
      step: 2,
      active: !!useRouteMatch(personalInfo),
    },
    {
      hide: sorry,
      label: 'Banktransacties',
      step: 3,
      active: !!useRouteMatch(financialInfo),
    },
    {
      hide: !sorry,
      icon: 'icmn-dove',
      active: sorry,
    },
    {
      hide: sorry,
      icon: 'icmn-high-five',
      active: !!useRouteMatch(requestThanks),
    },
  ];
  const activeIndex = items.findIndex((i) => i.active);

  return (
    <StyledComponent>
      {
        items.map((item, i) => {
          if (item.hide) return null;

          const done = i < activeIndex;
          const { active } = item;
          const itemClasses = classNames({
            done,
            active,
            icon: item.icon,
          });

          return (
            <li
              className={itemClasses}
              key={item.label || item.icon}
            >
              {item.label && <span>{item.label}</span>}
              {item.step && !done ? `Stap ${item.step} van 3` : <i className="icmn-checkbox" />}
              {item.icon && <i className={item.icon} />}
            </li>
          );
        })
      }
    </StyledComponent>
  );
};

export default MainMenu;
