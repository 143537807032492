import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

/* Constants */
import { completeOverview } from '../../../constants/routes';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Radio from '../../../objects/radio';

/* Redux */
import { setValues, nextPage } from '../../../redux/actions/dept-form';

const Bankruptcy = ({ dispatch, defaultValues }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues });
  const { handleSubmit, formState, triggerValidation } = formMethods;
  const options = [
    { label: 'Ja' },
    { label: 'Nee' },
  ];

  // Fix validating defaultValues
  useEffect(() => {
    triggerValidation();
  }, [triggerValidation]);

  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(nextPage());
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="bold">Ben je in de afgelopen 8 jaar betrokken geweest bij een faillissement?</p>
        <div className="mt-20">
          <Radio
            name="bankruptcy"
            options={options}
            required
          />
        </div>
        <div className="mt-40 inDesktop">
          <Grid>
            <Link to={completeOverview}>
              <Button width="100%" outline>Terug naar mijn dossier</Button>
            </Link>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
            <Link to={completeOverview}>
              <Button width="100%" outline>Terug naar mijn dossier</Button>
            </Link>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Bankruptcy.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
};

Bankruptcy.defaultProps = {
  defaultValues: {},
};

export default connect((state) => ({
  defaultValues: state.deptForm,
}))(Bankruptcy);
