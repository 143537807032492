import {
  FETCH_OPPORTUNITY_SUCCESS,
} from '../actions/opportunity';

const INITIAL_STATE = {};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_OPPORTUNITY_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
