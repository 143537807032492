import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Objects */
import Container from '../objects/container';

/* Components */
import Later from '../components/later';

const RequestLater = ({ requestId }) => {
  const sId = localStorage.getItem('s_id');

  return (
    <Container width={770}>
      {
        sId
        && requestId
        && <img src={`https://www.financeads.net/tl.php?p=2367&oid=${requestId}&ocategory=upload&s_id=${sId}`} alt="pixel" />
      }
      <Later />
    </Container>
  );
};

RequestLater.propTypes = {
  requestId: PropTypes.string,
};

RequestLater.defaultProps = {
  requestId: null,
};

export default connect((state) => ({
  requestId: state.request.id,
}))(RequestLater);
