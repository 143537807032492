import styled from 'styled-components';

/* Constants */
import { space, spaceSmall } from '../../constants/spaces';
import {
  blueGradientStart, blueGradientEnd, green, field, grayDark, grayLight, blueLight
} from '../../constants/colors';

export default styled.ul`
  // padding: 0;
  font-size: 14px;
  // list-style-type: none;
  // position: relative;
  display: none;
  // flex-direction: row;
  // justify-content: space-between;
  // margin-top: 14px;
  // margin-bottom: 40px;
  font-weight:400

  @media only screen and (min-width: 544px) {
    display: block;
  }

  &:after {
    content: '';
    width: 100%;
    height: 4px;
    display: block;
    background: linear-gradient(to right, ${blueGradientStart}, ${blueGradientEnd} ${(props) => props.progress}px, ${(field)} ${(props) => props.progress}px);
  }

  ul {
    width: 730px;
    max-width: 100%;
    margin: 0 auto;
    padding: ${spaceSmall}px 20px;
    display: flex;
    list-style-type: none;
  }

  li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: ${blueLight};
    cursor: default;

    &:first-child {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 400;
      color: ${grayDark};
    }

    .icon {
      width: 31px;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${space}px;
      //border-radius: 50%;
     // color: ${grayLight};
      border: 1px solid #ffffff;
      color: #fff;
      background-image:url(${process.env.PUBLIC_URL}/images/progressRect.svg);
      font-weight: 700;
      background-repeat: no-repeat;
    }

    &.done {
      .icon {
        color: '#8968FD';
        // border: 1px solid ${blueGradientStart};
        background: white;
        font-size: 12px;
        font-weight: 400;
        background-image:url(${process.env.PUBLIC_URL}/images/progressRectDone.svg);
      }
    }

    &.active {
      color: ${blueLight};

      .icon {
         //color: ${green};
        // border: 1px solid ${blueGradientStart};
        background: white;
        font-size: 14px;
        font-weight: 700;
        background-image:url(${process.env.PUBLIC_URL}/images/progressRectActive.svg);
        color: #8968FD;
      }
    }
  }
`;
