import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import { Redirect, Link } from 'react-router-dom';

/* Constants */
import { basicInfo, requestSorry, financialInfo, temp_psd2 } from '../constants/routes';
import {
  activeValues, revenueValues, goalOptions,
  whenOptions,
} from '../constants/options';

/* Objects */
import Container from '../objects/container';
import Grid from '../objects/grid';
import Input from '../objects/input';
import Checkbox from '../objects/checkbox';
import Button from '../objects/button';
import Card from '../objects/card';
import Confetti from '../objects/confetti';

/* Components */
import PageLayout from '../components/page-layout';
import SearchCompany from '../components/search-company';
import RequestProgress from '../components/request-progress';

/* Redux */
import { setValues, submit } from '../redux/actions/request';

const PersonalInfo = ({
  dispatch, loading, request, psd2ormt940request, invalidBaseProfile
}) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: { ...request },
  });
  const {
    handleSubmit,
    formState,
    watch,
    getValues,
  } = formMethods;
  const kvk = watch('kvkNumber');
  const partner = localStorage.getItem('partner');
  const activeSummary = [
    'Je bent een startende ondernemer',
    'Je bent een startende ondernemer',
    'Je bent een ervaren ondernemer',
    'Je bent een ondernemer in hart en nieren',
  ];
  const activeSummaryPartner = [
    'Een startende ondernemer',
    'Een startende ondernemer',
    'Een ervaren ondernemer',
    'Een ondernemer in hart en nieren',
  ];
  const revenueSummary = [
    'met een goed begin qua omzet,',
    'met een prima omzet,',
    'met een lekkere omzet,',
    'met een serieuze omzet,',
    'met een prachtige omzet,',
    'met een fantastische omzet,',
    'met een jaloersmakende omzet,',
  ];
  const goalSummary = [
    'die wacht op debiteuren',
    'die kansen wil grijpen',
    'die de boel draaiende wil houden',
    'die graag wil investeren',
    'die graag meer klanten trekt',
    'die graag een nieuwe wagen wilt',
    'die de boel flink gaat verbouwen',
    'die een voorschot nodig heeft',
    'die een mooi pand op het oog heeft',
    'die een nieuwe investering nodig heeft',
    'die een lening goed kan gebruiken',
  ];
  const whenSummary = [
    // 'en daarvoor het geld het liefst gister al had ontvangen.',
    'en daarvoor het geld het liefst vandaag nog ontvangt.',
    'en daarvoor het geld het liefst deze week nog ontvangt.',
    'en daarvoor het geld binnenkort nodig heeft.',
    'en daarvoor benieuwd is naar de mogelijkheden.',
  ];
  const textArray = [
    activeValues.indexOf(request.active),
    revenueValues.indexOf(request.revenue),
    goalOptions.indexOf(request.goal),
    whenOptions.indexOf(request.when),
  ];
  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(submit());
  };

  const setValuesLocal = () => {
    const filledValues = getValues();
    dispatch(setValues(filledValues));
  };

  const handleSubmitBtn = () =>{
    if(getValues()){
      if(getValues().terms && (getValues().firstName && getValues().firstName !== "")
        && (getValues().lastName && getValues().lastName !== "") && (getValues().email &&
          getValues().email !== "") && (getValues().phone && getValues().phone !== "") && !invalidBaseProfile){
            return true
      }else {
            return false
      }
    }
  }

  // Redirect after submit
  if (request.submit) {
    dispatch(setValues({ submit: false }));

    // Redirect
    if (request.potential === 'low') return <Redirect to={requestSorry} push />;
    return <Redirect to={psd2ormt940request && psd2ormt940request === "PSD2" ? temp_psd2 : financialInfo} push />;
  }

  let text = ""
  if(partner){
    text = <p className={`leadSmall dark  ${(request.potential !== 'low' && request.potential !== 'LOWREV') ? 'mt-40' : ''}`}>
      Wat is de <strong>bedrijfsnaam</strong> of <strong>KVK-nummer</strong> van je klant?</p>
  }else{
    text = <p className={`leadSmall dark  ${(request.potential !== 'low' && request.potential !== 'LOWREV') ? 'mt-40' : ''}`}>
      Wat is je <strong>bedrijfsnaam</strong> of <strong>KVK-nummer</strong>?</p>
  }
  
  const renderFields = () => {
    if (!kvk) return;
    return (
      <>
        {partner
          && (
            <p className="bold">Klantgegevens</p>
          )}
        <div className="mt-10">
          <Grid columns={2}>
            <Input
              name="firstName"
              label="Voornaam"
              required
            />
            <Input
              name="lastName"
              label="Achternaam"
              required
            />
            {partner ? (
              <Input
                name="email"
                label="E-mailadres"
                type="email"
              />
            ) : (
              <Input
                name="email"
                label="E-mailadres"
                type="email"
                required
              />
            )}
            {partner ? (
              <Input
                name="phone"
                label="Mobiel telefoonnummer"
                type="tel"
                defaultValue="06"
              />
            ) : (
              <Input
                name="phone"
                label="Mobiel telefoonnummer"
                type="tel"
                defaultValue="06"
                required
              />
            )}
          </Grid>
        </div>
        {
          partner
          && (
            <div className="mt-20">
              <p className="bold">Partner gegevens</p>
              <div className="mt-10">
                <Grid columns={2}>
                  <Input
                    name="partnerFirstName"
                    label="Voornaam"
                    required
                  />
                  <Input
                    name="partnerLastName"
                    label="Achternaam"
                    required
                  />
                  <Input
                    name="partnerEmail"
                    label="E-mailadres"
                    required
                  />
                  <Input
                    name="partnerPhone"
                    label="Telefoonnummer"
                    type="tel"
                    defaultValue="06"
                    required
                  />
                </Grid>
              </div>
            </div>
          )
        }
        <div className="mt-20">
          <Checkbox
            name="terms"
            label={
                <div>
                  <span style={{color: '#1E2749', opacity: '70%', 'font-weight': 400}}>Ik geef toestemming voor de verzameling, verwerking en het gebruik van mijn gegevens zoals is beschreven in het </span>
                  <a style={{textDecoration: 'underline', color: '#1E2749', opacity: '70%', 'font-weight': 400}} href={'https://www.bridgefund.nl/bedrijf/privacy-statement/'}>privacybeleid.</a>
                  <span style={{color: '#1E2749', opacity: '70%', 'font-weight': 400}}> Tevens ga ik akkoord met de </span>
                  <a style={{textDecoration: 'underline', color: '#1E2749', opacity: '70%', 'font-weight': 400}} href={'https://www.bridgefund.nl/bedrijf/algemene-voorwaarden/'}>algemene voorwaarden.</a>
                </div>
              }
            required
          />
        </div>
      </>
    );
  };

  return (
    <PageLayout>
      <RequestProgress />
      <Container width={730}>
        {
          request.potential === 'low' ?
          (<span></span>)
          : request.potential === 'LOWREV' ?
          (<span></span>)
          :
          (
            <Confetti>
              <Card image={`${process.env.PUBLIC_URL}/images/icons/party-popper.png`}>
                <p style={{"font-size":"16px", "line-height":"25px"}}>
                  {partner ? (<strong>De aanvraag ziet er positief uit!</strong>) : (<strong>Je aanvraag ziet er positief uit!</strong>)}
                  <br />
                  {partner ? activeSummaryPartner[textArray[0]] : activeSummary[textArray[0]]}
                  {' '}
                  {revenueSummary[textArray[1]]}
                  {' '}
                  {goalSummary[textArray[2]]}
                  {' '}
                  {whenSummary[textArray[3]]}
                  {' '}
                  {partner ? 'Laten we snel kijken wat we voor je klant kunnen betekenen.' : 'Laten we snel kijken wat we voor je kunnen betekenen.'}
                </p>
              </Card>
            </Confetti>
          )
        }

        {text}
        
        {/* <p className={`leadSmall dark  ${(request.potential !== 'low' && request.potential !== 'LOWREV') ? 'mt-40' : ''}`}>
          {
            partner
              ? 'Wat is de bedrijfsnaam of KVK-nummer van je klant?'
              : 'Wat is je bedrijfsnaam of KVK-nummer?'
          }
        </p> */}
        <FormContext {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} id="GT-FORM-PERSONAL">
            <SearchCompany />
            <div className="mt-40">
            {
              invalidBaseProfile ?
                <div id="failMsg">
                  <div id="failTop">
                    <img src={`${process.env.PUBLIC_URL}/images/icons/frown.svg`} />
                        <p>Dit bedrijf is volgens de KVK niet in Nederland gevestigd.</p>
                    </div>
                    <p id="failContent">
                        Selecteer een ander bedrijf.
                    </p>
                </div>
                :
                renderFields()
            }
             
              <div className="mt-40 inDesktop marginBottomInButtonsDesktop">
                <Grid>
                  <Link to={basicInfo}>
                    <Button
                      width="100%"
                      outline
                      onClick={() => setValuesLocal()}
                    >
                      Vorige stap
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    width="100%"
                    loading={loading}
                    disabled={handleSubmitBtn() ? false : !formState.isValid ? true : invalidBaseProfile}
                  >
                    Volgende stap
                  </Button>
                </Grid>
              </div>
              <div className="mt-40 inMobile inMobileSpecial">
                <Grid>
                  <Button
                    type="submit"
                    width="100%"
                    loading={loading}
                    disabled={handleSubmitBtn() ? false : !formState.isValid ? true : invalidBaseProfile}
                  >
                    Volgende stap
                  </Button>
                  <Link to={basicInfo}>
                    <Button
                      textAlign="center"
                      outline
                      textOnlyFullWidth
                      onClick={() => setValuesLocal()}
                    >
                      Vorige stap
                    </Button>
                  </Link>
                </Grid>
              </div>
            </div>
          </form>
        </FormContext>
      </Container>
    </PageLayout>
  );
};

PersonalInfo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  request: PropTypes.shape({
    submit: PropTypes.bool,
    active: PropTypes.string,
    revenue: PropTypes.string,
    goal: PropTypes.string,
    when: PropTypes.string,
    potential: PropTypes.string,
    invalidBaseProfile: PropTypes.bool
  }).isRequired,
};

PersonalInfo.defaultProps = {
  loading: false,
  invalidBaseProfile: false
};

export default connect((state) => ({
  loading: state.loading.request,
  request: state.request,
  psd2ormt940request: state.request.psd2ormt940request,
  invalidBaseProfile: state.error.invalidBaseProfile
}))(PersonalInfo);
