import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import md5 from 'md5';
import { useWindowDimensions } from '../utils/browserWidth';
import { requestPotentialParameters } from '../redux/actions/request'
import {fetchOpportunity} from '../redux/actions/opportunity';

/* Constants */
import {
    basicInfo, personalInfo, requestLater, requestThanks, opertunityClosed, completeOverview
} from '../constants/routes';

/* Objects */
import Container from '../objects/container';
import Button from '../objects/button';
import File from '../objects/file';
import Card from '../objects/card';
import Link from '../objects/link';
import Grid from '../objects/grid';
import Divider from '../objects/divider';

/* Componnents */
import PageLayout from '../components/page-layout';
import BankManual from '../components/bank-manual';
import RequestProgress from '../components/request-progress';
import FullScreenLoader from '../components/full-screen-loader';

/* pages */
import FinanacialInfo from './1-3FinancialInfo';

/* Redux */
import { sendFiles, setValues, getInverseRedirectURL, getData } from '../redux/actions/request';

const BankTransacties = ({
  dispatch, requestId, email, loading, isSubmitted, noConcent, dataLoading, loanRequestId, customerId, completeId
}) => {
  const paramId = useParams().id;
  const stap1Ref = useRef(null);
  const stap1RefMobile = useRef(null);
  const id = useParams().id || requestId || completeId;
  const formMethods = useForm({ mode: 'onChange' });
  const { handleSubmit, formState } = formMethods;
  const [files, setFiles] = useState([]);
  const [option, setOption] = useState("psd2");
  const [displayInfoWindow, setDisplayInfoWindow] = useState(false);
  const [displayHelpWindow, setDisplayHelpWindow] = useState(false);
  const partner = localStorage.getItem('partner');
  const { height, width } = useWindowDimensions(); // eslint-disable-line
  const pagePath = useLocation().pathname;
  let params = new URLSearchParams(useLocation().search);
  let fromMyDossier = false;
  let setPadding = 60;

  if(pagePath === '/afronden/banktransacties'){
    fromMyDossier = true;
  }

  if(fromMyDossier){
    setPadding = 0;
  }
  
  useEffect(() => {
    if(params && params.get('ConsentCollectionId') != null){
        let data = {
            consentCollectionId : params.get('ConsentCollectionId'),
            concentCount : params.get('ConsentCount'), 
            requestId : params.get('RequestId'),
        }
        dispatch(getData(data));
    }
  }, []);

  useEffect(() => {
    if(fromMyDossier){
        dispatch(fetchOpportunity());
    }
  }, []);
  
    // for normal flow
  //let path = useLocation().pathname.split('banktransacties/')[1]; 
    // seprate route for psd2
   let path = useLocation().pathname.split('banktransacties-psd2/')[1];
  
   useEffect(() => {
    if(path !== '' && path != null){
        dispatch(requestPotentialParameters(path))
    }else{
        if(id){
          dispatch(requestPotentialParameters(id))
        }
    }
  }, []);

  // Redirect if no id
  if(params.get('ConsentCollectionId')== null && !fromMyDossier){
    if (!id && path === '' && path === null) return <Redirect to={basicInfo} />;
  }
 

  // Redirect after submit
  if (isSubmitted) {
    try {
      const ADT = window.ADT || {};
      ADT.Tag = ADT.Tag || {};
      ADT.Tag.t = 4;
      ADT.Tag.c = 'EUR';
      ADT.Tag.tp = 1260627010;
      ADT.Tag.ti = id;
      ADT.Tag.xd = md5(encodeURI(email.toLowerCase()));
      ADT.Tag.doEvent();
    } catch (error) {}

    // Reset submit status
    dispatch(setValues({ submit: false }));

    return <Redirect to={requestThanks} push />;
   // return <Redirect to={`${completeOverview}/${requestId}`} push />;
  }

  if(dataLoading){
    return <FullScreenLoader />;
  }

  const handleFiles = (f) => {
    setFiles(f);
  };

  const onSubmit = () => {
    dispatch(sendFiles(id, files));
  };

  const setTrasactionsOption = (option) => {
    setOption(option)
  }

  const handleInfoDisplay = (val) => {
    setDisplayInfoWindow(val)
  }

  const handleHelpWindow = (val) => {
    setDisplayHelpWindow(val)
  }

  const startPSD2Upload = () => {
    dispatch(getInverseRedirectURL({
        loanRequestId : loanRequestId,
        customerId : customerId,
        returnUrlPath : pagePath.substring(1)
    }));
  }

  return (
    <PageLayout fromMyDossier={fromMyDossier}>
      {!paramId && !fromMyDossier && <RequestProgress />}
      <Container width={660} padding={setPadding}>
      {
         !fromMyDossier && 
            <Card image={`${process.env.PUBLIC_URL}/images/icons/robot-face.png`}>
                <p>
                    <strong>
                        {
                            partner ? 'Lever je zakelijke banktransacties aan voor een aanbod op maat.'
                            : 'Lever je zakelijke banktransacties aan voor een aanbod op maat.'
                        }
                    </strong>
                    <br />
                        {
                            partner ? 'Wij doen niet aan businessplannen of jaarcijfers. Onze slimme scan checkt razendsnel je zakelijke banktransacties van de afgelopen 12 maanden. Zo bepalen we zorgvuldig hoeveel je maximaal kunt lenen.'
                            : 'Wij doen niet aan businessplannen of jaarcijfers. Onze slimme scan checkt razendsnel je zakelijke banktransacties van de afgelopen 12 maanden. Zo bepalen we zorgvuldig hoeveel je maximaal kunt lenen.'
                        }
                </p>
            </Card>
        } 

        {
            fromMyDossier && 
            <>
                <h2>Banktransacties</h2>
                <Divider />
                <p className="lead light smallFontDark">Kies één van de volgende methoden om je zakelijke banktransacties aan te leveren.</p>
            </>
            
        }

        {
            !fromMyDossier &&
            <>
                <p className="inDesktop mainHeading" ref={stap1Ref}>
                    Kies een aanlevermethode   
                </p>
                <p className="inMobile inMobileSpecial mainHeading" ref={stap1RefMobile}>
                    Kies een aanlevermethode           
                </p>
            </>
        }

        {
            noConcent &&

            <div id="psd2FailMsg">
                <div id="psd2FailTop">
                    <img src={`${process.env.PUBLIC_URL}/images/icons/frown.svg`} />
                    <p>Importeren mislukt</p>
                </div>
                <p id="psd2FailContent">
                    Het automatisch importeren is niet gelukt. Upload je banktransacties handmatig of probeer het opnieuw.
                </p>
            </div>
        }

        <div>
            { <div className={`mt-20 transactionsOptions ${option === "psd2" ? "selectedOption" : ""} `} onClick={() => setTrasactionsOption('psd2')}>
                <p id="legendLabel" className={`${option === "psd2" ? "legendLabelActive" : "legendLabelInactive"}`}>Eenvoudigste keuze</p>
                <div className="iconDiv">  
                    {
                       option === "psd2" ? 
                        <img className="selectedIcon" src={`${process.env.PUBLIC_URL}/images/icons/icon_selected.svg`} />
                        :
                        <img className="selectedIcon" src={`${process.env.PUBLIC_URL}/images/icons/icon_unselected.svg`} />
                    }
                </div>
                <div className="contentDiv">
                    <strong className={`optionHeading ${option === "psd2" ? "optionHeadingSelected" : ""} `}>Automatisch ophalen bij je bank</strong>
                        <p className="mt-10 ">
                            De meest eenvoudige en veilige manier om je zakelijke banktransacties aan te leveren. Na het koppelen worden je 
                            banktransacties automatisch opgehaald bij de bank. Je kan je bank op ieder moment weer ontkoppelen.
                        </p>
                </div>
                {
                    displayInfoWindow &&
                    <div id= { !fromMyDossier ? "PSD2InformativeWindow" : "PSD2Mt940CompleteInfo"}>
                        <div className="informativeTop">
                            <p className="informativeHeading">PSD2 koppeling</p>
                            <img onClick={() => handleInfoDisplay(false)} className="closeInformativeWindow" src={`${process.env.PUBLIC_URL}/images/icons/x-circle.svg`}/>
                        </div>
                        <p className="informativeContent">Door een koppeling te maken met je bank geef je ons inzicht in je banktransacties. Je banktransacties worden automatisch 
                        opgehaald door onze partner Invers BV. Dit is een door De Nederlandsche Bank goedgekeurde PSD2 service provider. Invers stuurt je gegevens enkel door naar 
                        BridgeFund en slaat geen transactie- of persoonsgegevens van je op. BridgeFund gebruikt je gegevens uitsluitend voor de kredietbeoordeling. Je kan je bank 
                        op ieder moment weer ontkoppelen.
                        </p>
                        <p className="informativeBottomTxt">› Lees hier meer over PSD2</p>
                    </div>
                }
                
                <div className="infoIconDiv">  
                    <img onClick={() => handleInfoDisplay(true)} className="infoIcon" src={`${process.env.PUBLIC_URL}/images/icons/infoIcon.svg`} />
                </div>
            </div>     }
                            
            <div className={`mt-20 transactionsOptions ${option === "mt940" ? "selectedOption" : ""} `} onClick={() => setTrasactionsOption('mt940')}>
                <div className="iconDiv">  
                    {
                       option === "mt940" ? 
                        <img className="selectedIcon" src={`${process.env.PUBLIC_URL}/images/icons/icon_selected.svg`} />
                        :
                        <img className="selectedIcon" src={`${process.env.PUBLIC_URL}/images/icons/icon_unselected.svg`} />
                    }
                </div>
                <div className="contentDiv">
                    <strong className={`optionHeading ${option === "mt940" ? "optionHeadingSelected" : ""} `}>Handmatig uploaden van een MT940-bestand</strong>
                        <p className="mt-10 ">
                            Upload je zakelijke banktransacties van de afgelopen 12 maanden in de vorm 
                            van een MT940-bestand. Deze kan je downloaden vanuit de online omgeving bij je bank.
                        </p>
                </div>
            </div>
        
            {
                option === 'mt940' && 
            <>  
            {
                displayHelpWindow && 
                <div id={!fromMyDossier ? "PSD2HelpWindow" : "PSD2Mt940CompleteHelpWindow"}>
                    <div className="informativeTop">
                        <p className="informativeHeading">Kom je er niet uit?</p>
                        <img onClick={() => handleHelpWindow(false)} className="closeInformativeWindow" src={`${process.env.PUBLIC_URL}/images/icons/x-circle.svg`}/>
                    </div>
                    <p className="informativeContent">Probeer dan onze andere aanlevermethode. Je banktransacties worden dan automatisch opgehaald bij de bank. 
                    </p>
                </div>
            }
                 
                <p className="mt-40 inDesktop" ref={stap1Ref}>
                    <strong className="stepHeadingsMt940">1. Download je banktransacties (MT940-bestand)</strong>
                    <img className="helpImg" onClick={() => handleHelpWindow(true)} src={`${process.env.PUBLIC_URL}/images/icons/help-circle.svg`} />
                </p>
                <p className="mt-40 inMobile stepHeadingsMt940" ref={stap1RefMobile}>
                    <strong className="stepHeadingsMt940">1. Download je banktransacties <br></br>(MT940-bestand)</strong>
                    <img className="helpImg" src={`${process.env.PUBLIC_URL}/images/icons/help-circle.svg`} />
                </p>
                <p className="linkText">Klik hier voor <a href="https://www.bridgefund.nl/hoe-download-ik-een-mt940-bestand/" target="_blank">downloadinstructies</a> per bank</p>

                <p className="mt-40 inDesktop" ref={stap1Ref}>
                    <strong className="stepHeadingsMt940">2. Upload je banktransacties:</strong>
                </p>
                <p className="mt-40 inMobile inMobileSpecial stepHeadingsMt940" ref={stap1RefMobile}>
                    <strong className="stepHeadingsMt940">2. Upload je banktransacties:</strong>
                </p>
            </>
            }

                <FormContext {...formMethods}>
                    <form onSubmit={handleSubmit(onSubmit)} id="MT940 GT-FORM-MT940">
                        {
                            option === 'mt940' && 
                            <div className="mt-20">
                            <File
                                name="mt940"
                                label="Selecteer je MT940 bestand"
                                labelActive="Selecteer nog een MT940 bestand"
                                onChange={handleFiles}
                                accept=".940,.txt,.sta,.swi,.dms,.mt940,.mt940structured"
                                allowedExtensions={/(\.940|\.txt|\.sta|\.swi|\.dms|\.mt940|\.mt940structured)$/i}
                                required
                            />
                            </div>
                        }
                        
                        <div className="inDesktop inDesktopActionWrapper">
                        <div className="mt-40">
                            <Grid>
                            {paramId ? <div></div> : 
                            <Link to={fromMyDossier ? `${completeOverview}/${id}` :personalInfo}>
                                <Button
                                    width="100%"
                                    outline
                                >
                                    {fromMyDossier ? "Terug naar mijin dossier" : "Vorige stap"}
                                </Button>
                            </Link>
                            }

                            {/* {
                               fromMyDossier && 
                                    <Link to={`${completeOverview}/${requestId}`}>
                                    <Button
                                        width="100%"
                                        outline
                                    >
                                        Vorige stap
                                    </Button>
                                </Link>
                            } */}
                            

                            {
                                option === 'mt940' ?
                                <Button
                                    type="submit"
                                    width="100%"
                                    disabled={!formState.isValid}
                                    loading={loading}
                                >
                                Banktransacties verzenden
                                </Button>
                                :
                                <Button
                                    type="button"
                                    width="100%"
                                    onClick={() => startPSD2Upload()}
                                >
                                Banktransacties ophalen
                                </Button>
                            }
                           
                            </Grid>
                        </div>
                        
                        </div>
                        <div className="mt-40 inMobile inMobileSpecial">
                        <Grid>
                            {
                                option === 'mt940' ?
                                <Button
                                    type="submit"
                                    width="100%"
                                    disabled={!formState.isValid}
                                    loading={loading}
                                >
                                Banktransacties verzenden
                                </Button>
                                :
                                <Button
                                    type="button"
                                    width="100%"
                                    onClick={() => startPSD2Upload()}
                                >
                                Banktransacties ophalen
                                </Button>
                            }
                            {
                            !paramId
                            &&
                            <Link to={fromMyDossier ? `${completeOverview}/${id}` :personalInfo} className="center fullWidth">
                            <Button
                                width="100%"
                                outline
                            >
                               {fromMyDossier ? "Terug naar mijin dossier" : "Vorige stap"}
                            </Button>
                            </Link>
                            }
                            
                        </Grid>
                        </div>
                    </form>
                </FormContext>  
        </div>
      </Container>
    </PageLayout>
  );
};

BankTransacties.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requestId: PropTypes.string,
  email: PropTypes.string,
  loading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  option: PropTypes.string,
  displayInfoWindow: PropTypes.bool,
  displayHelpWindow: PropTypes.bool,
  noConcent: PropTypes.bool,
  dataLoading: PropTypes.bool,
  loanRequestId: PropTypes.string,
  customerId: PropTypes.string,
  salesStage: PropTypes.string,
  completeId: PropTypes.string
};

BankTransacties.defaultProps = {
  requestId: null,
  email: null,
  loading: false,
  isSubmitted: false,
  option: 'psd2', //should be psd2
  displayInfoWindow: false,
  displayHelpWindow: false,
  noConcent : false,
  dataLoading: false,
  salesStage: '',
  completeId: ''
};

export default connect((state) => ({
  requestId: state.request.id,
  email: state.request.email,
  loading: state.loading.request,
  isSubmitted: state.request.submit,
  noConcent : state.request.noConcent,
  dataLoading: state.request.dataLoading,
  loanRequestId: state.request.businessKey,
  customerId: state.request.customerId,
  salesStage: state.request.salesStage,
  completeId: state.complete.id
}))(BankTransacties);
