import React from 'react';

/* Objects */
import Container from '../objects/container';

/* Components */
import NotFound from '../components/not-found';

const NotFoundPage = () => (
  <Container width={770}>
    <NotFound />
  </Container>
);

export default NotFoundPage;
