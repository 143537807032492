import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

/* Style */
import StyledComponent from './style';

/* Constants */
import { financialInfo } from '../../constants/routes';

/* Components */
import UspLinks from '../usp-links';

const PageLayout = ({ children, fromMyDossier }) => {
  const isFinancialInfo = !!useRouteMatch(financialInfo);

  const renderSidebar = () => (
    <div className="sidebar">
      <UspLinks ssl={isFinancialInfo} />
    </div>
  );

  return (
    <StyledComponent>
      <div className="children">
        {children}
      </div>
      {!fromMyDossier && renderSidebar()}
    </StyledComponent>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
