import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Objects */
import Container from '../objects/container';

/* Components */
import Thanks from '../components/thanks';

const RequestThanks = ({ requestId, location }) => {
  const sId = localStorage.getItem('s_id');

  return (
    <Container width={770}>
      {
        sId
        && requestId
        && <img src={`https://www.financeads.net/tl.php?p=2367&oid=${requestId}&ocategory=upload&s_id=${sId}`} alt="pixel" />
      }
      <Thanks from={location.from ? location.from : ""} />
    </Container>
  );
};

RequestThanks.propTypes = {
  requestId: PropTypes.string,
};

RequestThanks.defaultProps = {
  requestId: null,
};

export default connect((state) => ({
  requestId: state.request.id,
}))(RequestThanks);
