import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const ProgressBar = ({ currentPage, totalPages }) => {
  const currentPercentage = ((currentPage + 1) / totalPages) * 100;
  const style = {
    width: `${currentPercentage}%`,
  }

  return (
    <StyledComponent>
      <div style={style} />
    </StyledComponent>
  );
}

ProgressBar.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default ProgressBar;
