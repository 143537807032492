import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import Header from '../components/header';

const GeneralWrapper = ({ children }) => (
  <>
    <Header />
    {children}
  </>
);

GeneralWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GeneralWrapper;
