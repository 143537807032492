import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Select from '../../../objects/select';
import Input from '../../../objects/input';

/* Redux */
import { setValues, nextPage, prevPage } from '../../../redux/actions/ubo-form';

const Owner = ({ dispatch, defaultValues }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues });
  const { handleSubmit, formState, watch } = formMethods;
  const partnerOptions = [
    { label: 'Ja' },
    { label: 'Nee' },
  ];

  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(nextPage());
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="bold">Gegevens eigenaar</p>
        <div className="mt-20">
          <Grid columns={1}>
            <Grid columns={2}>
              <Input name="owners[0]firstname" label="Voornaam" required />
              <Input name="owners[0]lastname" label="Achternaam" required />
              <Input name="owners[0]birthday" label="Geb. datum" required />
              <Select
                name="owners[0]partner"
                placeholder="Gehuwed/Geregistreerd partner"
                options={partnerOptions}
                required
              />
            </Grid>
            <Grid columns={1}>
              <Input IEInputStraatnaam = {true} name="owners[0]streetname" label="Straatnaam" required  />
              <Input IEInputNummer = {true} name="owners[0]housenumber" label="Nummer" required  />
            </Grid>
            <Grid columns={2}>
              <Input name="owners[0]postalcode" label="Postcode" required />
              <Input name="owners[0]city" label="Plaats" required />
              <Input name="owners[0]email" label="E-mailadres" type="email" required />
              <Input name="owners[0]mobile" label="Telefoonnummer" type="tel" defaultValue="06" required />
            </Grid>
            {
              watch()['owners[0]partner'] === partnerOptions[0].label
              && (
                <Grid columns={1}>
                  <p className="bold">Gegevens van uw partner</p>
                  <Grid columns={2}>
                    <Input name="owners[0]firstnamePartner" label="Voornaam partner" required />
                    <Input name="owners[0]lastnamePartner" label="Achternaam partner" required />
                    <Input name="owners[0]emailPartner" label="E-mailadres partner" required />
                    {/* <Input name="owners[0]birthdayPartner" label="Geb. datum partner" required /> */}
                  </Grid>
                  <p className="sub light">Vul bovenstaand het e-mailadres van de partner in. Dit moet een ander e-mailadres zijn dan het e-mailadres dat reeds wordt gebruikt voor de belanghebbende. Wanneer je aanvraag is goedgekeurd dienen de partner(s) van belanghebbende(n) digitaal mee te tekenen op de borgstelling en sturen wij dit verzoek naar het e-mailadres dat je hier opgeeft.</p>
                </Grid>
              )
            }
          </Grid>
        </div>
        <div className="mt-40 inDesktop">
          <Grid>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Owner.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
};

Owner.defaultProps = {
  defaultValues: {},
};

export default connect((state) => ({
  defaultValues: state.uboForm,
}))(Owner);
