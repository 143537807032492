import React from 'react';

/* Constants */
import { bridgefund } from '../../constants/routes';

/* Style */
import StyledComponent from './style';

/* Objects */
import Divider from '../../objects/divider';
import Button from '../../objects/button';

export const NotFound = () => (
  <StyledComponent>
    <div className="icon">
      <img src="https://media.giphy.com/media/s4WqOPcza04hi/source.gif" alt="giphy" />
    </div>
    <h2>Deze pagina lijkt niet te bestaan.</h2>
    <Divider align="center" />
    <p className="lead light">
      Of probeerde je ons zojuist te hacken?
      <br />
      In dat geval zijn wij niet thuis.
    </p>
    <div className="mt-60">
      <a href={bridgefund}>
        <Button>Naar de homepage</Button>
      </a>
    </div>
  </StyledComponent>
);

export default NotFound;
