import styled from 'styled-components';

export default styled.div`

  & > div {
    width: 100%;
    
  }

  iframe {
    max-width: none;
  }

  .tp-widget-businessinfo{
    font-size:14px !important;
  }
`;
