import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import md5 from 'md5';
import { useWindowDimensions } from '../utils/browserWidth';

/* Constants */
import {
  basicInfo, personalInfo, requestLater, requestThanks, workflowStatus
} from '../constants/routes';

/* Objects */
import Container from '../objects/container';
import Button from '../objects/button';
import File from '../objects/file';
import Card from '../objects/card';
import Link from '../objects/link';
import Grid from '../objects/grid';

/* Componnents */
import PageLayout from '../components/page-layout';
import BankManual from '../components/bank-manual';
import RequestProgress from '../components/request-progress';
import RevisionPsd2 from '../components/revision_psd2';

/* Redux */
import { createRevisionDocument, setValues, checkWorkflowStatus } from '../redux/actions/request';

const RevisionDocumentUpload = ({
  dispatch, requestId, email, loading, isSubmitted, revisionPsd2Status, activeWorkflowFound, psdCompleted
}) => {
  const paramId = useParams().id;
  const stap1Ref = useRef(null);
  const stap1RefMobile = useRef(null);
  const id = useParams().id || requestId;
  const formMethods = useForm({ mode: 'onChange' });
  const { handleSubmit, formState } = formMethods;
  const [files, setFiles] = useState([]);
  const partner = localStorage.getItem('partner');
  const { height, width } = useWindowDimensions(); 
  const location = useLocation();
  const pathName = location.pathname
  const contractId = pathName.split('banktransacties')[1].split('/')[1];
  const riskAnlysisSequence = pathName.split('banktransacties')[1].split('/')[2];
  const params = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if(contractId && riskAnlysisSequence){
      dispatch(checkWorkflowStatus({contractId: contractId, riskAnlysisSequence : Number(riskAnlysisSequence)}));
    }
   
   },[]);

  // Redirect if no active workflow found -- psd2 revision
  if(!activeWorkflowFound) return <Redirect to={workflowStatus} />;
  
  // Redirect if no id
  if (!id) return <Redirect to={basicInfo} />;

  // Redirect after submit
  if (isSubmitted) {
    try {
      const ADT = window.ADT || {};
      ADT.Tag = ADT.Tag || {};
      ADT.Tag.t = 4;
      ADT.Tag.c = 'EUR';
      ADT.Tag.tp = 1260627010;
      ADT.Tag.ti = id;
      ADT.Tag.xd = md5(encodeURI(email.toLowerCase()));
      ADT.Tag.doEvent();
    } catch (error) {}

    // Reset submit status
    dispatch(setValues({ submit: false }));

    if(!revisionPsd2Status){
      return <Redirect to={{ 
        pathname: requestThanks, 
        from: "revision-document-upload" 
       }} push />;
    }
    
  }

  const handleFiles = (f) => {
    setFiles(f);
  };

  const onSubmit = () => {
    dispatch(createRevisionDocument(id, files));
  };

  return (
    <PageLayout>
      {!paramId && <RequestProgress />}
      <Container width={720}>
        {
          !psdCompleted ?

          <Card image={`${process.env.PUBLIC_URL}/images/icons/robot-face.png`}>
          <p>
            <strong>
              {
                activeWorkflowFound && revisionPsd2Status ? 'Upload je zakelijke banktransacties om je financiële situatie te updaten.'
                : 'Upload je zakelijke banktransacties voor de periodieke kredietcheck'
              }
            </strong>
            <br />
            {
               activeWorkflowFound && revisionPsd2Status ? 
               'Wij doen om de 6 maanden een check om te zorgen dat de huidige financiering nog steeds passend is voor jouw bedrijf. Hiervoor hebben we een up-to-date beeld nodig van jouw financiële situatie. Haal je banktransacties van de volgende rekening(en) automatisch op bij de bank.'
               : 'De kredietcheck wordt halfjaarlijks uitgevoerd om te beoordelen of het huidige krediet nog steeds passend is voor jouw onderneming. Zo bekijken wij of je krediet hetzelfde kan blijven, wordt verlaagd of juist wordt verhoogd!'
            }
          </p>
          </Card>

          :

          <div id="psd2revisionSuccessDiv">
            <img className="headerIcon" src={`${process.env.PUBLIC_URL}/images/icons/well_done.svg`} />
            <p className="successTxt"><strong>Gelukt!</strong></p>
            <div className="wfltxtWrapper">
              <p className="wflTxtContent">Jouw bankrekeningen zijn succesvol gekoppeld.<br></br> 
              De banktransacties van de volgende rekening(en) worden nu opgehaald:</p><br></br>
            </div>

          </div>

        }

        { activeWorkflowFound && revisionPsd2Status ?
          <div className="mt-20">
            <RevisionPsd2 contractId={contractId} pagePath={pathName} />
          </div>
          :
          <>
            <p className="mt-40 inDesktop" ref={stap1Ref}>
              <strong>
                {'Stap 1. Download je banktransacties (MT940-bestand)' }
              </strong>
            </p>
            <p className="mt-40 inMobile inMobileSpecial" ref={stap1RefMobile}>
              <strong>
                {'Stap 1. Download je banktransacties' }
              </strong>
              <br />
              <strong>(MT940-bestand)</strong>
            </p>
            <p className="light">
              {partner ?
                'Als je klant hulp nodig heeft bij het downloaden van de banktransacties. Selecteer dan de juiste bank voor instructies en stuur deze door naar je klant.'
                : 'Selecteer je bank voor instructies'}
            </p>
            <div className="mt-20">
              <BankManual scrollTo={width <= 543 ? stap1RefMobile.current : stap1Ref.current} />
            </div>
            <p className="mt-40">
              <strong>
                {'Stap 2. Upload je banktransacties'}
              </strong>
            </p>

            <FormContext {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)} id="MT940 GT-FORM-MT940">
                <div className="mt-20">
                  <File
                    name="mt940"
                    label="Selecteer je MT940 bestand"
                    labelActive="Selecteer nog een MT940 bestand"
                    onChange={handleFiles}
                    accept=".940,.txt,.sta,.swi,.dms,.mt940,.mt940structured"
                    allowedExtensions={/(\.940|\.txt|\.sta|\.swi|\.dms|\.mt940|\.mt940structured)$/i}
                    from="revisionUpload"
                    required
                  />
                </div>
                <div className="inDesktop inDesktopActionWrapper">
                  <div className="mt-40">
                    <Grid>
                      {paramId ? <div></div> : <Link to={personalInfo}>
                        <Button
                          width="100%"
                          outline
                        >
                          Vorige stap
                        </Button>
                      </Link>}
                      <Button
                        type="submit"
                        width="100%"
                        disabled={!formState.isValid}
                        loading={loading}
                      >
                        Verzenden
                      </Button>
                    </Grid>
                  </div>
                  {
                    !formState.isValid
                    && !paramId
                    && (
                      <Grid>
                        <div />
                        <Link to={requestLater} className="gray center mt-20">
                          Aanvraag opslaan
                        </Link>
                      </Grid>
                    )
                  }
                </div>
                <div className="mt-40 inMobile inMobileSpecial">
                  <Grid>
                    <Button
                      type="submit"
                      width="100%"
                      disabled={!formState.isValid}
                      loading={loading}
                    >
                      Verzenden
                    </Button>
                    {
                      !paramId
                      &&
                    <Link to={personalInfo} className="center fullWidth">
                      <Button
                        width="100%"
                        outline
                      >
                        Vorige stap
                      </Button>
                    </Link>
                    }
                    {
                      !formState.isValid
                      && !paramId
                      && (
                        <Link to={requestLater} className="gray center">
                          Aanvraag opslaan
                        </Link>
                      )
                    }
                  </Grid>
                </div>
              </form>
            </FormContext>
          
          </>
        }
           
      </Container>
    </PageLayout>
  );
};

RevisionDocumentUpload.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requestId: PropTypes.string,
  email: PropTypes.string,
  loading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  revisionPsd2Status: PropTypes.bool,
  activeWorkflowFound: PropTypes.bool,
  psdCompleted: PropTypes.bool 
};

RevisionDocumentUpload.defaultProps = {
  requestId: null,
  email: null,
  loading: false,
  isSubmitted: false,
  revisionPsd2Status : false,
  activeWorkflowFound : true,
  psdCompleted : false
};

export default connect((state) => ({
  requestId: state.request.id,
  email: state.request.email,
  loading: state.loading.request,
  isSubmitted: state.request.submit,
  revisionPsd2Status : state.request.revisionPsd2Status,
  activeWorkflowFound : state.request.activeWorkflowFound,
  psdCompleted : state.request.psdCompleted
}))(RevisionDocumentUpload);
