import axios from 'axios';

/* Redux */
import { toggleLoading } from './loading';
import { fetchOpportunity } from './opportunity';

/* env */
import ENV from '../../config/env';

/* Actions */
export const SET_COMPLETE_VALUES = 'SET_COMPLETE_VALUES';

export const setValues = (values) => {
  if (!values) return false;

  return (dispatch, store) => {
    const { complete } = store();
    const object = {
      ...complete,
      ...values,
    };

    // Store in local
    const storageObject = { ...object };
    delete storageObject.submit;
    localStorage.setItem('complete', JSON.stringify(storageObject));

    dispatch({
      type: SET_COMPLETE_VALUES,
      values: object,
    });
  };
};

export const loadPageData = () => (dispatch) => {
  dispatch(toggleLoading('fullscreen', true));
  dispatch(fetchOpportunity()).then(() => {
    dispatch(toggleLoading('fullscreen', false));
  });
};


export const sendFiles = (type, files) => {
  if (!type || !files) return;

  return (dispatch, store) => {
    const { complete } = store();
    const formData = new FormData();

    formData.append('id', complete.id);
    formData.append('type', type); // BANK, COC, DEBT, ID, SCREENSHOT, UBO, VAT, CREDITLIST, DEBITLIST, DEBT_FACILITY, YEARLY_STATEMENT

    // Set loading state
    dispatch(toggleLoading('complete', true));

    files.forEach((file) => {
      formData.append('files[]', file, file.name);
    });

    return axios.post('https://www.bridgefund.nl/create_documents.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(() => {
      dispatch(fetchOpportunity(true)).then(() => {
        dispatch(setValues({ submit: true }));
        dispatch(toggleLoading('complete', false));
      });
    });
  };
};
