import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';
import { xsMinWidth, mobileSmallerMaxWidth } from '../../constants/breakpoints';
import {
    borderGray
} from '../../constants/colors';

export default styled.div`

    .bankBlockWrapper{
        width: 100%;
        display: inline;
        float: left;
        height: 53px;
        border-bottom: 1px solid ${borderGray};
        margin-top: 8px;
    }

    .bankLogo{
        width: 66px;
        height: auto;
        float: left;

        img{
            float:left;
            width:44px;;
            height:auto;
        }
    }

    .bankName{
        width: 80%;
        height: 100%;
        float: left;

        p{
            margin-top: 5px;
            text-align: left;
        }
    }

    .bankBlockHolder{
        height: auto;
        width: 100%;
        float: left;
        margin-bottom: 35px;
    }

    #psd2FailMsgInRevision{
        width:100%;
        height:92px;
        background-color:#E3056515;
        margin: 13px 0 0 0;
      }

    .actionArea{
        width: 100%;
        margin-bottom: 35px;
        height: auto;
        float: left;
    } 
    
    .btnHolder{
        width:50%
        float: right;
    }

    .infoIcon{
        width: 20px;
        height: 20px;
        max-width: fit-content;
        float: right;
        margin-right: 5px;
    }

    @media only screen and (min-width: 280px) and (max-width: 320px) {

        .bankName{
            width: 60%;
        }

        #psd2FailMsgInRevision{
            height : 133px;
        }
        
    }
 

    @media only screen and (min-width: 321px) and (max-width: 382px) {

        .bankName{
            width: 64%;
        }

        #psd2FailMsgInRevision{
            height : 133px;
        }
        
    }

    @media only screen and (min-width: 383px) and (max-width: 425px) {
        .bankName{
            width: 70%;
        }

        #psd2FailMsgInRevision{
            height : 121px;
        }
    }

    @media only screen and (min-width: 326px) and (max-width: 516px) {
        .bankName{
            width: 75%;
        }

        #psd2FailMsgInRevision{
            height : 116px;
        }
    }

   

`;
