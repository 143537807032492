import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Grid = ({
  children, order, columns,
  columnGap, rowGap,
}) => {
  let totalColumns = children.length || 1;
  let gridColumns = 'repeat(auto-fit, minmax(1px, 1fr))';
  let gridColumnsIE = `(1fr)[${totalColumns}]`;


  if (columns) {
    gridColumns = typeof columns === 'string' ? `${columns}` : gridColumns;
    gridColumns = typeof columns === 'number' ? `repeat(${columns}, 1fr)` : gridColumns;
    gridColumns = typeof columns === 'object' ? columns : gridColumns;
  }

  if (columns) {
    gridColumnsIE = typeof columns === 'string' ? `${columns}` : gridColumnsIE;
    gridColumnsIE = typeof columns === 'number' ? `(1fr)[${columns}]` : gridColumnsIE;
    totalColumns = typeof columns === 'string' ? columns.split(' ').length : gridColumnsIE;
    totalColumns = typeof columns === 'number' ? columns : gridColumnsIE;
  }

  return (
    <StyledComponent
      order={order}
      gridColumns={gridColumns}
      gridColumnsIE={gridColumnsIE}
      totalColumns={totalColumns}
      columnGap={columnGap}
      rowGap={rowGap}
    >
      {children}
    </StyledComponent>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  order: PropTypes.number,
  columnGap: PropTypes.number,
  rowGap: PropTypes.number,
  columns: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string, // 1fr 2fr 1fr
    PropTypes.shape({}),
  ]),
};

Grid.defaultProps = {
  order: 0,
  columnGap: 32,
  rowGap: 20,
  columns: null,
};

export default Grid;
