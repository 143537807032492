import { combineReducers } from 'redux';
import companies from './reducers/companies';
import complete from './reducers/complete';
import deptForm from './reducers/dept-form';
import dialog from './reducers/dialog';
import error from './reducers/error';
import loading from './reducers/loading';
import opportunity from './reducers/opportunity';
import request from './reducers/request';
import uboForm from './reducers/ubo-form';

const reducers = combineReducers({
  companies,
  complete,
  deptForm,
  dialog,
  error,
  loading,
  opportunity,
  request,
  uboForm,
});

export default reducers;
