import React from 'react';
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router, Switch, Route,
  Redirect, useLocation,
} from 'react-router-dom';


/* Constants */
import {
  notFound, waiting,
  requestLoan, basicInfo, personalInfo,
  financialInfo, requestThanks, requestSorry,
  requestLater, completeRequest, completeError,
  completeOverview, completeMtFile, completeBtwResult,
  completeBankStatement, completeStakeholders, completeDeptForm,
  completePassport, completeFinancialState, completeCreditArg,
  completeProposal, completeWaiting, updateLoan, newFinancialInfoUpdate, test_inverse_wizard,
  temp_psd2, completeMtAndPsd2File, workflowStatus
} from './constants/routes';

/* Wrapper */
import General from './pages/0-0General';
import RequestLoan from './pages/1-0RequestLoan';
import Complete from './pages/2-0Complete';
import CompleteBootstrap from './pages/2-0CompleteBootstrap';

/* General pages */
import NotFound from './pages/0-1NotFound';
import Waiting from './pages/0-2Waiting';

/* Request pages */
import BasicInfo from './pages/1-1BasicInfo';
import PersonalInfo from './pages/1-2PersonalInfo';
import FinancialInfo from './pages/1-3FinancialInfo';
import RequestSorry from './pages/1-4RequestSorry';
import RequestLater from './pages/1-5RequestLater';
import RequestThanks from './pages/1-6RequestThanks';
import FinancialInfoReUpload from './pages/FinancialInfoReUpload';
import RevisionDocumentUpload from './pages/RevisionDocumentUpload';
import BankTransacties from './pages/1-3BankTransacties';
import WorkflowStatus from './pages/WorkflowStatus';

/* for development */
import testInverseWizard from './pages/test-inverseWizard';

/* Complete pages */
import CompleteError from './pages/2-1Error';
import CompleteWaiting from './pages/2-2Waiting';
import Overview from './pages/2-3Overview';
import MtFile from './pages/2-4MtFile';
import BtwResult from './pages/2-5BtwResult';
import BankStatement from './pages/2-6BankStatement';
import Stakeholders from './pages/2-7Stakeholders';
import DeptForm from './pages/2-8DeptForm';
import Passport from './pages/2-9Passport';
import FinancialState from './pages/2-10FinancialState';
import CreditArg from './pages/2-11CreditArg';
import Proposal from './pages/2-12Proposal';

/* Utils */
import { setCookie, getCookie } from './utils/cookie';
import { getGtmParameters } from "./utils/googleTagManager";

/* logRocker */
import LogRocket from 'logrocket';
LogRocket.init('kphrav/loan-application-flow');

const DoOnEveryPage = () => {
  const params = new URLSearchParams(useLocation().search);

  // GA tracking pageviews
  // ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
  // Partner id: track which partner is using the flow
  if (params.get('partner')) localStorage.setItem('partner', params.get('partner'));
  // s_id: track a finance ad conversion
  if (params.get('s_id')) {

    localStorage.setItem('s_id', params.get('s_id'));

  } else {

    const parameters = getGtmParameters();
    localStorage.setItem('s_id', parameters['s_id']);

  }
  // GCLID: track google conversion
  // if (params.get('gclid')) setCookie('gclid', params.get('gclid'));
  // Update intercom for page/url tracking
  // if (window.Intercom) window.Intercom('update');
  // Scroll to page top
  window.scrollTo(0, 0);

  return null;
};

export default () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Route component={DoOnEveryPage} />
    <Switch>
      <Route path={requestLoan}>
        <RequestLoan>
          <Switch>
            <Route path={basicInfo} component={BasicInfo} />
            <Route path={personalInfo} component={PersonalInfo} />
            <Route path={`${financialInfo}/:id?`} component={FinancialInfo} />

            {/* new psd2 + mt940 */}
            {/* <Route path={`${financialInfo}/:id?`} component={BankTransacties} />
            <Route path={financialInfo} component={BankTransacties} /> */}

            {/* temp psd2 */}
            <Route path={`${temp_psd2}/:id?`} component={BankTransacties} />
            <Route path={temp_psd2} component={BankTransacties} /> 

            <Route path={requestSorry} component={RequestSorry} />
            <Route path={requestLater} component={RequestLater} />
            {/* <Route path={requestThanks} component={RequestThanks} /> */}
            <Route path={requestThanks} render={(props) => <RequestThanks {...props}/>} />
            <Route path={test_inverse_wizard} component={testInverseWizard} />
            <Redirect from="/lening-aanvragen/basis-gegevens" to={basicInfo} />
            <Redirect from="/lening-aanvragen/persoonlijke-gegevens" to={personalInfo} />
            <Redirect from="/lening-aanvragen/financiele-gegevens/:id?" to={`${financialInfo}/:id?`} />
            <Redirect to={basicInfo} />
          </Switch>
        </RequestLoan>
      </Route>
      <Route path={updateLoan}>
        <FinancialInfoReUpload>
          <Switch>
            <Route path={`${newFinancialInfoUpdate}/:id?`} component={RevisionDocumentUpload} />
            <Route path={workflowStatus} component={WorkflowStatus} />
          </Switch>
        </FinancialInfoReUpload>
      </Route>
      <Route path={completeError}>
        <General>
          <CompleteError />
        </General>
      </Route>
      <Route path={completeWaiting}>
        <Complete>
          <CompleteWaiting />
        </Complete>
      </Route>
      <Route path={`${completeRequest}/:page/:id?`}>
        <CompleteBootstrap sidebar>
          <Switch>
            <Route path={completeOverview} component={Overview} />
            <Route path={completeMtFile} component={MtFile} />

            <Route path={completeMtAndPsd2File} component={BankTransacties} />
            
            <Route path={completeBtwResult} component={BtwResult} />
            <Route path={completeBankStatement} component={BankStatement} />
            <Route path={completeStakeholders} component={Stakeholders} />
            <Route path={completeDeptForm} component={DeptForm} />
            <Route path={completePassport} component={Passport} />
            <Route path={completeFinancialState} component={FinancialState} />
            <Route path={completeCreditArg} component={CreditArg} />
            <Route path={completeProposal} component={Proposal} />
            <Redirect to={completeOverview} />
          </Switch>
        </CompleteBootstrap>
      </Route>
      <Route path="*">
        <General>
          <Switch>
            <Route path={waiting} component={Waiting} />
            <Route path={notFound} component={NotFound} />
            <Redirect to={notFound} />
          </Switch>
        </General>
      </Route>
      <Redirect to={requestLoan} />
    </Switch>
  </Router>
  
);
