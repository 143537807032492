import styled from 'styled-components';

/* Styles */
import { icomoon } from '../../styles/icomoon';

/* Constants */
import { space, spaceSmall, spaceLarge } from '../../constants/spaces';
import {
  primary, primary2, grayLight, red, blueDark, dark2
} from '../../constants/colors';
import { xsMinWidth, mdMinWidth } from '../../constants/breakpoints';

export default styled.div`
  .banks {
    .bank {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 ${space * 1.5}px;
      border: 1px solid #dddddd;
      border-radius: 6px;
      cursor: pointer;
      transition: filter 300ms ease, border-color 200ms ease;

      &:hover {
        border-color: ${primary};
      }

      &.active {
        border-color: ${primary};
      }

      &.inactive {
        filter: grayscale(1);
      }
    }

    .more {
      padding: 0 17px;
      font-size: 14px;
      font-weight:400;

      &:after {
        ${icomoon};
        content: "\\e909";
        font-size: 13px;
        margin-left: 4px;
        color: ${dark2};
      }
    }
  }

  .select {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      font-size: 40px;
      margin-right: ${spaceLarge}px;
      color: ${grayLight};
    }

    .form {
      flex-grow: 1;
    }

    .logo {
      width: 250px;
      height: 50px;
      display: none;
      align-items: center;
      justify-content: center;

      @media only screen and (min-width: ${xsMinWidth}px) {
        display: flex;
      }

      img {
        height: 35px;
      }
    }
  }

  .instruction {
    padding: ${spaceLarge}px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    position: relative;

    .desktop {
      display: none;

      @media only screen and (min-width: ${mdMinWidth}px) {
        display: inline-block;
      }
    }

    .mobile {
      display: inline-block;

      @media only screen and (min-width: ${mdMinWidth}px) {
        display: none;
      }
    }

    .close {
      position: absolute;
      top: ${space}px;
      right: ${space}px;
      font-size: 30px;
      transform: rotate(45deg);
      cursor: pointer;
    }

    p,
    ol {
      list-style-position: outside;
      margin-bottom: ${space}px;
    }

    p:last-child {
      margin: 0;
    }

    li,
    ol p {
      margin-bottom: ${spaceSmall}px;
    }

    span {
      color: ${primary2};
      cursor: pointer;
    }

    .date {
      /* color: ${red}; */
    }
  }

  .video {
    display: none;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    @media only screen and (min-width: ${xsMinWidth}px) {
      display: block;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
