import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Constants */
import { completeOverview } from '../constants/routes';

/* Components */
//import DeptFormWrapper from '../components/dept-form';
import DeptFormWrapper from '../components/dept-form/CustomerDebtForm';

/* Objects */
import Divider from '../objects/divider';

/* Redux */
import { resetValues } from '../redux/actions/dept-form';

const DeptForm = ({ dispatch, history, isSubmitted }) => {
  if (isSubmitted) {
    // GA tracking pageviews
    ReactGA.pageview(`${window.location.pathname}-complete${window.location.search}`);

    // Redirect after submit
    setTimeout(() => {
      dispatch(resetValues({ submit: false }));
      history.push(completeOverview);
    }, 2000);
  }

  return (
    <>
      {/* <h2>Schuldenformulier</h2> */}
      <h2>Overzicht financiële situatie</h2>
      <Divider />
      {/* <p className="lead light smallFontDark">Het is belangrijk dat wij een goed overzicht hebben van de financiële positie van je bedrijf. Dit om op een verantwoordelijke wijze een financiering te verstrekken.</p> */}
      {/* <div className="mt-40"> */}
      <div className="mt-20">
        <DeptFormWrapper />
      </div>
      {/* <Footer /> */}
    </>
  );
};

DeptForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isSubmitted: PropTypes.bool,
};

DeptForm.defaultProps = {
  isSubmitted: false,
};

export default connect((state) => ({
  isSubmitted: state.deptForm.submit,
}))(DeptForm);
