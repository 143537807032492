import styled from 'styled-components';

/* Constants */
import { xsMinWidth, tabMaxWidth } from '../../constants/breakpoints';
import { spaceLarge, headerHeight } from '../../constants/spaces';
import { field, stepsSideBarBackground } from '../../constants/colors';

/* Utils */
import zindex from '../../utils/zindex';

export default styled.div`
  width: 100%;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
  background-color: #1E2749;
  border-bottom: 1px solid ${field};
  position: relative;
  z-index: ${zindex('header')};

  .logo {
    width: 145px;
    margin-left: ${spaceLarge}px;
    display: block;

    @media only screen and (min-width: ${xsMinWidth}px) {
      width: 137px;
    }
  }

  .menu {
    height: 100%;
    width:320px;
    margin-left: auto;
    //margin-right: ${(props) => props.marginRight}%
    text-align:center;
  }

  .menu {
    ul {
      width:100%;
      text-align:center;
      padding:0;

      li{
        margin-left:auto;
        margin-right:auto;
      }
    }
  }


  @media only screen and (max-width: ${tabMaxWidth}px) {
    .menu {
      ul{
         width: 80%;
          float: right;
          li {
            padding-left: 19%;
          }
        }
      }

    }
  }

  @media only screen and (max-width: 543px) {
    .menu {
      ul{
         width: 82%;
         li {
           width:100%;
         }
         }
      }

    }

    // .search{
    //   font-size: 39px;
    // }


  }

`;
