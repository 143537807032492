import React, {useEffect}  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useWindowDimensions } from '../../utils/browserWidth';

/* Style */
import StyledComponent from './style';

/* Constants */
import {
  completeMtFile, completeBtwResult, completeBankStatement,
  completeStakeholders, completeDeptForm, completePassport,
  completeFinancialState, completeCreditArg, completeWaiting,
  completeProposal, completeMtAndPsd2File
} from '../../constants/routes';

/* Objects */
import Card from '../../objects/card';

import {  requestPredefinedParties } from '../../redux/actions/dept-form';

const loopItems = (items, type) => {
  let html = null;
  if (items && items.length > 0) {
    if (type === 'todo' || type === 'waiting') {
      html = items.map((item, i) => (
        <li key={item.label}>
          <div className="info">
          <div className="itemIcon">
            <i className={`icmn-${item.icon || 'checkmark-round'}`} />
            </div>
            <div>
              <div className="label">{item.label}</div>
              <div className="description">
                {`Opnieuw aanleveren: ${item.feedback}`}
              </div>
            </div>
            {/* <a className="actionButtonMobile anchor" href={item.link}> </a> */}
            {
              item && (item.label === "Overzicht financiële situatie" || item.label === "Overzicht belanghebbende(n)") ?
                <a className="invullenBtn anchor" href={item.link}>Invullen</a>
                :
                <a className="uploadenBtn anchor" href={item.link}>Uploaden</a>
            }

          </div>
        </li>
      ));
    } else if(type === 'feedback') {
      html = items.map((item, i) => (
        <li key={item.label}>
          <div className="info">
            <div className="itemIcon">
            <i className={`icmn-${item.icon || 'checkmark-round'}`} />
            </div>
              <div className="labelAndDesWrapper">
              <div className="label">{item.label}</div>
              <div className="description lightColor">
                {`Opnieuw aanleveren: ${item.feedback}`}
              </div>
              </div>
            </div>
            {/* <a className="actionButtonMobile anchor" href={item.link}> </a> */}
            <a className="uploadenBtn anchor" href={item.link}>Uploaden</a>

        </li>
      ));
    }else {
      html = items.map((item, i) => (
        <li key={item.label}>
          {/* <Link to={item.link}> */}
          <i className={`icmn-${item.icon || 'checkmark-round'}`} />
           <div className="info">
              <div>
              <div className="label">{item.label}</div>
              <div className="description">
                {`Opnieuw aanleveren: ${item.feedback}`}
              </div>
              </div>
            </div>
          {/* </Link> */}
        </li>
      ));
    }
  }
  return (
    <ul>{html}</ul>
  );
};

const loopItemsMobile = (items, type) => {
  let html = null;
  if (items && items.length > 0) {
    if (type === 'todo' || type === 'waiting') {
      html = items.map((item, i) => (
        <li key={item.label}>
          <Link to={item.link}>
            <div className="info">
            <div className="itemIcon">
              <i className={`icmn-${item.icon || 'checkmark-round'}`} />
              </div>
              <div>
                <div className="label">{item.label}</div>
                <div className="description">
                  {`Opnieuw aanleveren: ${item.feedback}`}
                </div>
              </div>
              <a className="actionButtonMobile anchor" href={item.link}> </a>
            </div>
          </Link>
        </li>
      ));
    } else if (type === 'feedback') {
      html = items.map((item, i) => (
        <li key={item.label}>
          <Link to={item.link}>
            <div className="info">
              <div className="itemIcon">
              <i className={`icmn-${item.icon || 'checkmark-round'}`} />
              </div>
                <div className="mobileFeedback">
                  <div className="label">{item.label}</div>
                  <div className="description lightColor">
                    {`Opnieuw aanleveren: ${item.feedback}`}
                  </div>
                </div>
              </div>
              <a className="actionButtonMobile anchor" href={item.link}> </a>
          </Link>
        </li>
      ));
    }else {
      html = items.map((item, i) => (
        <li key={item.label}>
          {/* <Link to={item.link}> */}
            <i className={`icmn-${item.icon || 'checkmark-round'}`} />
            <div className="info">
                <div>
                <div className="label">{item.label}</div>
                <div className="description">
                  {`Opnieuw aanleveren: ${item.feedback}`}
                </div>
                </div>
              </div>
          {/* </Link> */}
        </li>
      ));
    }
  }
  return (
    <ul>{html}</ul>
  );
};

export const SubmitStatus = ({ documents, proposal, dispatch }) => {
  const todo = [];
  const waiting = [];
  const feedback = [];
  const done = [];
  const needed = Object.values(documents).filter((doc) => doc.needed === '1');
  const verified = Object.values(documents).filter((doc) => doc.geverifieerd === 'Geverifieerd');
  const { height, width } = useWindowDimensions(); // eslint-disable-line
  const items = [

    {
      // slug: 'kvk_result',
      label: 'Financieringsbehoefte',
      time: 3,
      link: '/',
      position: 1,
    },
    {
      // slug: 'kvk_result',
      label: 'Bedrijfs- en contactgegevens',
      time: 3,
      link: '/',
      position: 2,
    },
    {
      slug: 'mt_result',
      label: 'Banktransacties',
      icon: 'finance-report',
      time: 2,
      link: completeMtAndPsd2File,
      position: 3,
    },
    {
      slug: 'id_result',
      label: 'Kopie van je paspoort of ID-bewijs',
      icon: 'drivers-license',
      time: 2,
      link: completePassport,
      position: 4,
    },
    {
      slug: 'btw_result',
      label: 'Kopie van je BTW aangifte',
      icon: 'finance-report',
      time: 2,
      link: completeBtwResult,
      position: 5,
    },
    {
      slug: 'screenshot_result',
      label: 'Recente foto van je zakelijke bankrekening(en)',
      icon: 'receipt',
      time: 2,
      link: completeBankStatement,
      position: 6,
    },
    {
      slug: 'ubo_result',
      label: 'Overzicht belanghebbende(n)',
      icon: 'sitemap',
      time: 4,
      link: completeStakeholders,
      position: 7,
    },
    {
      slug: 'schulden_result',
      label: 'Overzicht financiële situatie', //Schuldenformulier
      icon: 'list',
      time: 7,
      link: completeDeptForm,
      position: 8,
    },
    {
      slug: 'jaarrekening_result',
      label: 'Jaarrekening',
      icon: 'invoice',
      time: 2,
      link: completeFinancialState,
      position: 9,
    },
    {
      slug: 'overeenkomsten_result',
      label: 'Kredietovereenkomst',
      icon: 'contract',
      time: 2,
      link: completeCreditArg,
      position: 10,
    },
    {
      show: proposal.quotation_loan_amount !== '' && proposal.maximum_loan_amount !== '' && proposal.maximum_loan_time !== '' && proposal.interest_increment !== '',
      label: 'Stel je lening samen',
      icon: 'sliders',
      time: 3,
      link: completeProposal,
      position: 11,
    },
  ];

  items.forEach((item) => {
    let newPosition = 0;
    // When show 'false' hide
    if (typeof item.show !== 'undefined' && !item.show) return;

    // When show 'true' show item in todo
    if (item.show) {
      todo.push(item);
      return;
    }

    // When no slug alway done
    if (!item.slug || item.show) {
      done.push(item);
      return;
    }

    const document = documents[item.slug];
    const newItem = item;

    // Done
    if (
      document
      && document.geverifieerd === 'Geverifieerd'
    ) {
      if (done.length > 0) {
        done.forEach((doneItem, index) => {
          if (newItem.position > done[(done.length - 1)].position) {
            newPosition = null;
          } else if (newItem.position < doneItem.position) {
            newPosition = index;
          }
        });
      }
      if (newPosition === null) {
        done.push(newItem);
      } else {
        done.splice((newPosition + 1), 0, newItem);
      }
      return;
    }

    // Waiting
    if (
      document
      && (
        document.verstuurd === '1'
        || document.ontvangen === '1'
      )
    ) {
      if (done.length > 0) {
        done.forEach((doneItem, index) => {
          if (newItem.position > done[(done.length - 1)].position) {
            newPosition = null;
          } else if (newItem.position < doneItem.position) {
            newPosition = index;
          }
        });
      }
      if (newPosition === null) {
        done.push(newItem);
      } else {
        done.splice((newPosition + 1), 0, newItem);
      }
      return;
    }

    // Feedback
    if (
      document
      && document.reden_afgekeurd
      && document.reden_afgekeurd !== ''
    ) {
      newItem.feedback = document.reden_afgekeurd;
      feedback.push(newItem);
      return;
    }

    // Todo
    if (
      document
      && document.needed === '1'
    ) {
      todo.push(newItem);
    }
  });

  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "party-names-loans",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);
  
  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "party-names-lease-contracts",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);
  
  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "party-names-payment-backlogs",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);
  
  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "type-of-lease-object",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);

  return (
    <StyledComponent>
      {((todo && todo.length === 0) && (feedback && feedback.length === 0)) &&
        <div className="mb-40">
        <Card image={`${process.env.PUBLIC_URL}/images/icons/thumbs-up.png`} >
        <p>
          <strong>
            {
               'Super! Je aanvraag is helemaal compleet.'
            }
          </strong>
          <br />
          {
             'Onze kredietanalisten doen nu een laatste check. Het kan zijn dat wij nog enkele vragen hebben. Wij nemen in ieder geval zo snel mogelijk contact met je op.'
          }
        </p>
      </Card>
      </div>
      }
      {
        needed.length === verified.length
        && (
          <>
            <Card icon="👏">
              <p>
                <strong>Je dossier is helemaal compleet!</strong>
                <br />
                <span>Je hoeft op dit moment geen documenten meer te uploaden. We gaan alle stukken beoordelen en komen snel bij je terug met een definitieve uitslag. Doorgaans hoor je op werkdagen binnen twee uur van ons.</span>
              </p>
            </Card>
            <ul className="complete mt-20">
              <li>
                <Link to={completeWaiting}>
                  <i className="icmn-lamp" />
                  <div className="info">
                    <div className="label">Bekijk interessante ondernemersverhalen</div>
                  </div>
                </Link>
              </li>
            </ul>
          </>
        )
      }
      {/* {
        feedback.length > 0 && (
          <div className="feedback">
            <p className="lead">Opnieuw verzenden</p>
            {loopItems(feedback)}
          </div>
        )
      } */}

      {
        (todo.length > 0 || done.length > 0) && (
          <div className="todo">
            <div className="leadWrapper">
            {((todo && todo.length === 0) && (feedback && feedback.length === 0)) ?
            <p className="lead">Je aanvraag is compleet</p>
            :
            <p className="lead completerText">Maak je aanvraag compleet</p>
          }

              <div className="leapHeadRight">
                <img alt="" id="stepStatusIcon" src={todo.length < 1 ? "/images/icons/check-mobile.svg" : "/images/icons/red.svg"} />
                <p id="numberOfActions">{(todo.length + feedback.length)} openstaande acties</p>
                <p id="numberOfActionsMobile">{(todo.length + feedback.length)} acties</p>
              </div>
            </div>

            {done && done.length > 0 &&
              (<div className="done">
                {width <= 520 ? loopItemsMobile(done) : loopItems(done)}
              </div>)
            }
            {feedback && feedback.length > 0 &&
              (<div className="feedback">
                {width <= 520 ? loopItemsMobile(feedback, 'feedback') : loopItems(feedback, 'feedback')}
              </div>)
            }
            {todo && todo.length > 0 &&
              (
                <div className="todoList">
                  {width <= 520 ? loopItemsMobile(todo, 'todo') : loopItems(todo, 'todo')}
                </div>
              )
            }
            {waiting && waiting.length > 0 &&
              (
                <div className="waiting">
                  {loopItems(waiting, 'waiting')}
                </div>
              )

            }

          </div>
        )
      }
      {/* {
        waiting.length > 0 && (
          <div className="waiting">
            <p className="lead">Verzonden ter verificatie</p>
            {loopItems(waiting)}
          </div>
        )
      } */}
      {/* {
        done.length > 0 && (
          <div className="done">
            <p className="lead">Verzonden en geverifieerd</p>
            {loopItems(done)}
          </div>
        )
      } */}
    </StyledComponent>
  );
};

SubmitStatus.propTypes = {
  documents: PropTypes.shape({}).isRequired,
  proposal: PropTypes.shape({
    quotation_loan_amount: PropTypes.string,
    maximum_loan_amount: PropTypes.string,
    maximum_loan_time: PropTypes.string,
    interest_increment: PropTypes.string,
  }).isRequired,
};

export default connect((state) => ({
  documents: state.opportunity.documents,
  proposal: state.opportunity.proposal,
}))(SubmitStatus);
