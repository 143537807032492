export const SET_DIALOG = 'SET_DIALOG';
export const TOGGLE_DIALOG = 'TOGGLE_DIALOG';

export const toggleDialog = (open) => (dispatch) => {
  dispatch({
    type: TOGGLE_DIALOG,
    open,
  });
};

export const openDialog = (children) => {
  if (!children) return false;

  return (dispatch, store) => {
    const { dialog } = store();

    if (dialog.open) {
      return;
    }

    dispatch({
      type: SET_DIALOG,
      children,
    });

    setTimeout(() => {
      dispatch(toggleDialog(true));
    }, 50);
  };
};

export const closeDialog = () => (dispatch) => {
  dispatch(toggleDialog(false));

  setTimeout(() => {
    dispatch({
      type: SET_DIALOG,
      children: null,
    });
  }, 150);
};
