import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Radio from '../../../objects/radio';
import File from '../../../objects/file';

/* Components */
import DuplicateForm from '../../duplicate-form';

/* Redux */
import { sendFiles } from '../../../redux/actions/complete';
import { setValues, nextPage, prevPage } from '../../../redux/actions/dept-form';

const Debtors = ({ dispatch, defaultValues }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues });
  const {
    handleSubmit, formState, triggerValidation, watch,
  } = formMethods;
  const [files, setFiles] = useState([]);
  const debtor = watch('debtor');
  const options = [
    { label: 'Ja' },
    { label: 'Nee' },
    { label: 'Ja, ik wil mijn debiteurenlijst uploaden' },
  ];
  const fields = [
    {
      label: 'Naam debiteur',
      name: 'debtorName',
      required: true,
    },
    {
      label: 'Openstaand bedrag',
      name: 'debtorAmount',
      required: true,
    },
    {
      label: 'Aantal dagen oud',
      name: 'debtorDays',
      type: 'select',
      options: [
        { label: 'Minder dan 30 dagen' },
        { label: '60 dagen' },
        { label: 'Meer dan 90 dagen' },
      ],
      required: true,
    },
  ];

  // Fix validating defaultValues
  useEffect(() => {
    triggerValidation();
  }, [triggerValidation]);

  const handleFiles = (f) => {
    setFiles(f);
  };

  const setFormData = (data) => {
    dispatch(setValues(data));
    dispatch(nextPage());
  };

  const onSubmit = (data) => {
    if (files.length === 0) return setFormData(data);
    dispatch(sendFiles('DEBITLIST', files)).then(() => {
      setFormData(data);
    });
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="bold">Heb je op dit moment openstaande facturen waarvan je de betaling nog moet ontvangen?</p>
        <div className="mt-40">
          <Radio
            name="debtor"
            options={options}
            required
          />
        </div>
        {
          debtor === options[0].label
          && (
            <div className="mt-40">
              <p>{'Vul onderstaand de openstaande facturen in die je nog betaald moet krijgen en hoeveel dagen deze factuur reeds open staat (<30, 60 of meer dan >90)'}</p>
              <div className="mt-20">
                <DuplicateForm
                  fields={fields}
                  prefix="debtors"
                  defaultRows={defaultValues.debtors && defaultValues.debtors.length}
                  addMoreLabel="Nog een debiteur toevoegen"
                />
              </div>
            </div>
          )
        }
        {
          debtor === options[2].label
          && (
            <div className="mt-40">
              <File
                name="debetFiles"
                label="Selecteer je debiteurenlijst"
                labelActive="Selecteer nog een debiteurenlijst"
                onChange={handleFiles}
                accept=".jpg,.jpeg,.png,.pdf,xlsx"
                allowedExtensions={/(\.jpg|\.jpeg|\.png|\.pdf|\.xlsx)$/i}
                required
              />
            </div>
          )
        }
        <div className="mt-40 inDesktop">
          <Grid>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Debtors.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  defaultValues: PropTypes.shape({
    debtors: PropTypes.array,
  }).isRequired,
};

Debtors.defaultProps = {
  loading: false,
};

export default connect((state) => ({
  loading: state.loading.complete,
  defaultValues: state.deptForm,
}))(Debtors);
