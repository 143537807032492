import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import moment from 'moment';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Checkbox from '../../../objects/checkbox';
import Input from '../../../objects/input';

/* Redux */
import { setValues, prevPage, submit } from '../../../redux/actions/ubo-form';

const Sign = ({ dispatch, loading, isSubmitted }) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      date: moment().format(),
    },
  });
  const { handleSubmit, formState } = formMethods;

  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(submit());
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>Deze verklaring moet worden ondertekend door minimaal één bevoegde (wettelijke) vertegenwoordiger en geldt dat deze verklaring wordt afgegeven en ondertekend namens de rechtspersoon.</p>
        <p className="bold mt-40">Door onderstaande te ondertekenen verklaar ik dat:</p>
        <div className="mt-20">
          <Checkbox
            name="signTruth"
            label="Ik dit formulier naar waarheid heb ingevuld"
            fontSize={17}
            required
          />
        </div>
        <div className="mt-20">
          <Checkbox
            name="signChanges"
            label="Ik wijzigingen in voorgaande gegevens doorgeef aan BridgeFund B.V."
            fontSize={17}
            required
          />
        </div>
        <div className="mt-40">
          <p className="bold light">Ondertekend door*</p>
          <div className="mt-20">
            <Grid columns={2}>
              <Input
                name="firstName"
                label="Voornaam"
                required
              />
              <Input
                name="lastName"
                label="Achternaam"
                required
              />
              <Input
                name="city"
                label="Plaats"
                required
              />
              <Input
                name="date"
                label="Datum"
                required
              />
            </Grid>
          </div>
        </div>
        <div className="mt-40 inDesktop">
          <Grid>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
            <Button
              type="submit"
              width="100%"
              loading={loading}
              success={isSubmitted}
              disabled={!formState.isValid}
            >
              Akkoord en verzenden
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              loading={loading}
              success={isSubmitted}
              disabled={!formState.isValid}
            >
              Akkoord en verzenden
            </Button>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Sign.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

Sign.defaultProps = {
  loading: false,
  isSubmitted: false,
};

export default connect((state) => ({
  loading: state.loading.uboForm,
  isSubmitted: state.uboForm.submit,
}))(Sign);
