import styled from 'styled-components';

/* Utils */
import zindex from '../../utils/zindex';

export default styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zindex('fullScreenLoader')};
  display: flex;
  justify-content: center;
  background-color: white;

  img {
    width: 60px;
  }
`;
