import {
  SET_REQUEST_VALUES,
  SET_CONCENT_DETAILS,
  SET_LOADING,
  SET_PSD_REVISION_STATUS,
  SET_PSD_ACCOUNT_SHORT,
  SET_CONCENT_INFORMATION,
  SET_CONCENT_RENEWAL_STATE,
  PSD_COMPLETED,
  SET_CONCENT_STATUS,
  SET_RISK_ANLYSIS_SEQUENCE_NUMBER,
  RESET_STATUS_VALUES
} from '../actions/request';

const INITIAL_STATE = JSON.parse(sessionStorage.getItem('request')) || {};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_REQUEST_VALUES:
      return {
        ...state,
        ...action.values,
      };
    case SET_LOADING:  
      return {
        ...state,
        dataLoading : action.value,
      };
    case SET_CONCENT_DETAILS:
        return {
          ...state,
          noConcent : action.noConcent,
        }; 
    case SET_PSD_REVISION_STATUS:  
        return {
          ...state,
          revisionPsd2Status : action.valueObj.PSD2Loan,
          psd2CustomerId: action.valueObj.customerId,
          activeWorkflowFound : action.valueObj.activeWorkflowFound
        };  
    case SET_PSD_ACCOUNT_SHORT:   
        return {
          ...state,
          psd2Accounts : action.psd2Accounts,
        };  
    case SET_CONCENT_INFORMATION:
        return {
          ...state,
          concentInfomation : action.concentInfomation
        }  
    case SET_CONCENT_RENEWAL_STATE:   
        return {
          ...state,
          allConcentsRenewed : action.allConcentsRenewed
        }   
    case PSD_COMPLETED: 
        return {
          ...state,
          psdCompleted : action.value
        } 
    case SET_CONCENT_STATUS:  
      return {
        ...state,
        concentStatus : action.status
      }  
    case SET_RISK_ANLYSIS_SEQUENCE_NUMBER:
      return {
        ...state,
        riskAnlysisSequence : action.sequenceNumber
      }  
    case RESET_STATUS_VALUES:  
      return {
        ...state,
        psd2Accounts : [],
        riskAnlysisSequence : 0
      }    
    default:
      return state;
  }
}
