import styled from 'styled-components';

/* Constants */
import { space, spaceLarge } from '../../constants/spaces';
import { xsMinWidth } from '../../constants/breakpoints';
import { stepsSideBarBackground} from '../../constants/colors';

/* Utils */
import zindex from '../../utils/zindex';

export default styled.div`
  width: 100%;
  position: relative;
  padding: ${space}px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: ${stepsSideBarBackground};
  z-index: ${zindex('card')};
  font-size: 14px;

  .iconWrapper{
    width:100%;
    text-align:center;
  }

  @media only screen and (min-width: ${xsMinWidth}px) {
    display: inline-block;
    align-items: center;
  }

  .icon {
    font-size: 40px;
    margin: 5px auto ${space}px auto;
    text-align: center;
    padding-right: 28px;

    @media only screen and (min-width: ${xsMinWidth}px) {
      margin: 0 auto ${space}px auto;
    }
  }

  .image {
    height: 40px;
    display: table;
    margin: 5px auto 8px auto;
    text-align: center;
    padding-right: 28px;

    @media only screen and (min-width: ${xsMinWidth}px) {
      margin: 0 auto 8px auto;
    }

    @media only screen and (max-width: 425px) {
      padding-right: 0;
    }
  }

  .children{
    font-size:16px;
  
    p{
      font-weight:400;
      font-size:12px;
      margin-left: 12px;

      strong{
        font-weight:700;
        font-size:16px;
      }
    }
  }

`;
