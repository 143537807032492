import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';
import { gray } from '../../constants/colors';
import { xsMinWidth } from '../../constants/breakpoints';

export default styled.div`
  &.hide {
    display: none;
  }

  .top {
    @media only screen and (min-width: ${xsMinWidth}px) {
      padding: 0 ${space}px;
    }
  }

  .loading {
    width: 100%;
    height: 40px;
    padding: 0 ${space}px;
    background-image: url(${process.env.PUBLIC_URL}/images/loader-dots-gray.svg);
    background-size: 40px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .no-results {
    color: ${gray};

    @media only screen and (min-width: ${xsMinWidth}px) {
      padding: 0 ${space}px;
    }

    p {

    }
  }

  .results {
    ul {
      padding: 0;
    }

    li {
      height: 60px;
      line-height: 60px;
      border-radius: 5px;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      cursor: pointer;

      @media only screen and (min-width: ${xsMinWidth}px) {
        padding: 0 ${space}px;
        height: 40px;
        line-height: 40px;
      }

      &.active,
      &:hover {
        background-color: #f2f2f2;
      }

      .name {
        flex-grow: 1;
        padding-right: ${space}px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .postalcode {
        font-weight: 400;
        color: ${gray};
      }
    }
  }
`;
