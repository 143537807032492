import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

export const SuccessManager = ({ manager }) => {
  const managers = {
    niekpunte:{
      name: 'Niek Punte',
      phone: '085-4016615',
      email: 'niek@bridgefund.nl',
      whatsapp: '06-14487415',
      image: 'niekpunte.png',
    },
    daniquevanbueren: {
      name: 'Danique',
      phone: '020-2442507',
      email: 'danique@bridgefund.nl',
      image: 'daniquevanbueren.png',
    },
    frisovanderputten: {
      name: 'Friso van der Putten',
      phone: '085-4016611',
      email: 'friso@bridgefund.nl',
      whatsapp: '06-14721957',
      image: 'frisovanderputten.png',
    },
    olliverfandino: {
      name: 'Olliver',
      phone: '020-2442504',
      email: 'olliver@bridgefund.nl',
      image: 'olliverfandino.png',
    },
    twansiegers: {
      name: 'Twan',
      phone: '020-2442503',
      email: 'twan@bridgefund.nl',
      whatsapp: '06-14727913',
      image: 'twansiegers.png',
    },
    timbleeker: {
      name: 'Tim Bleeker',
      phone: '085-4016612',
      email: 'tim@bridgefund.nl',
      whatsapp: '06-14728700',
      image: 'timbleeker.png',
    },
    isabellemeijster: {
      name: 'Isabelle Meijster',
      phone: '085-4016613',
      email: 'isabelle@bridgefund.nl',
      whatsapp: '06-34530192',
      image: 'isabellemeijster.png',
    },
    pieterblom: {
      name: 'Pieter Blom',
      phone: '085-4016614',
      email: 'pieter@bridgefund.nl',
      whatsapp: '06-14727913',
      image: 'pieterblom.png',
    },
    martijnkolenbrander: {
      name: 'Martijn Kolenbrander',
      phone: '085-4016617',
      email: 'martijn@bridgefund.nl',
      whatsapp: '06-14721957',
      image: 'martijnkolenbrander.png',
    },
    rubenbauwens: {
      name: 'Ruben Bauwens',
      phone: '085-4016616',
      email: 'ruben@bridgefund.nl',
      whatsapp: '06-34530192',
      image: 'rubenbauwens.png',
    },
    'debbybotman-haakma': {
      name: 'Debby Botman-Haakma',
      phone: '085-4016619',
      email: 'debby@bridgefund.nl',
      whatsapp: '06-14487415',
      image: 'no-img',
    },
    no_csm: {
      phone: '085-4016600',
      email: 'contact@bridgefund.nl',
      image: 'ted_no_csm.png',
    }
  };
  let info = managers[manager];
  if (!info) info = managers.no_csm;

  return (
    <StyledComponent>
      {
      info.image && 
        <img src={`${process.env.PUBLIC_URL}/images/success-managers/${info.image === 'no-img' ? 'ted_no_csm.png' : info.image}`} alt="success-manager" className="avatar" />
      }

      {
        info.name && 
        <>
          <p className="name bolder">{info.name}</p>
          <p className="title titleSuccessManager">Jouw persoonlijke</p>
          <p className="title titleSuccessManager">Customer Success Manager</p>
        </>
        
      }
      
      <p className="questions">
        <strong className="vragen">Vragen?</strong>
        <br />
        Op werkdagen te bereiken via:
        <br />
        <a href={`tel:${info.phone}`}>{info.phone}</a>
        {' of per e-mail via'}
        <br />
        <a className="mailTo" href={`mailto:${info.email}`}>{info.email}</a>
      </p>
      {
        info.whatsapp
        && (
          <p className="whatsapp">
            <a
              href={`https://wa.me/0031${info.whatsapp.replace('-', '')}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icmn-whatsapp" />
              WhatsApp
            </a>
          </p>
        )
      }
    </StyledComponent>
  );
};

SuccessManager.propTypes = {
  manager: PropTypes.oneOf([
    'daniquevanbueren',
    'frisovanderputten',
    'niekpunte',
    'olliverfandino',
    'twansiegers',
    'timbleeker',
    'isabellemeijster',
    'pieterblom',
    'martijnkolenbrander',
    'rubenbauwens',
    'debbybotman-haakma'
  ]).isRequired,
};

export default connect((state) => ({
  manager: state.opportunity.manager,
}))(SuccessManager);
