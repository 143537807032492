export default `
  h1 {
    font-size: 40px;
    font-weight: normal;
  }

  h2 {
    font-size: 30px;
    font-weight: normal;
  }

  h3 {
    font-size: 26px;
    font-weight: normal;
    line-height: 1.31;
  }
`;
