import {
  TOGGLE_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  CLEAR_COMPANIES,
  SET_KVK_API_AVAILABILITY
} from '../actions/companies';

const INITIAL_STATE = {
  items: [],
  coc_api_available: false
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_COMPANIES:
      return {
        ...state,
        show: action.show,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        totalItems: action.totalItems,
        items: action.items,
      };
    case CLEAR_COMPANIES:
      return {
        ...state,
        totalItems: 0,
        items: [],
      };
    case SET_KVK_API_AVAILABILITY:  
      return {
        ...state,
        coc_api_available: action.avilable
      };
    default:
      return state;
  }
}
