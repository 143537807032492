import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Trustpilot = ({ type, width }) => {
  const trustBoxRef = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, [trustBoxRef]);

  if (type === 'mini') {
    return (
      <StyledComponent>
        <div
          ref={trustBoxRef}
          className="trustpilot-widget"
          data-locale="nl-NL"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="5c8145bdf229920001fc67eb"
          data-style-height="110px"
          data-style-width={width}
          data-theme="light"
        >
          <a
            href="https://nl.trustpilot.com/review/bridgefund.nl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </StyledComponent>
    );
  }

  if (type === 'slider') return (
    <StyledComponent>
      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale="nl-NL"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="5c8145bdf229920001fc67eb"
        data-style-height="200px"
        data-style-width={width}
        data-theme="light"
        data-stars="3,4,5"
        data-schema-type="Organization"
      >
        <a
          href="https://nl.trustpilot.com/review/bridgefund.nl"
          target="_blank"
          rel="noopener noreferrer"
        >Trustpilot</a>
      </div>
    </StyledComponent>
  );
};

Trustpilot.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
};

Trustpilot.defaultProps = {
  type: 'mini',
  width: '100%',
};

export default Trustpilot;
