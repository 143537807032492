import { createGlobalStyle } from 'styled-components';

/* Styles */
import core from './core';
import headers from './headers';
import icomoonFont from './icomoon';
import list from './list';
import spacing from './spacing';
import text from './text';

export default createGlobalStyle`
  ${core}
  ${headers}
  ${icomoonFont}
  ${list}
  ${spacing}
  ${text}
`;
