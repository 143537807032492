import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Components */
import SearchResults from '../search-results';

/* Objects */
import Divider from '../../objects/divider';

/* Redux */
import { getCompaniesByQuery, clearCompanies } from '../../redux/actions/companies';
import { closeDialog } from '../../redux/actions/dialog';

export const FullScreenSearch = ({
  dispatch, loading, onSelect,
  suggestions, coc_api_available
}) => {
  const [query, setQuery] = useState('');
  const [show, setShow] = useState(false);
  const [typing, setTyping] = useState(false)

  const close = () => {
    dispatch(closeDialog());
  };

  // After the search query is set
  useEffect(() => {
    if (query.length < 3) {
      dispatch(clearCompanies());
      return;
    }

    const timer = setTimeout(() => {
      dispatch(getCompaniesByQuery(query));
      setShow(true);
    }, 300);
    return () => clearTimeout(timer);
  }, [dispatch, query]);

  const handleChange = (e) => {
    const value = e.target.value || '';
    setQuery(value);
    setTyping(true);
  };

  const handleSelect = (item) => {
    onSelect(item);
    dispatch(closeDialog());
  };


  const sendCompanyName = () => {
   
    const company = {
      kvkNummer: "70304580",
      handelsnaam: `NO KVK - ${query}`,
      straatnaam: "Amsteldijk",
      plaats: "Amsterdam",
      type: "hoofdvestiging",
      postalCode : "1079 LK",
      houseNumber : "216"
      
    }

    onSelect(company);
    dispatch(closeDialog());
  }

  return (
    <StyledComponent>
      <div className="container">
        <div className="close" onClick={close}>
          <i className="icmn-plus-round" />
        </div>
        {
          (!coc_api_available && typing) &&
          <div id="buttonHolder">
            <button id="sendButton" onClick={sendCompanyName}>Volgende</button>
          </div>
        }
        <input
          type="text"
          value={query}
          placeholder="Doorzoek de KVK"
          onChange={handleChange}
          autoFocus
        />
        <Divider width="100%" />
        <SearchResults
          show={show}
          loading={loading}
          results={suggestions}
          onSelect={handleSelect}
          limit={10}
        />
      </div>
    </StyledComponent>
  );
};

FullScreenSearch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  coc_api_available: PropTypes.bool.isRequired
};

FullScreenSearch.defaultProps = {
  loading: false,
  suggestions: [],
  coc_api_available: false
};

export default connect((state) => ({
  loading: state.loading.companies,
  suggestions: state.companies.items,
  coc_api_available: state.companies.coc_api_available
}))(FullScreenSearch);
