import styled from 'styled-components';

/* Constants */
import { spaceLarge } from '../../constants/spaces';

export default styled.div`
  text-align: center;

  .icon {
    width: 230px;
    height: 230px;
    overflow: hidden;
    margin: 0 auto ${spaceLarge}px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-radius: 50%;

    img {
      width: 330px;
      max-width: none;
    }
  }

  .contact {
    line-height: 1.76;

    img {
      display: block;
      margin: 40px auto;
    }
  }
`;
