import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const Container = ({ children, width, padding, paddingRight }) => (
  <StyledComponent width={width} padding={padding} paddingRight={paddingRight}>
    {children}
  </StyledComponent>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  padding: PropTypes.number,
  paddingRight: PropTypes.number,
};

Container.defaultProps = {
  width: 1170,
  padding: 60,
  paddingRight: 20,
};

export default Container;
