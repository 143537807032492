import React from 'react';
import PropTypes from 'prop-types';

/* Components */
import Header from '../components/header';
import CallUs from '../components/call-us';
import MainMenu from '../components/main-menu';

import {
  newFinancialInfoUpdate,
} from '../constants/routes';
import { useRouteMatch, useLocation  } from 'react-router-dom';

const FinancialInfoReUpload = ({ children }) => {
  let pathArray = useLocation().pathname.split('/');
  let paramId = pathArray[pathArray.length - 1];
  let isFinancialInfoPage = !!useRouteMatch(newFinancialInfoUpdate);
  return (
    <>
      <Header>
      <CallUs />
        {!paramId.includes('SBF') && isFinancialInfoPage && <MainMenu />}
        
      </Header>
      {children}
    </>
  )
};

FinancialInfoReUpload.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FinancialInfoReUpload;
