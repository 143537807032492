const hash = 'r7nao';

export const icomoon = `
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export default `
  @font-face {
    font-family: 'icomoon';
    src:
      url('${process.env.PUBLIC_URL}/fonts/icomoon.ttf?${hash}') format('truetype'),
      url('${process.env.PUBLIC_URL}/fonts/icomoon.woff?${hash}') format('woff'),
      url('${process.env.PUBLIC_URL}/fonts/icomoon.svg?${hash}#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^='icmn-'], [class*=' icmn-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    ${icomoon}
  }

  .icmn-add-file:before {
    content: "\\e928";
  }
  .icmn-lamp:before {
    content: "\\e927";
  }
  .icmn-contract:before {
    content: "\\e924";
  }
  .icmn-invoice:before {
    content: "\\e925";
  }
  .icmn-sliders:before {
    content: "\\e926";
  }
  .icmn-play:before {
    content: "\\e921";
  }
  .icmn-mt940:before {
    content: "\\e922";
  }
  .icmn-other-files:before {
    content: "\\e923";
  }
  .icmn-hourglass:before {
    content: "\\e920";
  }
  .icmn-hands-up:before {
    content: "\\e91b";
  }
  .icmn-run:before {
    content: "\\e91c";
  }
  .icmn-bible:before {
    content: "\\e91d";
  }
  .icmn-percentage-tag:before {
    content: "\\e91e";
  }
  .icmn-finish:before {
    content: "\\e91f";
  }
  .icmn-pay-off:before {
    content: "\\e911";
  }
  .icmn-all-in-one:before {
    content: "\\e912";
  }
  .icmn-stopwatch:before {
    content: "\\e913";
  }
  .icmn-donut-doc:before {
    content: "\\e914";
  }
  .icmn-lowercase:before {
    content: "\\e915";
  }
  .icmn-lend:before {
    content: "\\e916";
  }
  .icmn-calendar:before {
    content: "\\e917";
  }
  .icmn-chat:before {
    content: "\\e918";
  }
  .icmn-choice:before {
    content: "\\e919";
  }
  .icmn-plus-round:before {
    content: "\\e91a";
  }
  .icmn-manual:before {
    content: "\\e910";
  }
  .icmn-sitemap:before {
    content: "\\e90e";
  }
  .icmn-list:before {
    content: "\\e90f";
  }
  .icmn-whatsapp:before {
    content: "\\e90a";
  }
  .icmn-drivers-license:before {
    content: "\\e90b";
  }
  .icmn-finance-report:before {
    content: "\\e90c";
  }
  .icmn-receipt:before {
    content: "\\e90d";
  }
  .icmn-caret:before {
    content: "\\e909";
  }
  .icmn-checkbox:before {
    content: "\\e901";
  }
  .icmn-abandon:before {
    content: "\\e900";
  }
  .icmn-checkmark-round:before {
    content: "\\e902";
  }
  .icmn-dove:before {
    content: "\\e903";
  }
  .icmn-encryption:before {
    content: "\\e904";
  }
  .icmn-high-five:before {
    content: "\\e905";
  }
  .icmn-lock:before {
    content: "\\e906";
  }
  .icmn-notification:before {
    content: "\\e907";
  }
  .icmn-thumbs-up:before {
    content: "\\e908";
  }
`;
