import styled from 'styled-components';

/* Constants */
import { blueGradientStart, blueGradientEnd } from '../../constants/colors';

export default styled.div`
  width: ${(props) => props.width};
  height: 2px;
  margin: 20px 0;
  background: linear-gradient(to right, ${blueGradientStart}, ${blueGradientEnd});

  &.center {
    margin: 20px auto;
  }

  &.right {
    margin: 20px 0 20px auto;
  }
`;
