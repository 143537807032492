import styled from 'styled-components';

/* Utils */
import zindex from '../../utils/zindex';

export default styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: white;
  z-index: ${zindex('dialog')};
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease-out;
  will-change: opacity;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
`;
