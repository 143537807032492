import styled from 'styled-components';

/* Constants */
import { blueGradientStart, blueGradientEnd } from '../../constants/colors';

export default styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  background: #f2f2f2;
  position: relative;

  & > div {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    background: linear-gradient(to right, ${blueGradientStart} 0%, ${blueGradientEnd} 100%);
    transition: width 300ms ease-in-out;
  }
`;
