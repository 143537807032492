import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

const ContainerPercentage = ({ children, width }) => (
  <StyledComponent width={width}>
    {children}
  </StyledComponent>
);

ContainerPercentage.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
};

ContainerPercentage.defaultProps = {
  width: '1170px',
};

export default ContainerPercentage;
