/* Actions */
export const TOGGLE_LOADING = 'TOGGLE_LOADING';

export const toggleLoading = (prop, bool) => {
  if (!prop) return false;

  return (dispatch, store) => {
    const { app } = store();
    const newBool = typeof bool !== 'undefined' ? bool : !app[prop];

    dispatch({
      type: TOGGLE_LOADING,
      bool: newBool,
      prop,
    });
  };
};
