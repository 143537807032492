import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* Style */
import StyledComponent from './style';

const TextBalloon = ({
  children, show, width,
  position, align, hide,
}) => {
  const [showIt, setShowIt] = useState(show);
  const componentClasses = classNames({
    show: showIt,
    [position]: position,
    [align]: align,
  });

  // Show it when show if true, or if the children are changed
  useEffect(() => {
    if (typeof show === 'undefined') return;
    setShowIt(show);
  }, [show, children]);

  // If hide is set, remove showIt after couple seconds
  useEffect(() => {
    if (hide <= 0) return;
    const timer = setTimeout(() => {
      setShowIt(false);
    }, hide);
    return () => clearTimeout(timer);
  }, [showIt, hide]);

  return (
    <StyledComponent
      className={componentClasses}
      width={width === 0 ? 'auto' : `${width}px`}
    >
      {children}
    </StyledComponent>
  );
};

TextBalloon.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  width: PropTypes.number,
  position: PropTypes.oneOf(['top', 'bottom']),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  hide: PropTypes.number,
};

TextBalloon.defaultProps = {
  show: false,
  width: 0,
  position: 'top',
  align: 'left',
  hide: 0,
};

export default TextBalloon;
