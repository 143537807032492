/* Actions */
export const TOGGLE_ERROR = 'TOGGLE_ERROR';
export const REPORT_ERROR = 'REPORT_ERROR';

export const toggleError = (prop, bool) => {
  if (!prop) return false;

  return (dispatch, store) => {
    const { app } = store();
    const newBool = typeof bool !== 'undefined' ? bool : !app[prop];

    dispatch({
      type: TOGGLE_ERROR,
      bool: newBool,
      prop,
    });
  };
};

export const reportError = (topic, bool) => {
  if (!topic) return false;

  return (dispatch, store) => {

    dispatch({
      type: REPORT_ERROR,
      value: bool,
      topic,
    });
  };
};