import {
  SET_UBOFORM_VALUES,
  RESET_UBOFORM_VALUES,
  SET_UBOFORM_PAGE,
} from '../actions/ubo-form';

const INITIAL_STATE = JSON.parse(localStorage.getItem('ubo-form')) || {};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_UBOFORM_VALUES:
      return {
        ...state,
        ...action.values,
      };
    case RESET_UBOFORM_VALUES:
      return {
        ...action.values,
      };
    case SET_UBOFORM_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      return state;
  }
}
