import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

/* Constants */
import { completeError } from '../constants/routes';

/* Objects */
import Grid from '../objects/grid';
import Container from '../objects/container';
import ContainerPercentage from '../objects/containerPercentage';

/* Components */
import Header from '../components/header';
import CompleteMenu from '../components/complete-menu';
import FullScreenLoader from '../components/full-screen-loader';
import SuccessManager from '../components/success-manager';

/* Redux */
import { setValues, loadPageData } from '../redux/actions/complete';

const Complete = ({
  dispatch, sidebar, error,
  loading, completeId, children,
}) => {
  const id = useParams().id || completeId; // 22803
  const colums = {
    mdMinWidth: '2.5fr 1fr',
  };

  useEffect(() => {
    dispatch(setValues({ id }));
    dispatch(loadPageData());
  }, [dispatch, id]);

  if (!id || error) {
    return <Redirect to={completeError} />;
  }

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Header marginRight={3.6}>
        <CompleteMenu />
      </Header>
      <Container width="auto" padding={0} paddingRight={0}>
        {
          sidebar
            ? (
              <Grid columns={colums} columnGap={60}>
                <ContainerPercentage width="75%">
                  <div>
                    {children}
                  </div>
                </ContainerPercentage>
                <div>
                  <SuccessManager />
                </div>
              </Grid>
            ) : (
              <>{children}</>
            )
        }
      </Container>
    </>
  );
};

Complete.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  completeId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Complete.defaultProps = {
  error: false,
  sidebar: false,
  loading: true,
  completeId: null,
};

export default connect((state) => ({
  error: state.error.opportunity,
  loading: state.loading.fullscreen,
  completeId: state.complete.id,
}))(Complete);
