import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';

/* Utils */
import zindex from '../../utils/zindex';

export default styled.div`
  width: ${(props) => props.width};
  max-width: 100%;
  display: block;
  position: absolute;
  z-index: ${zindex('textBalloon')};
  background: #fff;
  padding: ${space * 1.5}px ${space}px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  pointer-events: none;
  opacity: 0;
  will-change: transform, opacity;
  transition: opacity 300ms ease-out, transform 300ms ease-out;


  &.top {
    bottom: calc(100% + 24px);
    transform: translateY(10%);

    &:before {
      bottom: -10px;
      transform: rotate(45deg);
    }
  }

  &.bottom {
    top: calc(100% + 24px);
    transform: translateY(-10%);

    &:before {
      top: -10px;
      transform: rotate(-135deg);
    }
  }

  &.left {
    left: 0px;
  }

  &.right {
    right: 0;
  }

  &.center {
    left: 50%;
    transform: translateX(-50%);
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }

  &:before {
    content: "";
    position: absolute;
    left: 32px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #FFF #FFF transparent;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.05);
  }
`;
