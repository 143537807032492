import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Components */
import KnownFrom from '../known-from';
import UspGrid from '../usp-grid';
import Trustpilot from '../trustpilot';

/* Objects */
import Divider from '../../objects/divider';
import Button from '../../objects/button';

/* Redux */
import { closeDialog } from '../../redux/actions/dialog';

import {Telephone, Telephone_Link} from '../../constants/commons';

export const UspDialog = ({ dispatch }) => {
  const handleClick = () => {
    dispatch(closeDialog());
  };

  return (
    <StyledComponent>
      <div className="close">
        <Button onClick={handleClick} outline>
          Verder met aanvragen!
        </Button>
      </div>
      <img src={`${process.env.PUBLIC_URL}/images/new_logo_dark.svg`} alt="logo" className="logo" />
      <div className="mt-40">
        <KnownFrom fontSize={14} />
      </div>
      <h1 className="topHeading mt-80">3000+ ondernemers kozen al voor BridgeFund </h1>
      <Divider align="center" width="150px" />
      <div className="mt-40">
        <UspGrid />
      </div>
      <h1 className="topHeading mt-80">Andere ondernemers over BridgeFund</h1>
      <Divider align="center" width="150px" />
      <Trustpilot type="slider" />
      <h1 className="topHeading mt-80">Maak kennis met onze Customer Success Managers</h1>
      <Divider align="center" width="150px" />
      <div className="managers mt-40">
        <img src={`${process.env.PUBLIC_URL}/images/csm-2.png`} alt="contact" />
      </div>
      <p className="mt-40">
        <strong>Liever persoonlijk contact?</strong>
        <br />
        Bel met Isabelle of Pieter
        <br />
        <span>via <a href={Telephone_Link}>{Telephone}</a></span>
      </p>
      <div className="mt-40">
        <Button onClick={handleClick}>Ga verder met aanvragen!</Button>
      </div>
      <div className="mt-40 ">
        <p className="subheading light">
        BridgeFund B.V., Amsteldijk 216, 1079 LK Amsterdam, Nederland
        <br/>
        Geregistreerd bij de Kamer van Koophandel onder 70304580.
        </p>
      </div>
    </StyledComponent>
  );
};

UspDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(UspDialog);
