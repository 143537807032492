export const convertAmount = (amount) => {
    if(!amount) return '';
    
    if(amount.match('.')) amount = amount.replaceAll('.', '') // remove .

    if(amount.match(",")){
        let splittedAmount = amount.split(",");
        let formattedAmount = "";
        if(splittedAmount.length === 2){
            if(splittedAmount[1] === "00"){
                formattedAmount = splittedAmount[0];
            }else{

                formattedAmount = amount.replace(',', '.');
                
            }
        }else{
            console.log("invalid amount!");
            return amount;
        }
        return formattedAmount;
    }else{
        return amount;
    }
}
