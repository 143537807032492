import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Components */
import Trustpilot from '../trustpilot';
import UspDialog from '../usp-dialog';

/* Redux */
import { openDialog } from '../../redux/actions/dialog';

import {Telephone, Telephone_Link} from '../../constants/commons';

export const UspLinks = ({ dispatch, ssl }) => {
  const dialog = <UspDialog />;
  const partner = localStorage.getItem('partner');

  const handleClick = () => {
    dispatch(openDialog(dialog));
  };

  const renderUsps = () => {
    if (ssl) {
      return (
        <ul className="ssl mt-40">
          <li>Beveiligd verzenden via SSL</li>
          <li>Privacy gewaarborgd</li>
          <li>AVG Compliant</li>
          <li onClick={handleClick}>Bekijk alle pluspunten</li>
        </ul>
      );
    }

    return (
      <ul className="mt-40">
        <li className="uspLinks">{partner ? 'Aanbod binnen 24 uur' : 'Vandaag op je rekening'}</li>
        <li className="uspLinks">{partner ? 'Alles-in-1 tarief' : 'Korting bij sneller aflossen'}</li>
        <li className="uspLinks">{partner ? 'Géén rompslomp' : 'Vrijblijvend aanvragen'}</li>
        <li onClick={handleClick}>Bekijk alle pluspunten</li>
      </ul>
    );
  };

  return (
    <StyledComponent>
      <Trustpilot width="236px" />
      {renderUsps()}
      <div className="contact mt-40">
        {
          partner ?
          <img className="successMangerFrisco" src={`${process.env.PUBLIC_URL}/images/success-managers/frisovanderputten.png`} alt="contact" />
          :
          <img className="csmImages" src={`${process.env.PUBLIC_URL}/images/csm-2.png`} alt="contact" />
        }

        <p>
         Liever persoonlijk contact?
          {
            partner ? (
              <div>
                <a href={Telephone_Link}> Bel met Laurens, Friso, Tim of Isabelle via {Telephone}</a>
              </div>
            ) : (
              <div>
                 Bel met Isabelle of Pieter
                <br />
                <span>via <a href={Telephone_Link}>{Telephone}</a></span>
              </div>
            )
          }
        </p>
      </div>
    </StyledComponent>
  );
};

UspLinks.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ssl: PropTypes.bool,
};

UspLinks.defaultProps = {
  ssl: false,
};

export default connect()(UspLinks);
