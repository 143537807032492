import styled from 'styled-components';

/* Constants */
import { mdMinWidth } from '../../constants/breakpoints';
import { spaceLarge } from '../../constants/spaces';
import { stepsSideBarBackground } from '../../constants/colors';

export default styled.ul`
  height: 100%;
  padding: 0 ${spaceLarge}px 0 0;
  list-style-type: none;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: ${mdMinWidth}px) {
    padding: 0 ${spaceLarge * 2}px 0 0;
  }

  li {
    height: 100%;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .topLink{
    color:${stepsSideBarBackground};
  }
`;
