import styled from 'styled-components';

/* Constants */
import { xsMinWidth } from '../../constants/breakpoints';

export default styled.div`
  padding: 50px 20px;
  text-align: center;

  .topHeading{
    margin-top:80px;
    font-size:32px;
    font-weight:700;
  }

  .subheading{
    font-weight:400;
    font-size:14px;
    color:#B5B5B5;
  }

  @media only screen and (min-width: ${xsMinWidth}px) {
    padding: 50px 60px;
  }

  .close {
    position: absolute;
    top: 50px;
    right: 50px;
  }
git ad
  .logo {
    width: 250px;
  }

  .managers {
    img {
      width: 140px;
    }
  }
`;
