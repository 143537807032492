import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

/* Style */
import StyledComponent from './style';

const Dialog = ({ children, open, dispatch }) => {
  const componentClasses = classNames({
    open : !!(open && children)
  });

  console.log('Dialog redux state open : ', open)
  console.log('Dialog redux state children : ', children)

  return (
    <StyledComponent className={componentClasses}>
      {children}
    </StyledComponent>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
};

Dialog.defaultProps = {
  children: null,
};

export default connect((state) => ({
  children: state.dialog.children,
  open: state.dialog.open,
}))(Dialog);
