export const getCookie = (name) => {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
};

export const setCookie = (name, value, days = 90) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()};domain=bridgefund.nl`;
};

export const deleteCookie = (name) => {
  setCookie(name, '', -1);
};

export default {
  getCookie,
  setCookie,
  deleteCookie,
};
