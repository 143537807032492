import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { convertAmount } from '../../../utils/convertAmounts';
import  useForm,  { FormContext, useFormContext } from 'react-hook-form';
// import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FileDrop from 'react-file-drop';

/* Constants */
import { completeOverview } from '../../../constants/routes';

/* Components */
import SearchResults from '../../../components/search-results';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import RadioGroup from '../../../objects/radio/RadioGroup';
import Select from '../../../objects/select';
import Input from '../../../objects/input';
import File from '../../../objects/file';
import Combobox from '../../../objects/combobox';


/* Redux */
import { setValues, prevPage, submit, requestPredefinedParties, updateDebtAtThirdParty, 
  updateDebtor_Creditor, removeValues } from '../../../redux/actions/dept-form';
import { sendFiles } from '../../../redux/actions/complete';

const Questions = ({ dispatch, defaultValues, loanPredefinedParties, leasePredefinedParties, 
  paymentBacklogsPredefinedParties, typeOfLeaseObject, contractId, isSubmitted, loading }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues  });
  const { handleSubmit, formState, triggerValidation, getValues } = formMethods;
   const { register, watch, setValue, } = useForm();
  const paymentOptions = [
    { label: 'Belastingdienst', value: "tax-payment-overdue" },
    { label: 'Overheid', value: "government-payment-arrangement"},
    { label: 'Deurwaarder', value: "debt-collector" },
    { label: 'Incassobureau', value: "collection-agency" },
    { label: 'Overige', value: "other-payments-overdue" }
  ];
  
  const contractOptions = [
    { label: 'Zakelijke Lening', value: "loan" },
    { label: 'Rekening-Courant', value: "current-account-credit" },
    { label: 'Lening bij Familie/Kennis', value: "personal-relationship" },
    { label: 'Leverancierskrediet', value: "supplier-credit" },
    { label: 'Lease-Contract', value: "lease-contract" },
    { label: 'Bedrijfshypotheek', value: "mortgage" },
    { label: 'Overige', value: "other-loans" }
  ];

   const [creditorFiles, setFilesCreditor] = useState([]);
   const [debtorFiles, setFilesDebtor] = useState([]);
   const[answer1, setanswer1] = useState(false);
   const[answer2, setanswer2] = useState(false);
   const[answer3, setanswer3] = useState(false);
   const[answer4, setanswer4] = useState("nee");
   const[answer5, setanswer5] = useState("nee");
   const[paymentOptionSelected, setPaymentOptionSelected] = useState([]);
   const[paymentSubOptionsInputSelected, setpaymentSubOptionsInputSelected] = useState([]);
   const[paymentSubOptionsDropdownSelected, setpaymentSubOptionsDropdownSelected] = useState([]);
   const[contractOptionSelected, setContractOptionSelected] = useState([]);
   const[contractLeaseOptionSelected, setContractLeaseOptionSelected] = useState([]);
   const[contractInputsDisplay, displayContractInputs] = useState([]);
   const[contractOtherOptionSelected, setContractOtherOptionSelected] = useState([]);
   const[contractPersonRelationshipOptionSelected, setContractPersonRelationshipOptionSelected] = useState(false);
   const[counter, setCount] = useState([1]);
   const[paymentsCounter, setpaymentsCount] = useState([1]);
   const[creditorCounter, setCreditorCount] = useState([1]);
   const[debtorCounter, setDebtorCount] = useState([1]);
   const[otherContracts, setOtherContracts] = useState(false);
   
  // Fix validating defaultValues
  useEffect(() => {
    triggerValidation();
  
  }, [triggerValidation]);

  useEffect(() => {
    renderQ2Elem();
  }, [setCount]);

  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "party-names-loans",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);

  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "party-names-lease-contracts",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);

  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "party-names-payment-backlogs",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);

  useEffect(() => {
    dispatch(requestPredefinedParties({
      "field-name" : "type-of-lease-object",
      "parent-field-name" : "",
      "parent-field-name-value" : ""
    }));
  }, []);

  const onSubmit = (data) => {
    let values = getValues();
    
    let obligationdata = prepareObligationsData(values);

   storeData(obligationdata);

     dispatch(setValues(obligationdata));
     dispatch(submit());

    if(creditorFiles.length > 0){
      dispatch(sendFiles('CREDITLIST', creditorFiles))
    }

    if(debtorFiles.length > 0){
      dispatch(sendFiles('DEBITLIST', debtorFiles))
    }
       
  };

  const  storeData = async (obligationdata) => { 
    if(obligationdata){
        if(obligationdata.contract && obligationdata.contract.length > 0){
          obligationdata.contract.map((contractItem) => {

            let contactDebtData = {
              debtAtThirdParty : {
                action : 'create',
                contractId : contractId, 
                bankruptIndicator : getValues().bankruptcy === "Ja" ? 'yes' : "no",
                bankruptCompanyName : getValues().bankrupt_companyName ? getValues().bankrupt_companyName : "",
                id : "",
                partyName : contractItem.counterPartyName,
                otherPartyName : "",
                debtType : contractItem.obligation,
                typeOfObject : "",
                description : "",
                totalInitialDebtAmount : Number(convertAmount(contractItem.totalInitialDebtAmount)),
                outstandingDebtAmount : Number(convertAmount(contractItem.outstandingAmount)),
                repaymentAmountPerMonth : Number(convertAmount(contractItem.repaymentPerMonth)),
                debtEndDate : ""
              }
              
            }
            
              if(contractItem.obligation === 'lease-contract'){
                contactDebtData.debtAtThirdParty.typeOfObject = contractItem.product;
              }
              if(contractItem.obligation === '"other-payments-overdue"'){
                contactDebtData.debtAtThirdParty.otherPartyName = contractItem.otherCounterpartyName;
              }
              
                dispatch(updateDebtAtThirdParty(contactDebtData));
          });
        }
      
        if(obligationdata.payment && obligationdata.payment.length > 0){
          obligationdata.payment.map((paymentItem) => {

            let paymentDebtData = {
              debtAtThirdParty : {
                action : 'create',
                contractId : contractId,
                bankruptIndicator : getValues().bankruptcy === "Ja" ? 'yes' : "no",
                bankruptCompanyName : getValues().bankrupt_companyName ? getValues().bankrupt_companyName : "",
                id : "",
                partyName : paymentItem.obligation === 'tax-payment-overdue' ? 'tax-payment-overdue' : paymentItem.obligation === 'government-payment-arrangement' ? 'government-payment-arrangement' : paymentItem.counterPartyName,
                otherPartyName : "",
                debtType : paymentItem.obligation,
                typeOfObject : "",
                description : "",
                totalInitialDebtAmount : Number(convertAmount(paymentItem.totalInitialDebtAmount)),
                outstandingDebtAmount : Number(convertAmount(paymentItem.outstandingAmount)),
                repaymentAmountPerMonth : Number(convertAmount(paymentItem.repaymentPerMonth)),
                debtEndDate : ""
              }
              
            }
              dispatch(updateDebtAtThirdParty(paymentDebtData));
          });
        }

        if(obligationdata.creditors && (obligationdata.creditors !== "" || obligationdata.creditors !== "Nee")){
          if(obligationdata.creditors === "Ja"){
            obligationdata.creditor.map((creditor) => {
              let debtorCreditorData = {
                debtorCreditor : {
                  action :"create",
                  smeLoanRequestId :  "",
                  contractId : contractId,
                  id : "",
                  partyName : creditor.name,
                  outstandingInvoiceAmount : Number(convertAmount(creditor.outstandingAmount)),
                  totalOutstanding : 0,
                  numberOfPartiesInvolved : 0,
                  debitorCreditorIndicator: "creditor",
                  debtorsCreditorsList: {}
                }
                
              }

                dispatch(updateDebtor_Creditor(debtorCreditorData));

            });

            }else if(obligationdata.creditors === "Ja, ik wil mijn crediteurenlijst uploaden"){
             
              generateDebtorCreditorList(creditorFiles[0])
              .then((creditorFile) => {
                let debtorCreditorData = {
                  debtorCreditor : {
                    action :"create",
                    smeLoanRequestId : "",
                    contractId : contractId,
                    id : "",
                    partyName : "creditors-overview",
                    outstandingInvoiceAmount : 0,
                    totalOutstanding : Number(obligationdata.creditor_outstandingAmount),
                    numberOfPartiesInvolved : Number(obligationdata.creditor_numberOfParties),
                    debitorCreditorIndicator: "creditor",
                    debtorsCreditorsList : creditorFile
                  }
                  
                }
                
                dispatch(updateDebtor_Creditor(debtorCreditorData));

              })
            }
        }

        if(obligationdata.debtors && (obligationdata.debtors !== "" || obligationdata.debtors !== "Nee")){
          if(obligationdata.debtors === "Ja"){
            obligationdata.debtor.map((debtor) => {
              let debtorCreditorData = {
                debtorCreditor : {
                  action :"create",
                  smeLoanRequestId : "",
                  contractId : contractId,
                  id : "",
                  partyName : debtor.name,
                  outstandingInvoiceAmount : Number(convertAmount(debtor.outstandingAmount)),
                  totalOutstanding : 0,
                  numberOfPartiesInvolved : 0,
                  debitorCreditorIndicator: "debitor",
                  debtorsCreditorsList: {}
                }  
              }
  
                dispatch(updateDebtor_Creditor(debtorCreditorData));

            });

          }else if(obligationdata.debtors === "Ja, ik wil mijn debiteurenlijst uploaden"){

            generateDebtorCreditorList(debtorFiles[0])
            .then((debitorFile) => {
              let debtorCreditorData = {
                debtorCreditor : {
                  action :"create",
                  smeLoanRequestId : "",
                  contractId : contractId,
                  id : "",
                  partyName : "debtors-overview",
                  outstandingInvoiceAmount : 0,
                  totalOutstanding : Number(obligationdata.debtor_totalOutstandingAmount),
                  numberOfPartiesInvolved : Number(obligationdata.debtor_numberOfParties),
                  debitorCreditorIndicator: "debitor",
                  debtorsCreditorsList : debitorFile
                }
              }
              
                dispatch(updateDebtor_Creditor(debtorCreditorData));
            })

          }
        }
    }
  };

  const generateDebtorCreditorList = (file) => {
    
    return new Promise((resolve, reject) => {
      if(!file) reject(null);

      let obj = {
        fieldName: "debtorCreditorList",
        fileName: file.name,
        size: file.size,
        type: file.type,
        dataUrl: null
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
       reader.onloadend = async () => {
        obj.dataUrl = reader.result;
        resolve (obj);
       };
    })    
  }

  const prepareObligationsData = (values) => {
    if(values){
      const pairs = Object.entries(values).map(([key, val]) =>
       [key.replace(/_(\d)-/g, (g0, g1) => `[${g1 - 1}].`), val]);

      const toObject = (pairs, acc = {}) => {
      pairs.forEach(([key, val]) => {
        const [headKey, ...tailKeys] = key.split(/\./g);
        if (tailKeys.length === 0) {
          acc[headKey] = val;
        } else {
          const [otherKey] = tailKeys;
          const [match, arrKey, arrIndex] = headKey.match(/(\w+)\[(\d+)\]/);
          if (match) {
            const index = parseInt(arrIndex, 10);
           
            acc[arrKey] = (acc[arrKey] || []);
            acc[arrKey][index] = (acc[arrKey][index] || {});
            acc[arrKey][index][otherKey] = val;
          }
        }
      });
        return acc;
      };

      return toObject(pairs)
    }
  };

  const radioClickBankruptcy = (evt) => {
    if(evt.target.id === "bankruptcy_Ja"){
      setanswer1(true);
    }else{
      setanswer1(false)
      dispatch(removeValues('bankrupt_companyName'));

    }
  }

  const radioClickContracts = (evt) => {
    if(evt.target.id === "contracts_Ja"){
      setanswer2("ja_contracts")
    }else{
      setanswer2(false)
    //  setContractNames([])
      dispatch(removeValues('contract'));
    }
  }

  const radioClickPayments = (evt) => {
    if(evt.target.id === "payments_Ja"){
      setanswer3(true);
    }else{
      setanswer3(false);
      //setPaymentOptionSelected(false);
      // setContractOptionSelected(false);
      //setPaymentNames([]);
      dispatch(removeValues('payment'));
    }
  }

  const radioClickCreditors = (evt) => {
    if(evt.target.id === "creditors_Ja"){
      setanswer4("ja");
    }else if(evt.target.id === "creditors_Ja-crediteurenlijst"){
      setanswer4("ja-crediteurenlijst")
    }else{
      setanswer4("nee");
      dispatch(removeValues('creditor'));
    }
  }

  const radioClickDebtors = (evt) => {
    if(evt.target.id === "debtors_Ja"){
      setanswer5("ja_debtors");
    }else if(evt.target.id === "debtors_Ja-debiteurenlijst"){
      setanswer5("ja-debiteurenlijst")
    }else{
      setanswer5("nee");
      dispatch(removeValues('debtor'));
    }
  }

  // handles options changes from Payments drop down (Q3)
  const handlePaymentOnChange = (evt, key) => {
    let paymentSelectedArray = [...paymentOptionSelected];
    let paymentSubOptionInputArray = [...paymentSubOptionsInputSelected]
    let paymentSubOptionsDropdownArray = [...paymentSubOptionsDropdownSelected]

    let paymentOptions = ["debt-collector","collection-agency"];

    if(paymentOptions.includes(evt.target.value)){
      paymentSubOptionsDropdownArray[key] = true;
      setpaymentSubOptionsDropdownSelected(paymentSubOptionsDropdownArray)
      paymentSubOptionInputArray[key] = false;
      setpaymentSubOptionsInputSelected(paymentSubOptionInputArray);
      
    }else if(evt.target.value === "other-payments-overdue"){
      paymentSubOptionInputArray[key] = true;
      setpaymentSubOptionsInputSelected(paymentSubOptionInputArray);
      paymentSubOptionsDropdownArray[key] = false;
      setpaymentSubOptionsDropdownSelected(paymentSubOptionsDropdownArray)
      
    }else if(evt.target.value === "tax-payment-overdue" || evt.target.value === "government-payment-arrangement"){
      paymentSubOptionInputArray[key] = false;
      setpaymentSubOptionsInputSelected(paymentSubOptionInputArray);
      paymentSubOptionsDropdownArray[key] = false;
      setpaymentSubOptionsDropdownSelected(paymentSubOptionsDropdownArray)
    }

    paymentSelectedArray[key] = true;
    setPaymentOptionSelected(paymentSelectedArray);
  }

  // handles options changes from Contract drop down (Q2)
  const handleContractOnChange = (evt, key) => {
    
    let contractSelectedAry = [...contractOptionSelected];
    let contractLeaseAry = [...contractLeaseOptionSelected];
    let contractInputsArray = [...contractInputsDisplay];
    let contractOtherOptionArray = [...contractOtherOptionSelected];

    let valueSet1 = ["loan","current-account-credit","supplier-credit","mortgage"];

    if(valueSet1.includes(evt.target.value)){

      contractSelectedAry[key] = true;
      setContractOptionSelected(contractSelectedAry);

      contractLeaseAry[key] = false;
      setContractLeaseOptionSelected(contractLeaseAry);

      contractOtherOptionArray[key] = false;
      setContractOtherOptionSelected(contractOtherOptionArray);

      setContractPersonRelationshipOptionSelected(false);
      
    }else if(evt.target.value === "lease-contract"){
      contractLeaseAry[key] = true;
      setContractLeaseOptionSelected(contractLeaseAry);

      contractSelectedAry[key] = true;
      setContractOptionSelected(contractSelectedAry);

      contractOtherOptionArray[key] = false;
      setContractOtherOptionSelected(contractOtherOptionArray);

    }else if(evt.target.value === "other-loans"){
      contractOtherOptionArray[key] = true;
      setContractOtherOptionSelected(contractOtherOptionArray);

      contractSelectedAry[key] = false;
      setContractOptionSelected(contractSelectedAry);

      setContractPersonRelationshipOptionSelected(false);

    }else if(evt.target.value === "personal-relationship"){
      contractOtherOptionArray[key] = true;
      setContractOtherOptionSelected(contractOtherOptionArray);

      setContractPersonRelationshipOptionSelected(true);
      
      contractSelectedAry[key] = false;
      setContractOptionSelected(contractSelectedAry);
      setOtherContracts(false);
    
    }

    contractInputsArray[key] = true;
    displayContractInputs(contractInputsArray);
  }

  // rendering for Q2 
  const renderQ2Elem = () => {
    return counter.map((item,key) =>(
        <div key={item}> 
            <p className="contractPartTitle mt-20">Verplichting {key+1}:</p>
            {
              counter.length > 1 && 
              <img src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                onClick={() => removeObligationItem(item,'contract', `contract_${item}-counterPartyName`)} 
                alt="close" className="closeObligation" />
            }

            <div id="contractSelections" className="mt-20">
              <div className="contractSelectionDivs">
                <Select
                  id={`contract_${item}-obligation`}
                  name={`contract_${item}-obligation`}
                  placeholder="Soort verplichting"
                  options={contractOptions}
                  handleOptionsChange={(e) => handleContractOnChange(e,item)}
                  required
                  height="60px"
                /> 
              </div>
              {
                contractOptionSelected[item] &&
                <>
                  <div id={`contractSubSelection_${item}`} className="contractSelectionDivs">
                    <Combobox name={`contract_${item}-counterPartyName`} 
                      label="Naam tegenpartij" 
                      options={contractLeaseOptionSelected[item] ? leasePredefinedParties : loanPredefinedParties}
                      placeholder="Naam tegenpartij"
                      list={`contract_${item}`}
                      height="60px"
                      required />
                     
                  </div>
                  {
                    contractLeaseOptionSelected[item] &&
                    <div className="contractSubInput">
                      <Select
                        id={`contract_${item}-product`}
                        name={`contract_${item}-product`}
                        placeholder="Soort product"
                        options={typeOfLeaseObject}
                        handleOptionsChange={(e) => handleContractOnChange(e,item)}
                        required
                        height="60px"
                      />
                    </div>
                  }
                  
                </>
              }

              {
                contractOtherOptionSelected[item] &&
                <>
                 <div className="contractSubInput">
                      <Input name={`contract_${item}-counterPartyName`} 
                        label="Naam tegenpartij" 
                        height="60px"
                        required />
                  </div> 
                  
                  
                  {
                    !contractPersonRelationshipOptionSelected &&
                      <div className="contractSubInput">
                        <Input name={`contract_${item}-description`} 
                          label="Omschrijving" 
                          height="60px"
                          required />
                      </div>
                  }
                </> 
              }
            </div>
              
            {
              contractInputsDisplay[item] && 
                <div className="Debt_at_third_Part_row mt-20">
                  <div className="Debt_at_third_Part_row_input" >
                    <Input height="60px" name={`contract_${item}-totalInitialDebtAmount`} 
                    label="Oorspronkelijke hoofdsom" 
                    required
                    currencyAmount={true} />
                  </div>
                  <div className="Debt_at_third_Part_row_input" >
                    <Input height="60px" name={`contract_${item}-outstandingAmount`} 
                    label="Openstaand bedrag"
                    required 
                    currencyAmount={true} />
                  </div>
                  <div className="Debt_at_third_Part_row_input" >
                    <Input height="60px" name={`contract_${item}-repaymentPerMonth`} 
                    label="Aflossing per maand" 
                    required 
                    currencyAmount={true} />
                  </div>
                </div>
            }
        </div>

    ));
  }

  // rendering for Q3
  const renderQ3Elem = () => {
    return paymentsCounter.map((item,key) =>(
      <div key={item}>
        <p className="mt-20">Betaalregeling {key+1}:</p>
          {
              paymentsCounter.length > 1 && 
              <img src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                onClick={() => removeObligationItem(item,'payment', `payment_${item}-counterPartyName`)} 
                alt="close" className="closeObligation" />
          }
          <div className="paymentTopRow">
            <div id={`paymentsSelections_${item}`} className="paymentSelectionDivs">
              <Select
                id={`payment_${item}-obligation`}
                name={`payment_${item}-obligation`}
                placeholder="Soort regeling"
                options={paymentOptions}
                handleOptionsChange={(e) => handlePaymentOnChange(e,item)}
                required
                height="60px"
              />
            </div>
            {
              paymentSubOptionsInputSelected[item] && 
              <div className="paymentSubInput">
                <Input name={`payment_${item}-counterPartyName`} 
                  label="Naam tegenpartij" 
                  height="60px"
                  required />
              </div>
            }
            
            {
              paymentSubOptionsDropdownSelected[item] && 
              <div className="paymentSubInput">
                <Combobox name={`payment_${item}-counterPartyName`} 
                  label="Naam tegenpartij" 
                  options={paymentBacklogsPredefinedParties}
                  //options= {["payemnt 1", "payment 2"]}
                  placeholder="Naam tegenpartij"
                  list={`payment_${item}`}
                  height="60px"
                  required />
                  
              </div>
            }
          </div>
              {paymentOptionSelected[item] &&
                <div className="Debt_at_third_Part_row mt-20">
                  <div className="Debt_at_third_Part_row_input" >
                    <Input height="60px" name={`payment_${item}-totalInitialDebtAmount`} label="Oorspronkelijke hoofdsom" currencyAmount={true} required />
                  </div>
                  <div className="Debt_at_third_Part_row_input" >
                    <Input height="60px" name={`payment_${item}-outstandingAmount`} label="Openstaand bedrag" currencyAmount={true} required />
                  </div>
                  <div className="Debt_at_third_Part_row_input" >
                    <Input height="60px" name={`payment_${item}-repaymentPerMonth`} label="Aflossing per maand" currencyAmount={true} required />
                  </div>
                </div>
              }       
      </div>
    ));  
  }

  // rendering for Q4
  const renderQ4Elem = () => { 
    return creditorCounter.map((item,key) =>(
        <div key={item}>
          <p className="mt-20">Crediteur {key+1}:</p>
          {
              creditorCounter.length > 1 &&
              <img src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                onClick={() => removeObligationItem(item,'creditor')} 
                alt="close" className="closeObligation" />
          }
            <div className="creditors_row Debt_at_third_Part_row">
              <div className="creditors_row_input Debt_at_third_Part_row_input" >
                <Input name={`creditor_${item}-name`} label="Naam crediteur" height="60px" required />
              </div>
              <div className="creditors_row_input Debt_at_third_Part_row_input" >
                <Input name={`creditor_${item}-outstandingAmount`} label="Openstaand bedrag" height="60px" currencyAmount={true} required />
              </div>
            </div>
        </div>
     ));
  }

   // rendering for Q5
   const renderQ5Elem = () => { 
    return debtorCounter.map((item,key) =>(
        <div key={item}>
          <p className="mt-20">Debiteuren {key+1}:</p>
          {
              debtorCounter.length > 1 && 
              <img src={`${process.env.PUBLIC_URL}/images/icons/close.svg`}
                onClick={() => removeObligationItem(item,'debtor')} 
                alt="close" className="closeObligation" />
          }
            <div className="creditors_row Debt_at_third_Part_row">
              <div className="creditors_row_input Debt_at_third_Part_row_input" >
                <Input name={`debtor_${item}-name`} label="Naam debiteur" height="60px" required  />
              </div>
              <div className="creditors_row_input Debt_at_third_Part_row_input" >
                <Input name={`debtor_${item}-outstandingAmount`} label="Openstaand bedrag" height="60px" currencyAmount={true} required />
              </div>
            </div>
        </div>
     ));
  }

  // Adding new items according to the clicks
  const addNewObligation = (type) => {
    if(type === 'contract'){
      let newCounter = [...counter];
      
      newCounter.push(newCounter[newCounter.length -1] + 1);
      setCount(newCounter);
      renderQ2Elem();

    }else if(type === 'payment'){
      let newPaymentsCounter = [...paymentsCounter];

      newPaymentsCounter.push(newPaymentsCounter.length+1)
      setpaymentsCount(newPaymentsCounter);
      renderQ3Elem();
    }else if(type === 'creditor'){
      let newCreditorCounter = [...creditorCounter];

      newCreditorCounter.push(newCreditorCounter.length+1)
      setCreditorCount(newCreditorCounter);
      renderQ4Elem();

    }else if(type === 'debtor'){
      let newDebtorCounter = [...debtorCounter];

      newDebtorCounter.push(newDebtorCounter.length+1)
      setDebtorCount(newDebtorCounter);
      renderQ5Elem();

    }

  }
 
  // Removing added items by clicks
  const removeObligationItem = (key, type, name) => {

    if(type === 'contract'){
      let newCount = [...counter];
      let newCounter = newCount.filter(item => {return item !== key})
      setCount(newCounter);

    }else if(type === 'payment'){
      let newCount = [...paymentsCounter];
      let newCounter = newCount.filter(item => {return item !== key})
      setpaymentsCount(newCounter);

    }else if(type === 'creditor'){
      let newCount = [...creditorCounter];
      let newCounter = newCount.filter(item => {return item !== key})
      setCreditorCount(newCounter);

    }else if(type === 'debtor'){
      let newCount = [...debtorCounter];
      let newCounter = newCount.filter(item => {return item !== key})
      setDebtorCount(newCounter);
    }
    
  }

  const handleFilesCreditor = (f) => {
    setFilesCreditor(f);
  }

  const handleFilesDebtor = (f) => {
    setFilesDebtor(f);
  }

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>Vul de onderstaande 5 vragen in m.b.t. de huidige financiële situatie van je bedrijf.
          Deze informatie hebben wij nodig om een definitieve berekening te maken van je leencapaciteit.</p>
        
        {/* <div className="mt-20"></div>
        <p>Deze informatie hebben wij nodig om een definitieve berekening te maken van je leencapaciteit.</p>
        <div className="mt-20"></div> */}

        <p className="bold mt-20">1. Faillissement:</p>
        <p>Ben je in de afgelopen 8 jaar betrokken geweest bij een faillissement?</p>
        <div className="mt-20">
            <RadioGroup
            name="bankruptcy"
            options={[
              { label: 'Ja', id : 'bankruptcy_Ja' },
              { label: 'Nee', id : 'bankruptcy_Nee'  },
            ]}
            required
            onclick = {(e) => radioClickBankruptcy(e)}
          />
           {answer1 &&
              <div id="bedrijfsnaamSection">
                <Input className="Bedrijfsnaam"  name="bankrupt_companyName"
                label="Bedrijfsnaam faillissement" required />
              </div>
           }
        </div>

        <p className="bold mt-20">2. Contractuele verplichtingen:</p>
        <p>Heeft het bedrijf contractuele verplichtingen? Bijvoorbeeld: Lease-overeenkomst,
          lopende zakelijke lening, rekening courant of andere financieringsvormen?</p>
        <div className="mt-20">
            <RadioGroup
            name="contracts"
            options={[
              { label: 'Ja', id : 'contracts_Ja' },
              { label: 'Nee', id : 'contracts_Nee' },
            ]}
            required
            onclick = {(e) => radioClickContracts(e)}
          />
          {answer2 && 
          <div>
            {/* <p className="bold mt-20">Vul de specificaties van de achterstand en/of betaalregeling in.</p> */}

            {counter && renderQ2Elem()}

            <div onClick={()=> addNewObligation('contract')} className="mt-20 addMoreObligations">
              <span>+ Nog een verplichting toevoegen</span> 
            </div>
          </div>
          }
          
        </div>

        <p className="bold mt-20">3. Betaalregelingen:</p>
        <p>Heb je op dit moment een betaalregeling lopen bij een incassobureau, gerechtsdeurwaarder, belastingdienst of de 
          overheid (NOW of TVL)?</p>
        <div className="mt-20">
            <RadioGroup
            name="payments"
            options={[
              { label: 'Ja', id : 'payments_Ja' },
              { label: 'Nee', id : 'payments_Nee'  },
            ]}
            required
            onclick = {(e) => radioClickPayments(e)}
          />
          {answer3 &&
              <div>
                {/* <p className="bold mt-20">Vul de specificaties van de achterstand en/of betaalregeling in.</p> */}

                {paymentsCounter && renderQ3Elem()}

                <div className="mt-20">
                  <div onClick={()=> addNewObligation('payment')} className="mt-20 addMoreObligations">
                    <span>+ Nog een betaalregeling toevoegen</span>
                  </div>
                </div>
              </div>

           }
        </div>

        <p className="bold mt-20">4. Crediteuren:</p>
        <p>Heb je op dit moment facturen openstaan die je nog moet betalen?</p>
        <div className="mt-20">
            <RadioGroup
            name="creditors"
            options={[
              { label: 'Ja', id : 'creditors_Ja' },
              { label: 'Ja, ik wil mijn crediteurenlijst uploaden', id : 'creditors_Ja-crediteurenlijst' },
              { label: 'Nee', id : 'creditors_Nee'  },
            ]}
            required
            onclick = {(e) => radioClickCreditors(e)}

          />
            {
              answer4 && answer4 === 'ja' &&
                <div>
                  {/* <p className="bold mt-20">Vul de openstaande facturen in die je nog moet betalen.</p> */}

                  {creditorCounter && renderQ4Elem()}

                  <div className="mt-20">
                    <div onClick={()=> addNewObligation('creditor')} className="mt-20 addMoreObligations">
                      <span>+ Nog een crediteur toevoegen</span>
                    </div>
                  </div>

                </div>

            }

            {
              answer4 === 'ja-crediteurenlijst' &&
              <div>
                {/* <p className="bold mt-20">Vul de openstaande facturen in die je nog moet betalen.</p> */}
                  <p className="mt-20">Totaal:</p>

                  <div className="creditors_row Debt_at_third_Part_row">
                    <div className="creditors_row_input Debt_at_third_Part_row_input" >
                      <Input type="number" name="creditor_numberOfParties" label="Aantal crediteuren" height="60px" required  />
                    </div>
                    <div className="creditors_row_input Debt_at_third_Part_row_input" >
                      <Input type="number" name="creditor_outstandingAmount" label="Totaal openstaand bedrag" height="60px" required />
                    </div>
                  </div>

                  <div className="mt-20">
                    <File
                      name="creditorList"
                      label="Selecteer je crediteurenlijst"
                      labelActive="Selecteer nog een crediteurenlijst"
                      onChange={handleFilesCreditor}
                      accept=".pdf"
                      allowedExtensions={/(\.pdf)$/i}
                      schuldenformulierNormal = {true}
                      reuse={true}
                      labelBeforeSubmit="Upload hier je crediteurenlijst"
                      required
                    />
                  </div>
              </div>


            }

        </div>

        <p className="bold mt-20">5. Debiteuren:</p>
        <p>Heb je op dit moment facturen openstaan die nog aan jou moeten worden betaald?</p>
        <div className="mt-20">
            <RadioGroup
            name="debtors"
            options={[
              { label: 'Ja', id : 'debtors_Ja' },
              { label: 'Ja, ik wil mijn debiteurenlijst uploaden', id : 'debtors_Ja-debiteurenlijst' },
              { label: 'Nee', id : 'debtors_Nee'  },
            ]}
            required
            onclick = {(e) => radioClickDebtors(e)}
          />

          {
              answer5 && answer5 === 'ja_debtors' &&
                <div>
                  {/* <p className="bold mt-20">Vul de openstaande facturen in die je nog moet betalen.</p> */}
                  {debtorCounter && renderQ5Elem()}

                  <div className="mt-20">
                    <div onClick={()=> addNewObligation('debtor')} className="mt-20 addMoreObligations">
                      <span>+ Nog een debiteur toevoegen</span>
                    </div>
                  </div>

                </div>
            }

            {
              answer5 === 'ja-debiteurenlijst' &&
              <div>
                {/* <p className="bold mt-20">Vul de openstaande facturen in die je nog moet betalen.</p> */}
                  <p className="mt-20">Totaal:</p>

                  <div className="debtors_row Debt_at_third_Part_row">
                    <div className="debtors_row_input Debt_at_third_Part_row_input" >
                      <Input type="number" name="debtor_numberOfParties" label="Aantal debiteuren" height="60px" required />
                    </div>
                    <div className="debtors_row_input Debt_at_third_Part_row_input" >
                      <Input type="number" name="debtor_totalOutstandingAmount" label="Totaal openstaand bedrag" height="60px" required />
                    </div>
                  </div>

                  <div className="mt-20">
                    <File
                      name="debitorList"
                      label="Selecteer je debiteurenlijst"
                      labelActive="Selecteer nog een debiteurenlijst"
                      onChange={handleFilesDebtor}
                      accept=".pdf"
                      allowedExtensions={/(\.pdf)$/i}
                      schuldenformulierNormal = {true}
                      reuse={true}
                      labelBeforeSubmit="Upload hier je debiteurenlijst"
                      required
                    />
              </div>
              </div>
            }

        </div>

        <div className="mt-40 inDesktop">
          <Grid>
            <Link to={completeOverview}>
              <Button width="100%" outline>Terug naar mijn dossier</Button>
            </Link>
            <Button
              type="submit"
              width="100%"
              loading={loading}
              success={isSubmitted}
              disabled={!formState.isValid}
            >
              Verzenden
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              loading={loading}
              success={isSubmitted}
              disabled={!formState.isValid}
            >
              Verzenden
            </Button>
            <Link to={completeOverview}>
              <Button width="100%" outline>Terug naar mijn dossier</Button>
            </Link>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Questions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
  loanPredefinedParties: PropTypes.array.isRequired,
  leasePredefinedParties: PropTypes.array.isRequired,
  paymentBacklogsPredefinedParties: PropTypes.array.isRequired,
  typeOfLeaseObject: PropTypes.array.isRequired,
  contractId : PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool,
  loading: PropTypes.bool,
  removeValues: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
};

Questions.defaultProps = {
  defaultValues: {},
  loanPredefinedParties: [],
  leasePredefinedParties: [],
  paymentBacklogsPredefinedParties: [],
  typeOfLeaseObject: [],
  contractId : "",
  isSubmitted: false,
  loading: false,
  suggestions: []

};

export default connect((state) => ({
  //defaultValues: state.deptForm,
  loanPredefinedParties: state.deptForm.loanPredefinedParties,
  leasePredefinedParties: state.deptForm.leasePredefinedParties,
  paymentBacklogsPredefinedParties: state.deptForm.paymentBacklogsPredefinedParties,
  typeOfLeaseObject: state.deptForm.typeOfLeaseObject,
  contractId : state.opportunity.documents.number,
  isSubmitted: state.deptForm.submit,
  loading: state.loading.deptForm
}))(Questions);
