import styled from 'styled-components';

/* Constants */
import { space, spaceSmall, spaceLarge } from '../../constants/spaces';
import { primary2, grayLight, stepsSideBarBackground, dark2, primary3 } from '../../constants/colors';

import {
  mdMinWidth,
  smMaxWidth,
  mdMaxWidth,
  smMinWidth,
  xlMaxWidth,
  xlMinWidth,
  tabMaxWidth
} from '../../constants/breakpoints';

export default styled.div`
  text-align: center;
  background-color:${stepsSideBarBackground};
  height:100%;
  padding-top:67px;
  width: 320px;
  float: right;

  // @media only screen and (max-width: ${smMaxWidth}px) {
  //   margin-left:0;
  //   }

  // @media only screen and (min-width: ${smMinWidth}px) and (max-width: ${mdMaxWidth}px) {
  //   margin-left:0%;
  //   }

  // @media only screen and (min-width: ${mdMinWidth}px) and (max-width: ${xlMaxWidth}px) {
  //   margin-left:0%;
  //  }

  // @media only screen and (min-width: ${xlMinWidth}px) and (max-width: 1440px) {
  //   margin-left:15%;
  //   }

  // @media only screen and (min-width: 1441px) and (max-width: 2560px) {
  //   margin-left:35%;
  // }

  @media only screen and (max-width: ${tabMaxWidth}px){
    width: 100%;

    .whatsapp {
        a {
        padding-bottom:4%;
      }

    }
  }

  @media only screen and (min-width:${tabMaxWidth}px) and (max-width: 992px) {
    width: 100%;
  }

  a {
    color: ${primary2};
    text-decoration: none;
  }

  .avatar {
    width: 125px;
  }

  .name {
    margin-top: ${spaceLarge}px;
  }

  .title {
    color: ${grayLight}
  }

  .discription {
    margin-top: ${space}px;
  }

  .questions {
    margin-top: ${space * 1.5}px;
    font-weight: 400;
    font-size:14px;
    line-height: 25px;
    color: ${dark2};
  }

  .whatsapp {
    margin-top: ${spaceLarge}px;
    color: ${primary3};
    font-weight: normal;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${primary3};
    }

    i {
      font-size: 24px;
      margin-right: ${spaceSmall * 1.5}px;
    }
  }

  .titleSuccessManager{
    color: #0d0d0e;
    margin: 0;
    font-weight:400;
    font-size:15px;
    line-height:25px;
    color: ${dark2};
  }

  .vragen{
    font-weight:600 !important;
  }

  .mailTo{
    color: ${primary3};
  }
`;
