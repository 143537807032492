import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

/* Style */
import StyledComponent from './style';

/* Objects */
import TextBalloon from '../text-balloon';
const partner = localStorage.getItem('partner');

const Select = ({
  name, options, defaultValue,
  placeholder, required, feedback,
  showStatus, height, handleOptionsChange
}) => {
  const {
    register, errors, watch,
    setValue,
  } = useFormContext();
  const registerData = { required };
  let value = watch(name);
  const [feedbackIndex, setFeedbackIndex] = useState();
  const error = errors[name];
  const componentClasses = classNames({
    set: value && showStatus,
    error,
  });

  // const [dropDownChanged, setdropDownChanged] = useState(false);

  const setFeedback = () => {
    value = watch(name);
    const index = options.findIndex((option) => option.value === value);
    // If no feedback item
    if (feedback.length === 0) return;
    // If options is the same as before
    if (index === feedbackIndex) return;
    setFeedbackIndex(index);
  };

  const handleChange = (e) => {
    setValue(name, e.target.value, true);
    setFeedback();
  };

  const renderSelect = () => (
    <select
      id={name}
      name={name}
      defaultValue={defaultValue}
      ref={register(registerData)}
      onChange={handleOptionsChange ? handleOptionsChange
        :(e) => handleChange(e)}
    >
      {placeholder && <option value="" disabled>{placeholder}</option>}
      {
        options.length > 0
        && options.map((option) => (
          <option
            key={option.label || option.value}
            value={(option.value || option.value === 0)  ? option.value : option.label}
          >
            {option.label || option.value}
          </option>
        ))
      }
    </select>
  );

  return (
    <StyledComponent height={height} className={componentClasses} htmlFor={name}>
      {!partner && <TextBalloon
        show={feedbackIndex >= 0}
        position="top"
        align="left"
        hide={3000}
      >
        {feedback[feedbackIndex] || ''}
      </TextBalloon>}
      <div className="holder">
        {renderSelect()}
      </div>
    </StyledComponent>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.shape({}),
  feedback: PropTypes.instanceOf(Array),
  showStatus: PropTypes.bool,
  height: PropTypes.string,
  handleOnChange: PropTypes.func
};

Select.defaultProps = {
  defaultValue: '',
  placeholder: '',
  required: false,
  error: {},
  feedback: [],
  showStatus: true,
  height: '50px',
};

export default Select;
