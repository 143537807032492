import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import FileDrop from 'react-file-drop';

/* Style */
import StyledComponent from './style';

const File = ({
  name, onChange, accept, allowedExtensions,
  required, from, schuldenformulierNormal, reuse, labelBeforeSubmit
}) => {
  const { register, setValue, errors } = useFormContext();
  const registerData = { required };
  const isFirstRun = useRef(true);
  const [fileList, setFileList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const error = errors[name];
  const selected = fileList.length > 0;
  const componentClasses = classNames({
    // selected,
    error,
  });

  useEffect(() => {
    // Not run first time
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (fileList.length > 0) {
      setValue(name, fileList.length, true);
    } else {
      setValue(name, '', true);
    }
  }, [fileList, name, setValue]);

  const handleDrop = (files) => {
    if (files.length === 0) return;
    const newFileList = [...fileList];
    const newErrorList = [...errorList];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!allowedExtensions.exec(file.name)) {
        newErrorList.push(file);
      } else {
        newFileList.push(file);
      }
    }

    setFileList(newFileList);
    setErrorList(newErrorList);
    if (newFileList.length > 0) onChange(newFileList);
  };

  const handleSelect = (e) => {
    handleDrop(e.target.files);
    e.target.value = '';
  };

  const removeFile = (index) => {
    const array = [...fileList];
    array.splice(index, 1);
    setFileList(array);
    onChange(array);
  };

  const removeError = (index) => {
    const array = [...errorList];
    array.splice(index, 1);
    setErrorList(array);
  };

  const renderFiles = () => {
    const fileAmount = fileList.length;
    const errorAmount = errorList.length;

    if (fileAmount === 0 && errorAmount === 0) return;

    const renderLines = () => (
      fileList.map((file, i) => (
        <li key={file.name}>
          <div className="iconWithDescription">
          <img className="fileNameIcon" src={`${process.env.PUBLIC_URL}/images/icons/smile.svg`} />
          <div className="name successFile">{file.name.length > 16 ? file.name.substring(0,16)+"..." : file.name}</div></div>
          <div className="delete" onClick={() => { removeFile(i); }}>
            Verwijderen
            <img className="deleteIcon" src={`${process.env.PUBLIC_URL}/images/icons/trash-2.svg`}/>
          </div>
        </li>
      ))
    );

    const renderErrors = () => (
      errorList.map((file, i) => (
        <li key={file.name}>
          <img className="fileNameIcon" src={`${process.env.PUBLIC_URL}/images/icons/frown.svg`} />
          <div className="name errorFile">
            {file.name.length > 16 ? file.name.substring(0,16)+"..." : file.name}
            {/* <span>Dit bestand wordt niet ondersteund</span> */}
          </div>
          <div className="delete" onClick={() => { removeError(i); }}>
            Verwijderen
            <img className="deleteIcon" src={`${process.env.PUBLIC_URL}/images/icons/trash-2-error.svg`}/>
          </div>
        </li>
      ))
    );

    let fileTxt = <p> <span className="textHighlighted">1 bestand versleuteld</span>  en klaar om te verzenden. </p>

    return (
    <>
      <div className={`set`}>
        <div className="intro">
          {
              fileAmount > 0  &&
              <img className="lockIcon" src={`${process.env.PUBLIC_URL}/images/icons/lock.svg`}/>

          }

          <p>
            <span className="textHighlighted">{fileAmount} bestanden versleuteld</span> en klaar om te verzenden.
          </p>
          
          {/* <p>
            {
              fileAmount === 1 ? 
                '1 bestand versleuteld en klaar om te verzenden.'
                : `${fileAmount} bestanden versleuteld en klaar om te verzenden.`
            }
          </p> */}
        </div>

        <ul className="files">{renderLines()}</ul>
      </div>
      
      { errorAmount > 0 && 

        <div className={`set setError`}>
          <div className="intro">
            
            <img className="lockIcon" src={`${process.env.PUBLIC_URL}/images/icons/unlock.svg`}/>

            <p>
              <span className="textHighlighted">Oeps! Dit bestand kan niet worden geüpload</span>
            </p>

          </div>

          <ul className="files errors">{renderErrors()}</ul>
        </div>

      }
    </>
    );
  };

  let uploaderTxt = "Klik om te selecteren of sleep bestanden hierheen."
  if(schuldenformulierNormal && labelBeforeSubmit !== ""){
    uploaderTxt = `+ ${labelBeforeSubmit}`
  }

  return (
    <StyledComponent className={componentClasses}>
      {renderFiles()}
      <label htmlFor={reuse ? name : "dummy"}>
        <FileDrop onDrop={handleDrop}>
          <img alt="" src="/images/icons/upload_document.svg" className="uploadIcon"/>
          {
            selected
              ? (
                <span className="file-amount submitted">
                  <strong>Heb je nog meer bestanden? </strong>
                  Klik om te selecteren of sleep bestanden hierheen.
                </span>
              ) :
              (

                <span className="file-amount beforeSubmit">{uploaderTxt}</span>
              )
          }

        </FileDrop>
        <input
          type="file"
          id={reuse ? name : "dummy"}
          name={reuse ? name : "dummy"}
          accept={accept}
          onChange={handleSelect}
          multiple
        />
      </label>
      <input
        id={name}
        name={`${name}`}
        ref={register(registerData)}
      />
    </StyledComponent>
  );
};

File.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  allowedExtensions: PropTypes.any,
  required: PropTypes.bool,
  from: PropTypes.string,
  schuldenformulierNormal: PropTypes.bool,
  reuse: PropTypes.bool,
  labelBeforeSubmit: PropTypes.string
};

File.defaultProps = {
  label: 'Selecteer je bestand',
  labelActive: 'Selecteer nog een bestand',
  required: false,
  from : "",
  schuldenformulierNormal: false,
  reuse: false,
  labelBeforeSubmit : ""
};

export default File;
