import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 32px;
  position: relative;

  input {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 100%;
    height: 32px;
    outline: none;
    z-index: 1;
    background-color: transparent;
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background: url(${process.env.PUBLIC_URL}/images/sliderDrag.svg);
    cursor: pointer;
    border-radius:50%;
    box-shadow: 0px 0px 8px -1px  rgba(16, 21, 52, 0.18)

  }

  input::-moz-range-thumb {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background: url(${process.env.PUBLIC_URL}/images/sliderDrag.svg);
    cursor: pointer;
  }

  input::-moz-focus-outer {
    border: 0;
  }

  input::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input::-ms-thumb {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background: url(${process.env.PUBLIC_URL}/images/sliderDrag.svg);
    cursor: pointer;
  }

  input::-ms-fill-lower,
  input::-ms-fill-upper {
    background-color: transparent;
  }

  .background {
    width: 100%;
    height: 10px;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    border-radius: 5px;
    background: #f2f2f2;
  }
`;
