import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/* Style */
import StyledComponent from './style';

const Button = ({
  type, small, textOnly, textOnlyFullWidth, width,
  outline, noline, important,
  disabled, loading, success,
  textAlign, children, onClick, buttonSize, pinkButton,
  blueWhiteBtn, widget

}) => {
  const classes = classNames({
    button: true,
    small,
    textOnly,
    textOnlyFullWidth,
    outline,
    noline,
    important,
    disabled,
    loading,
    success
    
  });

  let btnClasses = classes;
  if(buttonSize && buttonSize === 'halfRight'){
    btnClasses = btnClasses +" halfSizedRightBtn";
  }

  if(pinkButton){
    btnClasses = btnClasses +" pinkButton";
  }

  if(blueWhiteBtn){
    if(btnClasses.includes("disabled")){

    }else{
     btnClasses = btnClasses +" blueWhiteBtn";
    }
   }
  
  if(widget){
    btnClasses = btnClasses +" cta-widget";
  } 

   return (
    <StyledComponent
      width={width}
      type={type}
      className={btnClasses}
      textAlign={textAlign}
      onClick={onClick}

    >

      {loading ? '' : children}
    </StyledComponent>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  small: PropTypes.bool,
  textOnly: PropTypes.bool,
  textOnlyFullWidth: PropTypes.bool,
  width: PropTypes.string,
  outline: PropTypes.bool,
  noline: PropTypes.bool,
  important: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  textAlign: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  buttonSize: PropTypes.string,
  pinkButton: PropTypes.bool,
  blueWhiteBtn: PropTypes.bool,
  widget: PropTypes.bool
};

Button.defaultProps = {
  type: 'button',
  small: false,
  textOnly: false,
  textOnlyFullWidth: false,
  width: '300px',
  outline: false,
  noline: false,
  important: false,
  disabled: false,
  loading: false,
  success: false,
  textAlign: 'center',
  onClick: null,
  buttonSize: "",
  pinkButton: false,
  blueWhiteBtn: false,
  widget: false
};

export default Button;
