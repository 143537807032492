const spaceAmounts = [0, 10, 20, 40, 60, 80]; // Adjust this to include the pixel amounts you need.
const sides = ['top', 'right', 'bottom', 'left'];

export default spaceAmounts.map((size) => `
  ${sides.map((side) => `
    .m${side[0]}-${size}{
      margin-${side}: ${size}px !important;
    }
  `)}

  .m-0 {
    margin: 0;
  }
`);
