/* Constants */
import { grayDark } from '../constants/colors';

export default `
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

  * {
    // font-family: 'Source Sans Pro', sans-serif;
    font-family: 'inter', sans-serif;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  html {
    font-size: 17px;
    color: ${grayDark}
    scroll-behavior: smooth;

    height:100%
  }

  body, #root{
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 992px) and (max-width: 1050px) {
    body, #root{
      // width: auto;
    }
  }

  // @media only screen and (max-width: 375px) {
  //   body, #root{
  //      width: 47vh !important;
  //   }
  // }

  .ftllcf{
    position: relative;
    float: left;
    width: 100%;
    height: 94%;
  }

  .kBgFZP{
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
  }

  .eUMuZS{
    position: relative;
    float: left;
    height: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  input {
    color: ${grayDark};
  }

  select {
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }
  }

  button {
    font-size: inherit;
    color: inherit;
  }

  dl, ol, ul, p {
    margin-bottom: 0 !important;
  }
`;
