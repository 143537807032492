export const white = '#ffffff';
export const black = '#000000';

export const primary = '#967CFF'; // old #1edda8
export const primary2 = '#29c4ea';
export const primary3 = '#6434FC';
export const primary4 = '#D2D3DA';

export const grayDark = '#444444';
export const gray = '#888888';
export const grayLight = '#D2D3DA'; //old #bbbbbb
export const grayLight2 = '#bbbbbb';
export const grayDarker = '#2D2F39';

export const secondary = '#eac435';
export const secondary2 = '#e40066';
export const secondary3 = '#b655ca';

export const green = '#1edda8';
export const red = '#e40066';
export const errorRed = '#f2606a0f';

export const gradientStart = '#27e97a';
export const gradientEnd = '#09c4c9';

export const field = '#f2f2f2';
export const fieldActive = '#f1fdfa'; // old #f1fdfa
export const fieldError = '#ffffff'; // old #fbf2f7
export const fieldAction = '#fefbef';
export const stepsSideBarBackground = '#FDF2E6'; // old #F3F7FA
export const fieldColor = '#ffffff';
export const fileSelected = '#00A58819';
export const fileError = '#FDEAF2';



export const blue = '#6434FC';
export const blueGradientStart = '#6434FF';
export const blueGradientEnd = '#9D86FC';
export const blueLight = '#6434FC';
export const blueDark = '#675FF7'
export const dark = '#1E274A';
export const bluePurlplish = '#6D4AFF';
export const dark2 = '#1E2749';
export const clearBlue = '#5742F2';
export const purple = '#a084fc';

export const borderGray = '#D2D3DA';
export const borderGreen = '#00A588';
export const borderRed = '#F2606A';




