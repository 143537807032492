// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/web/url-search-params';
import 'core-js/features/array/find-index';

import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import numeral from 'numeral';
// import 'moment/locale/nl'; // Set if you need something like month names
import 'numeral/locales';
import ReactGA from 'react-ga';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

/* Style */
import GlobalStyle from './styles/index';

/* Redux */
import reducers from './redux/index';

/* Objects */
import Dialog from './objects/dialog';

/* Moment default settings */
moment.locale('nl');
moment.defaultFormat = 'DD-MM-YYYY';

/* Numeral default settings */
numeral.locale('nl-nl');

/* Google Analytics */
// ReactGA.initialize([{
//   trackingId: 'UA-113561806-1',
//   options: {
//     useExistingGa: true,
//   },
// }]);

const preloadedState = window.PRELOADED_STATE || {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
const store = createStore(
  reducers,
  { ...preloadedState },
  composeEnhancers(applyMiddleware(thunk)),
  // ,LogRocket.reduxMiddleware()
);

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
    <Dialog />
  </Provider>,
  document.getElementById('root'),
);
