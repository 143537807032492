import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import md5 from 'md5';
import { useWindowDimensions } from '../utils/browserWidth';
import { requestPotentialParameters } from '../redux/actions/request'

/* Constants */
import {
  basicInfo, personalInfo, requestLater, requestThanks,
} from '../constants/routes';

/* Objects */
import Container from '../objects/container';
import Button from '../objects/button';
import File from '../objects/file';
import Card from '../objects/card';
import Link from '../objects/link';
import Grid from '../objects/grid';

/* Componnents */
import PageLayout from '../components/page-layout';
import BankManual from '../components/bank-manual';
import RequestProgress from '../components/request-progress';

/* Redux */
import { sendFiles, setValues } from '../redux/actions/request';

const FinancialInfo = ({
  dispatch, requestId, email, loading, isSubmitted,
}) => {
  const paramId = useParams().id;
  const stap1Ref = useRef(null);
  const stap1RefMobile = useRef(null);
  const id = useParams().id || requestId;
  const formMethods = useForm({ mode: 'onChange' });
  const { handleSubmit, formState } = formMethods;
  const [files, setFiles] = useState([]);
  const partner = localStorage.getItem('partner');
  const { height, width } = useWindowDimensions(); // eslint-disable-line

  let path = useLocation().pathname.split('banktransacties/')[1];
  
  useEffect(() => {
    if(path !== '' && path != null){
      dispatch(requestPotentialParameters(path))
    }
  }, []);

  // Redirect if no id
  if (!id) return <Redirect to={basicInfo} />;

  // Redirect after submit
  if (isSubmitted) {
    try {
      const ADT = window.ADT || {};
      ADT.Tag = ADT.Tag || {};
      ADT.Tag.t = 4;
      ADT.Tag.c = 'EUR';
      ADT.Tag.tp = 1260627010;
      ADT.Tag.ti = id;
      ADT.Tag.xd = md5(encodeURI(email.toLowerCase()));
      ADT.Tag.doEvent();
    } catch (error) {}

    // Reset submit status
    dispatch(setValues({ submit: false }));

    return <Redirect to={requestThanks} push />;
  }

  const handleFiles = (f) => {
    setFiles(f);
  };

  const onSubmit = () => {
    dispatch(sendFiles(id, files));
  };

  return (
    <PageLayout>
      {!paramId && <RequestProgress />}
      <Container width={720}>
        <Card image={`${process.env.PUBLIC_URL}/images/icons/robot-face.png`}>
          <p>
            <strong>
              {
                partner ? 'Upload de banktransacties van je klant voor een aanbod op maat.'
                  : 'Upload je zakelijke banktransacties voor een aanbod op maat'
              }
            </strong>
            <br />
            {
              partner ? 'Wij doen niet aan businessplannen of jaarcijfers. Onze slimme scan checkt razendsnel de zakelijke banktransacties van de afgelopen 12 maanden. Zo bepalen we zorgvuldig hoeveel iemand maximaal verantwoord kan lenen.'
                : 'Wij doen niet aan businessplannen of jaarcijfers. Onze slimme scan checkt razendsnel je zakelijke banktransacties van de afgelopen 12 maanden. Zo bepalen we zorgvuldig hoeveel je maximaal verantwoord kunt lenen.'
            }
          </p>
        </Card>
        <p className="mt-40 inDesktop" ref={stap1Ref}>
          <strong className="strongHeading">
            {partner ? 'Stap 1. Download de banktransacties (MT940-bestand)'
              : 'Stap 1. Download je banktransacties (MT940-bestand)' }
          </strong>
        </p>
        <p className="mt-40 inMobile inMobileSpecial" ref={stap1RefMobile}>
          <strong className="strongHeading">
            {partner ? 'Stap 1. Download de banktransacties'
              : 'Stap 1. Download je banktransacties' }
          </strong>
          <br />
          <strong>(MT940-bestand)</strong>
        </p>
        <p className="light2 ">
          {partner ?
            'Als je klant hulp nodig heeft bij het downloaden van de banktransacties. Selecteer dan de juiste bank voor instructies en stuur deze door naar je klant.'
            : 'Selecteer je bank voor instructies'}
        </p>
        <div className="mt-20">
          <BankManual scrollTo={width <= 543 ? stap1RefMobile.current : stap1Ref.current} />
        </div>
        <p className="mt-40">
          <strong className="strongHeading">
            { partner ? 'Stap 2. Upload de banktransacties '
              : 'Stap 2.  Upload je banktransacties'}
          </strong>
        </p>
        <FormContext {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} id="MT940 GT-FORM-MT940">
            <div className="mt-20">
              <File
                name="mt940"
                label="Selecteer je MT940 bestand"
                labelActive="Selecteer nog een MT940 bestand"
                onChange={handleFiles}
                accept=".940,.txt,.sta,.swi,.dms,.mt940,.mt940structured"
                allowedExtensions={/(\.940|\.txt|\.sta|\.swi|\.dms|\.mt940|\.mt940structured)$/i}
                required
              />
            </div>
            <div className="inDesktop inDesktopActionWrapper">
              <div className="mt-40">
                <Grid>
                  {paramId ? <div></div> : <Link to={personalInfo}>
                    <Button
                      width="100%"
                      outline
                    >
                      Vorige stap
                    </Button>
                  </Link>}
                  <Button
                    type="submit"
                    width="100%"
                    disabled={!formState.isValid}
                    loading={loading}
                  >
                    Verzenden
                  </Button>
                </Grid>
              </div>
              {
                !formState.isValid
                && !paramId
                && (
                  <Grid>
                    <div />
                    <Link to={requestLater} className="center mt-20 link">
                      Aanvraag opslaan
                    </Link>
                  </Grid>
                )
              }
            </div>
            <div className="mt-40 inMobile inMobileSpecial">
              <Grid>
                <Button
                  type="submit"
                  width="100%"
                  disabled={!formState.isValid}
                  loading={loading}
                >
                  Verzenden
                </Button>
                {
                  !paramId
                  &&
                <Link to={personalInfo} className="center fullWidth">
                  <Button
                    width="100%"
                    outline
                  >
                    Vorige stap
                  </Button>
                </Link>
                }
                {
                  !formState.isValid
                  && !paramId
                  && (
                    <Link to={requestLater} className="link center">
                      Aanvraag opslaan
                    </Link>
                  )
                }
              </Grid>
            </div>
          </form>
        </FormContext>
      </Container>
    </PageLayout>
  );
};

FinancialInfo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requestId: PropTypes.string,
  email: PropTypes.string,
  loading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

FinancialInfo.defaultProps = {
  requestId: null,
  email: null,
  loading: false,
  isSubmitted: false,
};

export default connect((state) => ({
  requestId: state.request.id,
  email: state.request.email,
  loading: state.loading.request,
  isSubmitted: state.request.submit,
}))(FinancialInfo);
