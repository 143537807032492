import styled from 'styled-components';

/* Constants */
import { blue } from '../../constants/colors';
import { space, spaceLarge } from '../../constants/spaces';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  input {
    display: none;

    &:checked + .radio:before {
      opacity: 1;
    }

    &:checked ~ .label {
      font-weight: 600;
    }
  }

  .radio {
    width: 14px;
    height: 14px;
    margin-right: ${space}px;
    position: relative;
    border-radius: 50%;
    border: solid 1px #dddddd;

    &:before {
      width: 6px;
      height: 6px;
      content: '';
      border-radius: 50%;
      background-color: ${blue};
      position: absolute;
      left: 50%;
      top: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: opacity 0.2s ease-out;
    }
  }

  .label {
    margin-right: ${spaceLarge}px;
    transition: font-weight 0.2s ease-out;
  }

  @media only screen and (max-width: 425px) {
    .label {
      margin-right: ${space}px;
    }
    .radio {
      margin-right: ${space/2}px;
    }
`;
