import React, { useState } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

/* Constants */
import { completeOverview } from '../constants/routes';

/* Objects */
import Divider from '../objects/divider';
import Grid from '../objects/grid';
import File from '../objects/file';
import Button from '../objects/button';

/* Components */
import BankManual from '../components/bank-manual';

/* Redux */
import { setValues, sendFiles } from '../redux/actions/complete';

const MtFile = ({
  dispatch, history, loading,
  isSubmitted,
}) => {
  const formMethods = useForm({ mode: 'onChange' });
  const { handleSubmit, formState } = formMethods;
  const [files, setFiles] = useState([]);

  if (isSubmitted) {
    // GA tracking pageviews
    ReactGA.pageview(`${window.location.pathname}-complete${window.location.search}`);

    // Redirect after submit
    setTimeout(() => {
      dispatch(setValues({ submit: false }));
      history.push(completeOverview);
    }, 2000);
  }

  const handleFiles = (f) => {
    setFiles(f);
  };

  const onSubmit = () => {
    dispatch(sendFiles('BANK', files));
  };

  return (
    <>
      <h2>Banktransacties (MT940-bestand)</h2>
      <Divider />
      <p className="lead light smallFontDark">Je zakelijke banktransacties zijn eenvoudig te downloaden via je bank. We hebben alleen de laatste 12 maanden nodig.</p>
      <p className="mt-40 inDesktop">
        <strong>Stap 1. Download je banktransacties (MT940-bestand)</strong>
      </p>
      <p className="mt-40 inMobile">
        <strong>Stap 1. Download je banktransacties</strong><br/>
        <strong>(MT940-bestand)</strong>
      </p>
      <p className="light">Selecteer je bank voor instructies</p>
      <div className="mt-20">
        <BankManual />
      </div>
      <p className="mt-40">
        <strong>Stap 2. Upload je banktransacties</strong>
      </p>
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)} >
          <div className="mt-20">
            <File
              name="mt940"
              label="Selecteer je MT940 bestand"
              labelActive="Selecteer nog een MT940 bestand"
              onChange={handleFiles}
              accept=".940,.txt,.sta,.swi,.dms,.mt940,.mt940structured"
              allowedExtensions={/(\.940|\.txt|\.sta|\.swi|\.dms|\.mt940|\.mt940structured)$/i}
              required
            />
          </div>
          <div className="mt-40 inDesktop">
            <Grid>
              {
                !formState.isValid
                && (
                  <Link to={completeOverview}>
                    <Button width="100%" outline>Terug naar mijn dossier</Button>
                  </Link>
                )
              }
              <Button
                type="submit"
                width="100%"
                loading={loading}
                success={isSubmitted}
                disabled={!formState.isValid}
                buttonSize= {formState.isValid ? "halfRight" : ''}
              >
                 {'Verzenden'}
              </Button>
            </Grid>
          </div>
          <div className="mt-40 inMobile">
            <Grid>
              <Button
                type="submit"
                width="100%"
                loading={loading}
                success={isSubmitted}
                disabled={!formState.isValid}
                buttonSize= {formState.isValid ? "halfRight" : ''}
              >
                 {'Verzenden'}
              </Button>
              {
                !formState.isValid
                && (
                  <Link to={completeOverview}>
                    <Button width="100%" outline>Terug naar mijn dossier</Button>
                  </Link>
                )
              }
            </Grid>
          </div>
        </form>
      </FormContext>
      {/* <Footer /> */}
    </>
  );
};

MtFile.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
};

MtFile.defaultProps = {
  loading: false,
  isSubmitted: false,
};

export default connect((state) => ({
  loading: state.loading.complete,
  isSubmitted: state.complete.submit,
}))(MtFile);
