import React from 'react';
import {Telephone, Telephone_Link} from '../../constants/commons';

/* Style */
import StyledComponent from './style';

const CallUs = () => (
  <StyledComponent>
    Vragen?
    <a className="tp" href={Telephone_Link}>&nbsp;{Telephone}</a>
  </StyledComponent>
);

export default CallUs;
