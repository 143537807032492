import styled from 'styled-components';

/* Constants */
import { xsMinWidth, mdMinWidth } from '../../constants/breakpoints';
import { space, spaceSmall } from '../../constants/spaces';
import {
  gradientStart, gradientEnd, grayLight,
  fieldActive, green,
} from '../../constants/colors';

export default styled.ul`
  height: 100%;
  padding: 0;
  font-size: 14px;
  list-style-type: none;
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: ${xsMinWidth}px) {
    display: none;
  }

  @media only screen and (max-width: 425px) {
    display: none;
  }

  li {
    height: 100%;
    padding: 0 ${space}px;
    overflow: hidden;
    position: relative;
    border-left: 1px solid #dddddd;
    display: none;
    flex-direction: column;
    justify-content: center;

    @media only screen and (min-width: ${mdMinWidth}px) {
      display: flex;
      padding: 0 36px 0 20px;
    }

    &:after {
      content: '';
      width: 100%;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(5px);
      background: linear-gradient(to right, ${gradientStart}, ${gradientEnd});
    }

    &.done {
      background-color: ${fieldActive};
    }

    &.active {
      display: flex;
      min-width: 168px;
      text-align: center;

      &:after {
        transform: translateY(0);
      }
    }

    i {
      font-size: 18px;
      color: ${green};
      margin-top: ${spaceSmall / 2}px;
    }

    span {
      font-weight: 600;
    }

    &.icon {
      padding: 0 ${space}px;

      i {
        font-size: 40px;
        color: ${grayLight};
      }

      .icmn-checkbox {
        display: none;
      }
    }
  }
`;
