import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';
import { xsMinWidth, mobileSmallerMaxWidth } from '../../constants/breakpoints';
import {
  blueGradientStart, blueGradientEnd, dark2,
  grayDark, grayLight, dark
} from '../../constants/colors';

export default styled.div`
  .input-holder {
    position: relative;
  }

  .search {
    height: 90px;
    width: 100%;
    font-size: 40px;
    color: ${dark};
    font-weight:400;

    @media only screen and (min-width: ${xsMinWidth}px) {
      font-size: 55px;
    }

    &::placeholder {
      color: ${grayLight};
    }
  }

  .result {
    color: ${dark2};
    font-size: 16px;
    font-weight:400;
    opacity: 70%;

    &:before {
      content: '';
      width: 100%;
      height: 2px;
      display: block;
      margin-bottom: ${space}px;
      background: linear-gradient(to right, ${blueGradientStart}, ${blueGradientEnd});
    }
  }
  @media only screen and (max-width: ${mobileSmallerMaxWidth}px) {

    .search{
      font-size: 37px;
    }
  }

`;
