import {
  TOGGLE_ERROR,
  REPORT_ERROR
} from '../actions/error';

const INITIAL_STATE = {};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_ERROR:
      return {
        ...state,
        [action.prop]: action.bool,
      };

    case REPORT_ERROR:  
      return {
        ...state,
        [action.topic]: action.value,
      };
    default:
      return state;
  }
}
