import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

/* Constants */
import { bridgefund } from '../../constants/routes';

const Header = ({ children, marginRight }) => (
  <StyledComponent marginRight={marginRight}>
    <a href={bridgefund} target="_self">
      <img src={`${process.env.PUBLIC_URL}/images/bridgefund_logo_light.svg`} alt="logo" className="logo" />
    </a>
    <div className="menu">
      {children}
    </div>
  </StyledComponent>
);

Header.propTypes = {
  children: PropTypes.node,
  marginRight: PropTypes.number,
};

Header.defaultProps = {
  children: null,
  marginRight: 0,

};

export default Header;
