import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Style */
import StyledComponent from './style';

/* actions */
import {getInversConsentCollection, getInverseRedirectURL, getInversConsentInformation, getData , 
  requestSystemRedirectDemoUrl, setConcentStatus} from '../../redux/actions/request'

/* Constants */
import { mdMinWidth } from '../../constants/breakpoints';

/* Objects */
import Button from '../../objects/button';

/* env */
import ENV from '../../config/env';

export const RevisionPsd2 = ({
  dispatch, psd2Accounts, psd2CustomerId, contractId, pagePath, allConcentsRenewed, loading, psdCompleted, concentStatus,
  riskAnlysisSequence
}) => {
  const [psd2Acc, setPsd2Acc] = useState([]);
  const params = new URLSearchParams(useLocation().search);
  let psdBankList = [];
  let consetCompleted = 'none';

  useEffect(() => {
   if(psd2CustomerId) dispatch(getInversConsentCollection({customerId : psd2CustomerId, checkForRenewed: 'yes'}))
   },[psd2CustomerId]);

  useEffect(() => {
    if(params && params.get('ConsentCollectionId') != null){
        const concentCount = params.get('ConsentCount');
        if(concentCount === 0) {
          // url might needs to update without concent infomation
          dispatch(setConcentStatus('failed'));
         
        }else{
          dispatch(getInversConsentInformation({type : 'renew', collection_id : params.get('ConsentCollectionId') }));
        }
    }
  }, []);  

  useEffect(() => {
    if(allConcentsRenewed){
      if(allConcentsRenewed === 'no'){
        dispatch(setConcentStatus('partial'));
      
      }else{
        //getData step 7
        if(params && params.get('ConsentCollectionId') !== null && params.get('RequestId') !== null
            && params.get('ConsentCount') !== null){
          dispatch(getData(
            {
              type : 'revision',
              consentCollectionId : params.get('ConsentCollectionId'), 
              requestId : params.get('RequestId'), 
              concentCount : params.get('ConsentCount'),
              riskAnlysisSequence : riskAnlysisSequence
            }
          ));
        }
        
      } 
    }
   
   },[allConcentsRenewed]);

  const startProcess = () => {

   if(ENV.APP_ENV === 'development'){

    dispatch(requestSystemRedirectDemoUrl({
      action : 'renew',
      loanRequestId : contractId,
      customerId : psd2CustomerId,
      returnUrlPath : pagePath.substring(1),
      consentCollectionStatus : "in-request",
      isFromRevision : true
    }));
     
   }else{
    //psd2 revision step 6
    dispatch(getInverseRedirectURL({
      action : 'renew',
      loanRequestId : contractId,
      customerId : psd2CustomerId,
      returnUrlPath : pagePath.substring(1)
    }));
   }

  }; 

  useEffect(() => {
    if(psd2Accounts && psd2Accounts.length > 0){
      const renewed = psd2Accounts.find((account) => account.renewed === 'Yes' );
      if(renewed) dispatch(setConcentStatus('partial'));  
    }
   
   },[psd2Accounts]);

   useEffect(() => {
    generateBankBlocks(psd2Accounts);
   }, [psd2Accounts, concentStatus])


  const generateBankBlocks = (data) => {

    psdBankList = [];

    if(data){
      data.forEach((item, i) => {
        
        psdBankList.push(
          <div className="bankBlockWrapper">
          <div className="bankLogo">
              <img src={ item.bank_code && item.bank_code !== "" ? `${process.env.PUBLIC_URL}/images/bankIcons/${item.bank_code}.svg` : `${process.env.PUBLIC_URL}/images/bankIcons/ABNA.svg`} />
            </div>
  
            <div className="bankName">
              <p>{item.bank_name}{item.iban_short} </p>
            </div>
  
            <div className="bankAction">
              {concentStatus === 'partial' &&
               <img src={item.renewed === 'Yes' ? `${process.env.PUBLIC_URL}/images/icons/yes.svg` : `${process.env.PUBLIC_URL}/images/icons/no.svg` } />
              } 
            </div>
         
           
         </div> 
        );
      })
    }

    setPsd2Acc(psdBankList);

  };


  return (
    <StyledComponent >
        <div>
          {
            !psdCompleted &&
            <div>
              <strong>Koppel de volgende rekening(en)</strong>
              <img className="infoIcon" src={`${process.env.PUBLIC_URL}/images/icons/infoIcon.svg`} />
            </div>
          }
          
          {
          concentStatus !== 'none' && !psdCompleted && 
            <div id="psd2FailMsgInRevision">
              <div id="psd2FailTop">
                <img src={`${process.env.PUBLIC_URL}/images/icons/frown.svg`} />
                {concentStatus === 'partial' && <p>Koppelen deels gelukt</p>}
                {concentStatus === 'failed' && <p>Koppelen mislukt</p>}
                
              </div>
              <p id="psd2FailContent">
                {concentStatus === 'partial' && "Niet alle rekeningen zijn gekoppeld. Klik opnieuw op ‘Rekening koppelen’ om de resterende rekening(en) te koppelen."}
                {concentStatus === 'failed' && "Probeer het opnieuw of neem contact met ons op."}
              </p>
            </div>
          }

          <div>
            <div className="bankBlockHolder mt-20">
             {psd2Acc}
            </div>  
          </div>

          {
            !psdCompleted  ? 

              <div className="actionArea">
                <div className="btnHolder">
                  <Button
                    type="submit"
                    width="100%"
                    loading={loading}
                    onClick={startProcess}>
                      Nu koppelen    
                  </Button>
                </div>
              
              </div> 
              :

              <div id="psd2revisionSuccessDiv">
                <div className="wfltxtWrapper">
                  <p className="wflTxtContent">Wij gaan checken of de huidige financiering nog steeds passend is voor jouw onderneming. Ziet alles er goed uit?<br></br> 
                  Dan kan je gewoon gebruik blijven maken van je krediet.</p><br></br>

                  <p className="wflTxtContent"><strong>We komen zo snel mogelijk bij je terug!</strong></p>
                </div>

            </div>
          }
           
          
        </div>  
    </StyledComponent>
  );
};

RevisionPsd2.propTypes = {
  dispatch: PropTypes.func.isRequired,
  contractId: PropTypes.string.isRequired,
  getInverseRedirectURL: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  psdCompleted: PropTypes.bool, 
  setConcentStatus: PropTypes.func.isRequired
};

RevisionPsd2.defaultProps = {
  psd2Accounts : [],
  loading: false,
  psdCompleted : false,
  concentStatus: 'none'
};

export default connect((state) => ({
  psd2Accounts: state.request.psd2Accounts,
  psd2CustomerId: state.request.psd2CustomerId,
  allConcentsRenewed: state.request.allConcentsRenewed,
  loading: state.request.dataLoading,
  psdCompleted : state.request.psdCompleted,
  concentStatus : state.request.concentStatus,
  riskAnlysisSequence : state.request.riskAnlysisSequence
}))(RevisionPsd2);

