import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Radio from '../../../objects/radio';

/* Components */
import DuplicateForm from '../../duplicate-form';

/* Redux */
import { setValues, nextPage, prevPage } from '../../../redux/actions/dept-form';

const Contracts = ({ dispatch, defaultValues, currentPage }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues });
  const {
    handleSubmit, formState, triggerValidation,
    watch,
  } = formMethods;
  const contract = watch('contract');
  const options = [
    { label: 'Ja' },
    { label: 'Nee' },
  ];
  const fields = [
    {
      label: 'Omschrijving',
      name: 'contractDescription',
      required: true,
    },
    {
      label: 'Oorspronkelijke hoofdsom',
      name: 'contractStartAmount',
      required: true,
    },
    {
      label: 'Openstaand bedrag',
      name: 'contractCurrentAmount',
      required: true,
    },
    {
      label: 'Afbetaling p/m',
      name: 'contractMonthly',
      required: true,
    },
  ];

  // Fix validating defaultValues
  useEffect(() => {
    triggerValidation();
  }, [triggerValidation]);

  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(nextPage());
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="bold">Heeft je bedrijf contractuele verplichtingen? Zoals bijvoorbeeld:</p>
        <ul className="mt-20">
          <li>Lease-overeenkomst</li>
          <li>Leningen</li>
          <li>Rekeningcourant</li>
          <li>Debetstand op rekening</li>
          <li>Koop op afbetaling</li>
          <li>Leverancierskrediet</li>
          <li>Lening van vrienden of familie</li>
          <li>Overige financieringsvormen</li>
        </ul>
        <div className="mt-40">
          <Radio
            name="contract"
            options={options}
            required
          />
        </div>
        {
          contract === options[0].label
          && (
            <div className="mt-40">
              <p>Vul onderstaand de contractuele verplichtingen van je bedrijf in.</p>
              <div className="mt-20">
                <DuplicateForm
                  fields={fields}
                  prefix="contracts"
                  defaultRows={defaultValues.contracts && defaultValues.contracts.length}
                  addMoreLabel="Nog een verplichting toevoegen"
                />
              </div>
            </div>
          )
        }
        <div className="mt-40 inDesktop">
          <Grid>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Contracts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    contracts: PropTypes.arrayOf({}),
  }),
};

Contracts.defaultProps = {
  defaultValues: {},
};

export default connect((state) => ({
  defaultValues: state.deptForm,
  currentPage: state.deptForm.currentPage,
}))(Contracts);
