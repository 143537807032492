import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';
import {
  primary,
  primary2,
  primary3,
  primary4,
  secondary2,
  gray,
  grayLight,
  blue
} from '../../constants/colors';

export default styled.button`
  width: ${(props) => props.width};
  height: 60px;
  max-width: 100%;
  padding: 0 ${space}px;
  color: white;
  border-radius: 42px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 17px;
  text-align: ${(props) => props.textAlign};
  background-color: ${primary3};
  transition: background-color 300ms ease;
  cursor: pointer;
  font-weight:500;

  &:hover {
    background-color: ${primary};
  }

  &.small {
    width: 170px;
    height: 40px;
  }

  &.textOnly {
    width: auto;
    padding: 2px;
    background: none;
    color: ${gray};

    &:hover {
      color: ${primary2};
    }
  }

  &.textOnlyFullWidth {
    width: 100%;
    padding: 2px;
    background: none;
    color: ${gray};

    &:hover {
      color: ${primary2};
    }
  }

  &.outline {
    background-color: transparent;
    color: ${blue};
    font-weight: 500;
    border: 2px solid ${blue};

    // &:hover {
    //   background-color: ${primary4};
    //   color: white;
    // }
  }

  &.noline {
    background-color: transparent;
    color: ${primary2};
    font-weight: 600;
    border: 2px solid white;
    transition: border-color 300ms ease;

    &:hover {
      border: 2px solid ${primary2};
    }
  }

  &.important {
    background-color: ${secondary2};
  }

  &.disabled {
    background-color: ${grayLight};
  }

  &.loading {
    background-color: ${primary};
    background-image: url(${process.env.PUBLIC_URL}/images/loader-dots.svg);
    background-size: 40px;
    background-position: center center;
    background-repeat: no-repeat;
    pointer-events: none;
    cursor: default;
  }

  &.success {
    position: relative;
    text-indent: 9999px;
    background-color: ${primary};
    pointer-events: none;
    cursor: default;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-right: 4px solid transparent;
      border-bottom: 4px solid transparent;
      transform: translate3d(-0.5em, 0, 0) rotate(45deg);
      transform-origin: 0% 100%;
      animation: checkbox-check 250ms 500ms cubic-bezier(.4, .0, .23, 1) forwards;
    }
  }

  &.halfSizedRightBtn{
    width: 48%;
    float: right;
    margin-left: 52%;
  }

  &.fullWidthBtn{
    width: 48%;
    float: right;
    margin-left: 52%;
  }

  &.pinkButton {
    background-color: #E40066 !important;
  }

  &.blueWhiteBtn{
    color: #29c4ea!important;
    line-height: 9px;
    padding: 19px 16px!important;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    border: 2px solid #29c4ea;
    background: #fff;
    text-align: center;
  }

  &.blueWhiteBtn:hover {
    background-color: #29c4ea;
    color: #fff !important;
  }

  @keyframes checkbox-check{
    0%{
      width: 0;
      height: 0;
      border-color: white;
      transform: translate3d(-0.5em, 0, 0) rotate(45deg);
    }
    33%{
      width: .6em;
      height: 0;
      transform: translate3d(-0.5em, 0, 0) rotate(45deg);
    }
    100%{
      width: .6em;
      height: 1.5em;
      border-color: white;
      transform: translate3d(-0.5em, -1.60em, 0) rotate(45deg);
    }
  }
`;
