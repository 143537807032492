import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWindowDimensions } from '../../utils/browserWidth';
import { useHistory, useLocation } from 'react-router-dom';

/* Style */
import StyledComponent from './style';

/* Banks */
import AbnAmro from './banks/AbnAmro';
import Asn from './banks/Asn';
import Bunq from './banks/Bunq';
import Ing from './banks/Ing';
import Knab from './banks/Knab';
import Rabobank from './banks/Rabobank';
import Sns from './banks/Sns';
import Triodos from './banks/Triodos';
import RegioBank from './banks/RegiBank';

/* Objects */
import Grid from '../../objects/grid';

/* redux */
import {getBanks} from '../../redux/actions/request';

const BankManual = ({ scrollTo, dispatch, bankList }) => {
  const [showAllBanks, setShowAllBanks] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  let options = [];
  let location = useLocation();
  
  const history = useHistory()

  const banks = [
    { label: 'INGB', value: 'INGB' },
    { label: 'RABO', value: 'RABO' },
    { label: 'ABNA', value: 'ABNA' },
    { label: 'KNAB', value: 'KNAB' },
    { label: 'RBRB', value: 'RBRB' },
    { label: 'TRIO', value: 'TRIO' },
    { label: 'SNSB', value: 'SNSB' },
    { label: 'BUNQ', value: 'BUNQ' },
  ];

  const renderBank = useMemo(() => {
    if (selectedBank === 'INGB') return <Ing />;
    if (selectedBank === 'ABNA') return <AbnAmro />;
    if (selectedBank === 'RABO') return <Rabobank />;
    if (selectedBank === 'SNSB') return <Sns />;
    if (selectedBank === 'KNAB') return <Knab />;
    if (selectedBank === 'TRIO') return <Triodos />;
    //if (selectedBank === 'ASNB') return <Asn />;
    if (selectedBank === 'BUNQ') return <Bunq />;
    if (selectedBank === 'RBRB') return <RegioBank />;
    return null;
  }, [selectedBank]);

  const clickHandler = (bank) => {
    if (selectedBank === bank) {
      setSelectedBank(null);
      return;
    }
    
    if(location.pathname && location.pathname.match('/lening-aanvragen/banktransacties/')){
      history.push(`?bank=${bank}`);
    }

    setSelectedBank(bank);
   
    setTimeout(() => {
      window.scrollTo(0, scrollTo.offsetTop);
    }, 50);
  };

  useEffect(() => {
    dispatch(getBanks());
  }, []);

  useEffect(() => {
    let bankName = location.search.split("=")[1];
    setSelectedBank(bankName);
  }, []);

  // removing db bank list due to conflict with the database  banklist value in show_to_customer_indicator

  // if(bankList && bankList.length > 0){
  //   bankList.forEach(element => {
  //      if(element.show_to_customer_indicator && element.show_to_customer_indicator === "yes"
  //        && element.country_code === 'NL'){ // adding banks which are only needs to display to the customer
  //         if(element.bank_code !== 'ASNB' && element.bank_code !== 'FVLB' && element.bank_code !== 'DEUT' ){ // removing ASN, DEUT and FVLB bank from the Display BankList
  //           options.push({ label: element.bank_full_name, value: element.bank_code })
  //         }
  //      }
      
  //   });
  // }


  // Using hard coded bank list due to conflict with the database banlist value in show_to_customer_indicator
  if(banks){
    banks.forEach(element => {
      if(element.value !== 'ASNB' && element.value !== 'FVLB' && element.value !== 'DEUT' ){ // removing ASN, DEUT and FVLB bank from the Display BankList
        options.push({ label: element.label, value: element.value })
      }
    });
  }

  const { height, width } = useWindowDimensions(); // eslint-disable-line

  const colums = {
    allMinWidth: '1fr 1fr',
    xsMinWidth: width <= 543 ? '1fr 1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr 1fr',
  };

  return (
    <StyledComponent>
      <div className="banks">
        <Grid columns={colums} columnGap={16} rowGap={16} className="banks">
          {
            options.map((b, index) => {
              
              if (!showAllBanks && index > (width <= 543 ? 4 : 3)) return null;

              return (
                <div
                  key={b.value}
                  className={`bank ${b.value === selectedBank ? 'active' : (selectedBank && 'inactive')}`}
                  onClick={() => clickHandler(b.value)}
                >
                  {
                  b.value === 'RBRB'
                    ? <img src={`${process.env.PUBLIC_URL}/images/banks/${b.value}.svg`} alt="logo" />
                    : <img src={`${process.env.PUBLIC_URL}/images/banks/${b.value}.png`} alt="logo" />
                }
                </div>
              );
            })
          }
          {!showAllBanks && <div className="bank more" onClick={() => setShowAllBanks(true)}>Overige banken</div>}
        </Grid>
      </div>
      {
        renderBank
        && (
          <div className="instruction mt-20">
            <i className="close icmn-plus-round" onClick={() => setSelectedBank(null)} />
            {renderBank}
          </div>
        )
      }
    </StyledComponent>
  );
};

BankManual.propTypes = {
  scrollTo: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  bankList: PropTypes.array.isRequired
};

BankManual.defaultProps = {
  scrollTo: null,
  bankList : []
};

export default connect((state) => ({
   bankList: state.request.bankList,
}))(BankManual);

