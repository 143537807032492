import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

/* Style */
import StyledComponent from './style';

const Combobox = ({
  name, label, defaultValue,
  type, required, IEInputStraatnaam,
  IEInputNummer, height, options, list
}) => {
  const {
    register, watch, errors, setValue,
  } = useFormContext();
  const currentWatch = watch([name])[name];
  const currentError = errors[name];
  const [focus, setFocus] = useState(false);
  const [value, setValueState] = useState(currentWatch);
  const [error, setError] = useState(currentError);
  const [flagTelephone, setFlagTelephone] = useState(false);
  let componentClasses = classNames({
    label,
    focus,
    error,
    set: value,
  });

  // IEInputStraatnaam and IEInputNummer is used change styles when run in Internet Explorer
  if(IEInputStraatnaam){
    componentClasses = componentClasses + ' IEInputStraatnaam'
  }

  if(IEInputNummer){
    componentClasses = componentClasses + ' IEInputNummer'
  }

  useEffect(() => {
    setValueState("");
    setError(null);
    const timer = setTimeout(() => {
      if(currentWatch){
        setValueState(currentWatch.replace(/["']/g, ""));
      }
      
      setError(currentError);
    }, 600);
    return () => clearTimeout(timer);
  }, [currentWatch, currentError]);

  useEffect(() => {
    if(currentWatch){
      setValue([name],currentWatch.replace(/["']/g, ""));
    }
    
    if (type === 'tel' && (currentWatch && currentWatch.length >= 3) && (currentWatch.charAt(2) === '0' )) {
      let newString = currentWatch.slice(0, 2) + currentWatch.slice(3)
      setFlagTelephone(true);
      setValue([name],newString);
      setValueState(newString);
    }
    if (type === 'tel' && (currentWatch && currentWatch.length >= 3) && (currentWatch.charAt(2) === '6' ) && flagTelephone) {
      let newString = currentWatch.slice(0, 2) + currentWatch.slice(3)
      setFlagTelephone(false);
      setValue([name],newString);
      setValueState(newString);
    }
    if (
      currentWatch &&
      (name.includes("firstName")
      || name.includes("lastName")
      || name.includes("firstname")
      || name.includes("lastname")
      || name.includes("FirstName")
      || name.includes("LastName")
      )
      ) {
      let uppercaseConvert = currentWatch.charAt(0).toUpperCase() + currentWatch.slice(1);
      setValue([name],uppercaseConvert);
      setValueState(uppercaseConvert);
    }
  }, [focus, currentError, setValue, currentWatch])

  let registerData = {
    required: required ? (label ? `Vul ${label.toLowerCase()} in` : 'Vul dit veld in') : required,
  };

  if (type === 'email') {
    registerData = {
      ...registerData,
      pattern: {
        value: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Geen geldig e-mailadres',
      },
    };
  }

  const generateOptionsForList = () => {
    return options.map((option) => (
        <option key={option} value={option}></option> 
    ));
  }

  if (type === 'tel') {
    registerData = {
      ...registerData,
      minLength: {
        value: 10,
        message: 'Telefoonnummer te kort',
      },
      maxLength: {
        value: 10,
        message: 'Telefoonnummer te lang',
      },
      pattern: {
        value: /^[0-9]*$/,
        message: 'Geen geldig telefoonnummer',
      },
    };
  }

  return (
    <StyledComponent height={height} className={componentClasses} htmlFor={name}>
      <div>
      <div className="label">{error ? error.message : label}</div>
      <input
        type={type}
        id={name}
        name={name}
        ref={register(registerData)}
        onBlur={() => setFocus(false)}
        onFocus={() => { 
          setValue([name], value || defaultValue);
          setFocus(true);
         
        }}
        list={list}
      />

      <datalist id={list}>
        {generateOptionsForList(value)}
        {/* <option key="-1" >{ value !== "" ? `"${value}"` : ""}</option> */}
        </datalist>
      </div>
      
    </StyledComponent>
  );
};

Combobox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  height: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'tel']),
  required: PropTypes.bool,
  IEInputStraatnaam: PropTypes.bool,
  IEInputNummer: PropTypes.bool,
  options: PropTypes.array
};

Combobox.defaultProps = {
  label: null,
  defaultValue: '',
  type: 'text',
  required: false,
  IEInputStraatnaam: false,
  IEInputNummer: false,
  height: '50px',
  options: [],
  list : ""
};

export default Combobox;
