import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Style */
import StyledComponent from './style';

/* Objects */
import ProgressBar from '../../objects/progress-bar';

/* Pages */
// import Bankruptcy from './pages/1Bankruptcy';
import Contracts from './pages/2Contracts';
import Payments from './pages/3Payments';
import Taxes from './pages/4Taxes';
import Creditors from './pages/5Creditors';
import Debtors from './pages/6Debtors';
import Sign from './pages/7Sign';

 import Questions from './pages/Questions';
 import Bankruptcy from './pages/1Bankruptcy';

export const CustomerDeptForm = ({ currentPage }) => {
  // const pages = [
  //   {
  //     title: 'Faillissement',
  //     component: Bankruptcy,
  //   },
  //   {
  //     title: 'Contractuele verplichtingen',
  //     component: Contracts,
  //   },
  //   {
  //     title: 'Betaalachterstanden',
  //     component: Payments,
  //   },
  //   {
  //     title: 'Belastingdienst',
  //     component: Taxes,
  //   },
  //   {
  //     title: 'Crediteuren',
  //     component: Creditors,
  //   },
  //   {
  //     title: 'Debiteuren',
  //     component: Debtors,
  //   },
  //   {
  //     title: 'Verklaring en ondertekening',
  //     component: Sign,
  //   },
  // ];
  // const { title, component } = pages[currentPage];
  // const Page = component;

  return (
    <StyledComponent>

      <div className="mt-10">
         <Questions />
      </div>
    </StyledComponent>
  );
};

CustomerDeptForm.propTypes = {
  // currentPage: PropTypes.number,
};

CustomerDeptForm.defaultProps = {
  // currentPage: 0,
};

export default connect((state) => ({
  currentPage: state.deptForm.currentPage,
}))(CustomerDeptForm);
