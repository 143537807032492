import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';

/* Objects */
import Grid from '../../../objects/grid';
import Button from '../../../objects/button';
import Radio from '../../../objects/radio';

/* Components */
import DuplicateForm from '../../duplicate-form';

/* Redux */
import { setValues, nextPage, prevPage } from '../../../redux/actions/dept-form';

const Payments = ({ dispatch, defaultValues }) => {
  const formMethods = useForm({ mode: 'onChange', defaultValues });
  const {
    handleSubmit, formState, triggerValidation, watch,
  } = formMethods;
  const payment = watch('payment');
  const options = [
    { label: 'Ja' },
    { label: 'Nee' },
  ];
  const fields = [
    {
      label: 'Omschrijving',
      name: 'paymentDescription',
      required: true,
    },
    {
      label: 'Oorspronkelijke hoofdsom',
      name: 'paymentStartAmount',
      required: true,
    },
    {
      label: 'Openstaand bedrag',
      name: 'paymentCurrentAmount',
      required: true,
    },
    {
      label: 'Afbetaling p/m',
      name: 'paymentMonthly',
      required: true,
    },
  ];

  // Fix validating defaultValues
  useEffect(() => {
    triggerValidation();
  }, [triggerValidation]);

  const onSubmit = (data) => {
    dispatch(setValues(data));
    dispatch(nextPage());
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="bold">Heb je op dit moment een betaalregeling lopen bij een incassobureau of gerechtsdeurwaarder?</p>
        <div className="mt-40">
          <Radio
            name="payment"
            options={options}
            required
          />
        </div>
        {
          payment === options[0].label
          && (
            <div className="mt-40">
              <p>Vul onderstaand de specificaties van de achterstand of betaalregeling in.</p>
              <div className="mt-20">
                <DuplicateForm
                  fields={fields}
                  prefix="payments"
                  defaultRows={defaultValues.payments && defaultValues.payments.length}
                  addMoreLabel="Nog een verplichting toevoegen"
                />
              </div>
            </div>
          )
        }
        <div className="mt-40 inDesktop">
          <Grid>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
          </Grid>
        </div>
        <div className="mt-40 inMobile">
          <Grid>
            <Button
              type="submit"
              width="100%"
              disabled={!formState.isValid}
            >
              Volgende stap
            </Button>
            <Button
              width="100%"
              onClick={() => { dispatch(prevPage()); }}
              outline
            >
              Vorige stap
            </Button>
          </Grid>
        </div>
      </form>
    </FormContext>
  );
};

Payments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    payments: PropTypes.array,
  }).isRequired,
};

export default connect((state) => ({
  defaultValues: state.deptForm,
}))(Payments);
