import styled from 'styled-components';

/* Constants */
import { space } from '../../constants/spaces';
import { primary2, red } from '../../constants/colors';

export default styled.div`

  .icon-div {
    margin: 0 auto;
  }

  i {
    display: block;
    height: 50px;
    line-height: 50px;
    font-size: 25px;
    margin-top: auto;
    transform: rotate(-135deg);
    cursor: pointer;

    &:hover {
      color: ${red};
    }
  }

  // .rowa {
  //   display: flex;
  //   flex-direction: row;
  //   margin-top: ${space}px;

  //   &:first-child {
  //     margin: 0;
  //   }

  //   i {
  //     height: 50px;
  //     line-height: 50px;
  //     font-size: 25px;
  //     margin-top: auto;
  //     transform: rotate(-135deg);
  //     cursor: pointer;

  //     &:hover {
  //       color: ${red};
  //     }
  //   }

  //   .empty-space {
  //     width: 25px;
  //   }
  // }

  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0px
  }

  .add-row {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 600;
    margin-top: ${space}px;
    border-radius: 4px;
    border: dashed 1px ${primary2};
    color: ${primary2};
    cursor: pointer;
    transition: background-color 300ms ease;
    background-color: #ffffff;

    &:hover {
      background-color: ${primary2};
      border: solid 1px ${primary2};
      color: white;
    }

    .plus {
      margin-right: ${space}px;
    }
  }
`;
