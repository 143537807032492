import styled from 'styled-components';

export default styled.div`
  #bedrijfsnaamSection {
    width: 336px;
  }

  #paymentsSelections{
    width: 216px;
  }

  #contractSelections{
    width: 100%;
    float: left;
    position: relative;
  }

  .contractSelectionDivs{
      width:30%
      float: left;
      position: relative;
      margin-right: 3%;
  }

  .contractPartTitle{
    float:left;
  }

  .closeObligation{
    float:right;
    cursor: pointer;
  }

  #contractSubSelection{
    margin-left: 4%;
  }

  .contractSubInput{
    width: 30%;
    float: left;
    margin-right: 3%;
  }

  .Debt_at_third_Part_row{
    width: 100%;
    display:inline-flex;
  }

  .Debt_at_third_Part_row_input{
    width: 65%;
    float: left;
    margin-right: 3%;
  }

  .paymentSelectionDivs{
    width:30%
    float: left;
    position: relative;
    margin-right: 3%;
  }

  .paymentSubInput{
    width: 30%;
    float: left;
    margin-right: 3%;
    background-color: #ffffff;
   
  }

  .paymentTopRow{
    width: 100%;
    float: left;
    position: relative;
  }

  .mt-10{
    margin-top:10px;
  }

  .addMoreObligations {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 15px 0;
    border: dashed 1px #00997F;
    background-color: #00997E15;
    border-radius: 5px;
    cursor: pointer;
    -webkit-transition: background-color 300ms ease-out;
    transition: background-color 300ms ease-out;
  }

  .addMoreObligations span{
    font-size: 14px;
    color: #1E2749;
    pointer-events: none;
  } 

  input{
    border:0;
  }

`;
