/* Responsive utils */
export const xlMinWidth = 1200; // Extra large devices (large desktops)
export const xlMaxWidth = 1199; // Extra large devices (large desktops)
export const mdMinWidth = 992; // Large devices (desktops)
export const mdMaxWidth = 991; // Large devices (desktops)
export const smMinWidth = 768; // Medium devices (tablets)
export const smMaxWidth = 767; // Medium devices (tablets)
export const xsMinWidth = 544; // Small devices (landscape phones)
export const xsMaxWidth = 543; // Small devices (landscape phones)
export const mobileSmallerMaxWidth = 320; // Mobile smaller size
export const mobileMediumMaxWidth = 375; // Mobile medium size
export const mobileLargeMaxWidth = 522; // Standard large mobile size
export const tabMaxWidth = 768; // Standard Tab size
export const desktopMediumMaxWidth = 1024; // Standard Medium desktop size
export const desktopLargeMaxWidth = 1440; // Standard large desktop size
export const desktopXLMaxWidth2 = 1800; // Standard larger desktop size
export const desktopLargerMaxWidth = 2560; // 4K large desktop size


export const allMinWidth = 0;

export default {
  xlMinWidth,
  xlMaxWidth,
  mdMinWidth,
  mdMaxWidth,
  smMinWidth,
  smMaxWidth,
  xsMinWidth,
  xsMaxWidth,
  allMinWidth,
  desktopLargeMaxWidth,
  desktopLargerMaxWidth,
  desktopMediumMaxWidth,
  mobileMediumMaxWidth,
  mobileSmallerMaxWidth,
  desktopXLMaxWidth2,
  tabMaxWidth

};
