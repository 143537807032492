import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Componnents */
import PageLayout from '../components/page-layout';

/* Objects */
import Container from '../objects/container';


const WorkflowStatus = ({ dispatch, activeWorkflowFound }) =>  {
    
  
  return (
    <PageLayout>
      <Container>
        <div className="mt-20 workflowStatusWrapper">
          { !activeWorkflowFound &&
            <>
              <img className="headerIcon" src={`${process.env.PUBLIC_URL}/images/icons/too_bad.svg`} />
              <p className="headingFont"><strong>Deze link is inmiddels verlopen</strong></p>
              <div className="wfltxtWrapper">
                <p className="wflTxtContent">Wil je toch gebruik blijven maken van je krediet? Neem dan contact op met jouw persoonlijke customer success<br></br> 
                  manager of via onderstaande gegevens. Wij zullen dan een nieuwe link genereren, zodat je de zakelijke<br></br> 
                  banktransacties alsnog kunt uploaden. Ziet alles er goed uit? Dan wordt de looptijd met 6 maanden verlengd en kan <br></br> 
                  je weer opnemen wanneer jij iets extra’s nodig hebt.</p><br></br>

                  <p className="wflTxtContent">Bel <strong>085 401 6600</strong> of stuur een mailtje naar <a href = "mailto: contact@bridgefund.nl">contact@bridgefund.nl</a></p>
              </div>
              
            </>
          
          }
          
        </div>
      </Container>  

    </PageLayout>
  )
};

WorkflowStatus.propTypes = {
    dispatch: PropTypes.func.isRequired,
    activeWorkflowFound : PropTypes.bool.isRequired
};

export default connect((state) => ({
  activeWorkflowFound : state.request.activeWorkflowFound
}))(WorkflowStatus);
